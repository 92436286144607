import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import BillListTableItem from "./BillListTableItem";
import Api from "../../../helpers/Api";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
  },
  headerSty: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "19px",
      fontWeight: "600",
    },
  },
  tableCont: {
    width: "100%",
  },
  subheadCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
  },
  leftSubhead: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "18px",
      fontWeight: "600",
      color: "white",
    },
  },
  rigthSubhead: {
    display: "flex",
    alignItems: "center",
  },
  headAmountCont: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    "& h3": {
      fontSize: "13px",
      fontWeight: "400",
      color: "white",
    },
    "& p": {
      fontSize: "13px",
      fontWeight: "510",
      color: "white",
      marginLeft: "3px",
    },
    "& span": {
      fontSize: "13px",
      fontWeight: "400",
      margin: "0px 5px",
      color: "white",
    },
  },
  tableSty: {
    width: "100%",
    border: "1px solid gray",
    borderCollapse: "collapse",
    "& th": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "0px",
    },
    "& td": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0px",
      textAlign: "right",
      paddingRight: "10px",
    },
  },
  titleBtnCont: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    "& p": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  btnCont: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "4px",
    borderRadius: "10px",
    marginLeft: "10px",
  },
  itemType: {
    width: "40px"
  },
  itemTitleSty: {
    width: "160px",
  },
  itemTypeSty: {
    width: "130px",
  },
  qtySty: {
    width: "40px",
  },
  taxChipSty: {
    width: "80px",
  },
  unitSty: {
    width: "40px",
  },
  priceUnitSty: {
    width: "40px",
  },
  priceUnitGSTSty: {
    width: "40px",
  },
  GSTSty: {
    width: "120px",
  },
  totalCostIncludingGSTSty: {
    width: "160px",
  },
  optionsCont: {
    padding: "5px",
  },
  singleOption: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    "& img": {
      width: "20px",
      height: "auto",
      marginRight: "10px",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "&:hover": {
      backgroundColor: "#e2e2e2",
    },
  },
  totalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "10px",
  },
  totalContainerChild: {
    flex: "0.5",
    margin: "10px",
    display: "flex",
    alignItems: "top",
  },
  totalTableSty: {
    width: "65%",
    border: "none",
    borderCollapse: "collapse",
    padding: "0px",
    fontSize: "0.9rem",
    color: "#27272a",
    fontWeight: 500,
    "& tr": {
      height: "5px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
      textAlign: "right",
    },
  },
  listTableSty: {
    width: "100%",
    border: "none",
    borderCollapse: "collapse",
    padding: "0px",
    fontSize: "0.9rem",
    color: "#27272a",
    fontWeight: 400,
    marginTop: "15px",
    "& tr": {
      height: "15px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
      textAlign: "left",
    },
  },
}));

const createBillItemForGroup = async (obj) => {
  try {
    const resData = await Api.post(
      "procurement/ops/billlist/billitems/group",
      obj
    );
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    console.log(error);
  }
};

const updateBillItemAndResources = async (obj) => {
  try {
    const resData = await Api.post(
      "/procurement/ops/billlist/billitems/update",
      obj
    );
    const resultData = resData?.data || null;
    return resultData;
  } catch (error) {
    console.log(error);
  }
};

const BillListTable = ({
  showTableColumn,
  issuebillListId,
  opsOrderName,
  setOpsOrderName,
  project,
  setProject,
  organization,
  setOrganization,
  loading,
  setLoading,
  opsOrder,
  setTotalBillDetails
}) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const [billitems, setBillItems] = useState([]);
  const [hierarchyArr, setHierarchyArr] = useState([]);
  const [isCollapseChild, setCollapseChild] = useState(false);
  const { id } = useParams();
  const [openChooseProject, setOpenChooseProject] = useState(false);
  const [openChooseOrganization, setOpenChooseOrganization] = useState(false);
  const { opsbillListId } = useParams();
  const billListId = opsbillListId || issuebillListId;

  const getBillItems = async () => {
    try {
      const resData = await Api.post("/procurement/ops/billlist/billitems", {
        billListId: billListId,
      });
      setBillItems(resData?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const createGroupItem = async (
    parentId = null,
    numberOfChildren = 0,
    parentData
  ) => {
    let newObj = {
      userId: user?._id,
      numberOfChildren: numberOfChildren,
      parentAncestor: parentData?.ancestors || [],
      billListId: billListId,
    };
    if (parentId) {
      newObj.parentId = parentId;
    }

    await createBillItemForGroup(newObj)
      .then((data) => {
        const newItem = data?.curItem;
        let arr = [];
        billitems.map((billItem) => {
          if (parentId && parentId === billItem?._id) {
            arr.push({
              ...billItem,
              numberOfChildren: billItem?.numberOfChildren + 1,
            });
          } else {
            arr.push(billItem);
          }
        });
        arr.push(newItem);
        setBillItems(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createBillItem = async (
    parentId = null,
    numberOfChildren = 0,
    parentData
  ) => {
    try {
      let newObj = {
        billList: billListId,
        userId: user?._id,
        numberOfChildren: numberOfChildren,
        parentAncestor: parentData?.ancestors || [],
      };
      if (parentId) {
        newObj.parentId = parentId;
      }

      const resData = await Api.post(
        "/procurement/ops/billlist/billitems/create",
        newObj
      );
      const resultData = resData?.data;

      const newItem = resultData?.curItem;
      let arr = [];
      billitems.map((billItem) => {
        if (parentId && parentId === billItem?._id) {
          arr.push({
            ...billItem,
            numberOfChildren: billItem?.numberOfChildren + 1,
          });
        } else {
          arr.push(billItem);
        }
      });
      arr.push(newItem);
      setBillItems(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const createBillItems = async (
    parentId = null,
    numberOfChildren = 0,
    parentData,
    size
  ) => {
    try {
      let newObj = {
        userId: user?._id,
        numberOfChildren: numberOfChildren,
        parentAncestor: parentData?.ancestors || [],
        billListId: billListId,
        count: size,
      };
      if (parentId) {
        newObj.parentId = parentId;
      }

      const resData = await Api.post(
        "/procurement/ops/billlist/billitems/create-many",
        newObj
      );
      const resultData = resData?.data;

      const newItems = resultData?.curItems;
      let arr = [];
      billitems.map((billItem) => {
        if (parentId && parentId === billItem?._id) {
          arr.push({
            ...billItem,
            numberOfChildren: billItem?.numberOfChildren + newItems.length,
          });
        } else {
          arr.push(billItem);
        }
      });
      arr = [...arr, ...newItems];
      setBillItems(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const updateBillItemFromDialog = async (dataObj) => {
    await updateBillItemAndResources(dataObj)
      .then((data) => {
        let allBillItemIds = data.allBillItemIds;
        let idBillItemMap = data.idBillItemMap;
        let updatedItemsArr = [];

        billitems.map((itemData) => {
          let id = itemData._id;
          if (allBillItemIds.includes(id)) {
            updatedItemsArr.push(idBillItemMap[id]);
          } else {
            updatedItemsArr.push(itemData);
          }
        });
        setBillItems(updatedItemsArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [untaxedAmountVal, setUntaxedAmountVal] = useState(0);
  const [totalAmountVal, setTotalAmountVal] = useState(0);
  const [taxesList, setTaxesList] = useState([]);
  const [resourceType, setResourceType] = useState("Material");

  const calculateTotalTaxes = () => {
    let untaxedAmount = 0;
    let taxMap = {};
    let totalAmount = 0;

    let list = billitems;

    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        let item = list[i];

        if (item.isBillGroup === false && item.qTy > 0 && item.rate > 0) {
          let amount = item.qTy * item.rate;
          untaxedAmount += amount;
          let taxes = item?.taxes;

          for (let j = 0; j < taxes.length; j++) {
            let tax = taxes[j];

            let perc = tax?.percent || 0;
            let taxAmount = (perc / 100) * amount;
            totalAmount += taxAmount;
            if (!taxMap[tax._id]) {
              taxMap[tax._id] = {
                name: tax?.name,
                totalTaxedAmount: taxAmount,
              };
            } else {
              let oldAmount = taxMap[tax._id]?.totalTaxedAmount;
              let newAmount = oldAmount + taxAmount;
              taxMap[tax._id] = {
                name: tax?.name,
                totalTaxedAmount: newAmount,
              };
            }
          }
        }
      }

      let taxes = Object.values(taxMap);
      totalAmount += untaxedAmount;
      setUntaxedAmountVal(untaxedAmount);
      setTotalAmountVal(totalAmount);
      setTaxesList(taxes);
    }
  };

  const buildHierarchy = (items, parent = null) => {
    const result = [];

    for (const item of items) {
      if (item.parent == parent) {
        const children = buildHierarchy(items, item._id);
        if (children.length > 0) {
          item.children = children;
        }

        if (item.numberOfChildren === 0) {
          result.push({
            ...item,
          });
        } else {
          result.push(item);
        }
      }
    }
    return result;
  };

  useEffect(() => {
    const parentChildArray = buildHierarchy(billitems);
    setHierarchyArr(parentChildArray);
    calculateTotalTaxes();
  }, [billitems]);

  useEffect(() => {
    if (billListId) {
      getBillItems();
    }
  }, [billListId]);

  useEffect(() => {
    const totalBillDetails = {
      billitems: billitems,
      untaxedAmountVal: untaxedAmountVal,
      taxesList: taxesList,
      totalAmountVal: totalAmountVal
    }
    setTotalBillDetails(totalBillDetails);
  }, [billitems, totalAmountVal]);

  return (
    <div>
      <div>
        <Paper
          className={classes.tableCont}
          elevation={2}
          style={{ marginTop: "15px" }}
        >
          {/* <div className={classes.subheadCont}>
            <div className={classes.leftSubhead}>
              <TextField
                size="small"
                value={opsOrderName}
                onChange={(e) => setOpsOrderName(e.target.value)}
                disableUnderline
                InputProps={{
                  style: {
                    color: "#ffffff",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    padding: "0px",
                    margin: "0px",
                  },
                  disableUnderline: true,
                }}
              />
            </div>
            <div className={classes.rigthSubhead} style={{ padding: "5px" }}>
              <Button
                size="small"
                variant="contained"
                disableElevation
                style={{ marginRight: "10px" }}
                onClick={() => setOpenChooseProject(true)}
              >
                {project
                  ? `${project?.displayName} (Project)`
                  : "Choose Project"}
              </Button>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={() => setOpenChooseOrganization(true)}
              >
                {organization
                  ? `${organization?.displayName} (Org)`
                  : "Choose Org"}
              </Button>
            </div>
          </div> */}
          <table className={classes.tableSty}>
            <tr>
              {showTableColumn?.includes("Item Type") &&
                <th className={classes.itemType}>
                  <div style={{ display: 'flex', alignItems: "center", paddingLeft: "15px" }}>
                    <p>Item Type</p>
                  </div>
                </th>}
              <th className={classes.itemTitleSty}>
                <div className={classes.titleBtnCont}>
                  <p>Item Group / Bill Item</p>
                  <MyPopOver
                    closeOnClick={true}
                    appearContent={
                      <Button className={classes.btnCont}>
                        <AddIcon
                          style={{
                            marginRight: "5px",
                            fontSize: "15px",
                            color: "white",
                          }}
                        />
                        <ExpandMoreIcon
                          style={{ fontSize: "15px", color: "white" }}
                        />
                      </Button>
                    }
                    showContent={
                      <div className={classes.optionsCont}>
                        <div
                          className={classes.singleOption}
                          onClick={() => {
                            createGroupItem(null, 0, null);
                          }}
                        >
                          <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/item-group-icon.svg?alt=media" />
                          <p>Item Group</p>
                        </div>
                        <div
                          className={classes.singleOption}
                          onClick={() => {
                            createBillItem(null, 0, null);
                          }}
                        >
                          <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/work-item-icon.svg?alt=media" />
                          <p>Bill Item</p>
                        </div>
                      </div>
                    }
                  />
                </div>
              </th>
              <th className={classes.qtySty}>Quantity</th>
              <th className={classes.priceUnitSty}>Rate / Unit</th>
              <th className={classes.unitSty}>Unit</th>
              <th className={classes.qtySty}>Amount</th>
              {showTableColumn?.includes("Taxes") && <th className={classes.taxChipSty}>Taxes</th>}
              {showTableColumn?.includes("Tax incl.") && <th className={classes.priceUnitSty}>Tax incl.</th>}
              {showTableColumn?.includes("Tax excl.") && <th className={classes.priceUnitSty}>Tax excl.</th>}
            </tr>
            {hierarchyArr &&
              hierarchyArr.map((billitemData) => (
                <BillListTableItem
                  key={billitemData._id}
                  showTableColumn={showTableColumn}
                  billitemData={billitemData}
                  createGroupItem={createGroupItem}
                  paddingLeft={15}
                  isCollapseChild={isCollapseChild}
                  setCollapseChild={setCollapseChild}
                  billitems={billitems}
                  setBillItems={setBillItems}
                  updateBillItemFromDialog={updateBillItemFromDialog}
                  createBillItems={createBillItems}
                  organization={organization}
                  calculateTotalTaxes={calculateTotalTaxes}
                  getBillItems={getBillItems}
                  loading={loading}
                  setLoading={setLoading}
                />
              ))}
          </table>
        </Paper>
        {/* <Choose
          open={openChooseProject}
          setOpen={setOpenChooseProject}
          parentModelName="Project"
          multiple={false}
          disableModelChange={true}
          placeHolder={`Choose Project`}
          onSelected={(arr) => {
            setProject(arr[0]);
          }}
        />
        <Choose
          open={openChooseOrganization}
          setOpen={setOpenChooseOrganization}
          parentModelName="Organization"
          multiple={false}
          disableModelChange={true}
          placeHolder={`Choose Organization`}
          onSelected={(arr) => {
            setOrganization(arr[0]);
          }}
        /> */}
        <div className={classes.totalContainer}>
          <div className={classes.totalContainerChild}>
            <TextField
              fullWidth
              placeholder="Define your terms and conditions..."
              multiline
              rows={5}
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <div
            className={classes.totalContainerChild}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <table className={classes.totalTableSty}>
              <tr>
                <td>Untaxed amount: </td>
                <td style={{ fontWeight: 600 }}>₹ {untaxedAmountVal}</td>
              </tr>

              {taxesList &&
                taxesList.length > 0 &&
                taxesList.map((item, i) => (
                  <tr key={i} >
                    <td>{item?.name}</td>
                    <td>{parseFloat(item?.totalTaxedAmount).toFixed(2)}</td>
                  </tr>
                ))}

              <tr>
                <td>Total: </td>
                <td
                  style={{
                    fontWeight: 600,
                    fontSize: "1.2rem",
                  }}
                >
                  ₹ {totalAmountVal}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillListTable;
