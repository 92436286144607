import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  CircularProgress,
} from "@mui/material";
import ProjectPicker from "./ProjectPicker";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import FormBox from "../../styled/generic/FormBox";
import { useParams, useHistory } from "react-router-dom";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import ProjectBlockAndUnitPickerDropdown from "../../styled/generic/ProjectBlockAndUnitPickerDropdown";

const CreateBookingModel = ({ open, onClose, libraryId }) => {
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const history = useHistory();
  const [profile, setProfile] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [paymentSchedule, setPaymentSchedule] = useState(null);
  const [paymentTables, setPaymentTables] = useState([]);
  const [bookingData, setBookingData] = useState({
    project: null,
    projectBlock: null,
    unit: null,
    area: "",
    rsf: "",
    paymentTable: "",
    paymentSchedule: "",
    project: "",
    organization: "",
    projectBlock: "",
    saleableArea: "",
  });

  const resetForm = () => {
    setBookingData({
      project: null,
      projectBlock: null,
      unit: null,
      area: "",
      rsf: "",
      paymentTable: "",
      paymentSchedule: "",
      project: null,
      organization: null,
      projectBlock: null,
      saleableArea: "",
    });
    setActiveStep(0);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      let data = await getProfileDataPopulate({
        profileId: profileId,
      });
      console.log("data", data);
      if (data) {
        if (data?.parentModelName === "Project") {
          setShowProjects(false);
          handleUpdateBookingData({ project: data?.parent });
          if (data?.ownerProfile?.parentModelName === "Organization") {
            handleUpdateBookingData({
              organization: data?.ownerProfile?.parent,
            });
          }
        } else {
          setShowProjects(true);
          handleUpdateBookingData({ organization: data?.parent });
        }
        setProfile(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to fetch profile",
          },
        });
      }
      setLoading(false);
      console.log("profile", profile);
    };
    if (profileId && open) {
      fetchProfile();
    }
  }, [profileId, open]);

  useEffect(() => {
    if (open) resetForm();
    else setTimeout(resetForm, 500);
  }, [open]);

  const steps = [
    "Project and Units",
    "Payment Details",
    "Payment Tables",
    "Additional Details",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUpdateBookingData = (newData) => {
    setBookingData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleSubmit = async () => {
    const payload = {
      bookingData: {
        ...bookingData,
        paymentTable: bookingData.paymentTable?._id,
        schedule: bookingData.paymentSchedule?._id,
        project: bookingData.project?._id,
        projectBlock: bookingData.projectBlock?._id,
        unit: bookingData.unit?._id,
        organization: bookingData.organization?._id,
      },
      libraryId: libraryId,
      profileId: profileId,
    };
    try {
      const data = await Api.post("/listing/booking/create", payload);
      if (data) {
        console.log("newbooking", data);
        await getPaymentTableDetails(
          bookingData.paymentTable._id,
          data.data._id
        );
        await updateAreaAndRate(data.data._id);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Booking created successfully",
          },
        });
        history.push("/booking/edit/" + data.data._id);
        onClose();
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong while creating booking",
        },
      });
    }
  };

  const getPaymentTableDetails = async (paymentTableId, bookingId) => {
    const paymentDetailsPayload = {
      tableId: paymentTableId,
      bookingId: bookingId,
    };

    const response = await Api.post(
      "listing/booking/payment/table/detail/get-or-create",
      paymentDetailsPayload
    );

    console.log(response, " arecomponentsINpaymenttable");

    if (response) {
      if (Array.isArray(response)) {
        console.log(response);
      } else {
        console.log([]);
      }
    }
  };

  const updateAreaAndRate = async (bookingId) => {
    console.log(
      "ratePerSqFt saleableArea ",
      bookingData?.ratePerSqFt,
      bookingData?.saleableArea
    );
    const rsf = bookingData?.rsf;
    const area = bookingData?.area;
    const saleableArea = bookingData?.saleableArea;

    const data = await Api.post(
      "/listing/booking/payment/update-area-and-rate",
      {
        bookingId,
        rate: rsf,
        area: area,
        saleableArea,
      }
    );

    if (data.booking) {
      const updatedBooking = data.booking;
      const { paymentDetails: details } = updatedBooking;

      console.log("updatedBooking", updatedBooking);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to update area and rate",
        },
      });
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {showProjects && (
                <FormBox label={"Project"}>
                  <ProjectPicker
                    fullWidth={true}
                    size="sm"
                    selectedOrg={
                      showProjects ? bookingData?.organization?._id : false
                    }
                    project={bookingData.project}
                    setProject={(project) =>
                      handleUpdateBookingData({ project: project })
                    }
                  />
                </FormBox>
              )}
              <FormBox label={"Project Block"}>
                <ProjectBlockAndUnitPickerDropdown
                  projectId={bookingData?.project}
                  hideLabel={true}
                  fullWidth={true}
                  selectedProjectBlock={bookingData?.projectBlock}
                  setSelectedProjectBlock={(data) => {
                    handleUpdateBookingData({ projectBlock: data });
                  }}
                  selectedProjectUnit={bookingData?.unit}
                  setSelectedProjectUnit={(data) => {
                    handleUpdateBookingData({ unit: data });
                  }}
                />
              </FormBox>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormBox label={"Total Area"}>
                <TextField
                  label="Total Area (sqft)"
                  type="number"
                  value={bookingData.area}
                  onChange={(e) =>
                    handleUpdateBookingData({ area: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                />
              </FormBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormBox label={"Rate per Sq Ft"}>
                <TextField
                  label="Rate per Sq Ft"
                  type="number"
                  value={bookingData.rsf}
                  onChange={(e) =>
                    handleUpdateBookingData({ rsf: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                />
              </FormBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormBox label={"Saleable Area"}>
                <TextField
                  label="Saleable Area (sqft)"
                  type="number"
                  value={bookingData.saleableArea}
                  onChange={(e) =>
                    handleUpdateBookingData({ saleableArea: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                />
              </FormBox>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6">Payment Tables and Schedules</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Table</InputLabel>
              <Select
                value={bookingData?.paymentTable?._id}
                onChange={(e) => {
                  const paymentTable = paymentTables?.find(
                    (item) => item?._id === e.target.value
                  );
                  handleUpdateBookingData({ paymentTable: paymentTable });
                }}
              >
                {paymentTables.map((item) => {
                  return (
                    <MenuItem key={item} value={item._id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Schedule</InputLabel>
              <Select
                value={bookingData.paymentSchedule}
                onChange={(e) =>
                  handleUpdateBookingData({ paymentSchedule: e.target.value })
                }
              >
                {paymentSchedule?.map((item) => {
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        );
      case 3:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <FormBox label={"Booking Status"}>
              <Select
                label="Booking Status"
                value={bookingData.bookingStatus}
                onChange={(e) =>
                  handleUpdateBookingData({ bookingStatus: e.target.value })
                }
                fullWidth
                margin="normal"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormBox>

            <FormBox label="Sanctioned/Unsanctioned">
              <Select
                label="Sanctioned/Unsanctioned"
                value={bookingData.sanctioned}
                onChange={(e) =>
                  handleUpdateBookingData({ sanctioned: e.target.value })
                }
                fullWidth={true}
              >
                <MenuItem value={true}>Sanctioned</MenuItem>
                <MenuItem value={false}>Unsanctioned</MenuItem>
              </Select>
            </FormBox>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  const getSchedules = async () => {
    if (!bookingData?.paymentTable?._id) {
      return;
    }

    const { data } = await Api.post("/listing/booking/schedule/get", {
      libraryId: libraryId,
      tableId: bookingData?.paymentTable?._id,
    });
    if (data) {
      const filteredPaymentDetails = data.filter((item) => item?.title);
      setPaymentSchedule(filteredPaymentDetails);
    } else
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
  };

  useEffect(() => {
    if (bookingData?.paymentTable && open) {
      getSchedules();
    }
  }, [bookingData?.paymentTable]);

  const getPaymentTables = async () => {
    const data = await Api.post("/listing/booking/payment/table/get", {
      libraryId: libraryId,
    });

    if (data) {
      const filteredPaymentTables = data.data.filter((item) => item.title);
      setPaymentTables(filteredPaymentTables);
    }
  };

  useEffect(() => {
    getPaymentTables();
  }, [open, libraryId]);

  console.log("bookingData", bookingData);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Booking</DialogTitle>
      <DialogContent
        sx={{
          height: "60vh",
        }}
      >
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider
          sx={{
            mb: 3,
          }}
        />
        {loading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          renderStepContent(activeStep)
        )}
      </DialogContent>
      <DialogActions
        sx={{
          gap: "5px",
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
        >
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        ) : (
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateBookingModel;
