import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  Grid,
  Select,
  Pagination,
  MenuItem,
  Divider,
  LinearProgress,
  CardActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  Schedule as ScheduleIcon,
  Business as BusinessIcon,
  Home as HomeIcon,
  ViewModule as BlockIcon,
  LayersClear as SlabIcon,
  Assignment as DemandDeedIcon,
  Timeline as ProgressIcon,
} from "@mui/icons-material";
import FormBox from "../../styled/generic/FormBox";
import { Edit as EditIcon } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Api from "../../../helpers/Api";
import { DemandDeedPrepareDrawer } from "../settings/Schedule";
import { LoadingButton } from "@mui/lab";

const ITEMS_PER_PAGE = 12;

const getSchedule = async (scheduleId) => {
  const { data } = await Api.post("/listing/booking/schedule/get-by-id", {
    scheduleId: scheduleId,
  });

  const completedSchedule = data.slabs.filter((slab) => slab.isCompleted);
  const lastCompleteSlab = completedSchedule[completedSchedule.length - 1];

  return {
    bookings: data?.bookings,
    schedule: data,
    slabs: data?.slabs,
    lastCompleteSlab: lastCompleteSlab,
  };
};

const getBlocks = async (projectId) => {
  const { data } = await Api.post(
    "public/project/block/get/against/multiple-projects",
    {
      projectIds: [projectId],
    }
  );

  return data || null;
};

// projectid, projectblockids
const getSchedules = async (params) => {
  const { data } = await Api.post("/listing/booking/schedule/get", params);
  return data || null;
};

const FilterSection = ({ onFilterChange, projectIds, projects }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    if (projects.length === 1) setSelectedProject(projectIds[0]);
  }, [projectIds]);

  useEffect(() => {
    if (selectedProject) {
      getBlocks(selectedProject).then((data) => {
        if (data) {
          console.log("data", data);
          setBlocks(data);
          handleChange(
            "block",
            data.map((block) => block?._id)
          );
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Failed to fetch blocks",
            },
          });
          console.log("Something went wrong");
        }
      });
    }
  }, [selectedProject]);

  const handleChange = (field, value) => {
    switch (field) {
      case "name":
        setName(value);
        break;
      case "block":
        setSelectedBlock(value);
        break;
      case "project":
        setSelectedProject(value);
        break;
      default:
        break;
    }
    console.log(value);
    onFilterChange({
      name,
      selectedBlock,
      selectedProject,
      [field]: value,
    });
  };

  console.log("name12", name);

  return (
    <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
      <FormBox label={"Search"}>
        <TextField
          size="small"
          fullWidth
          placeholder="Search schedules..."
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormBox>

      {projects.length > 1 ? (
        <FormBox label={"Project"} fullWidth>
          <Select
            fullWidth
            labelId="project-select-label"
            value={selectedProject}
            onChange={(e) => handleChange("project", e.target.value)}
            size="small"
            label="Projects"
          >
            {projects?.map((pro) => {
              return (
                <MenuItem value={pro?.parent?._id}>
                  {pro?.parent?.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormBox>
      ) : (
        <FormBox label={"Project"}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              height: "100%",
              padding: 1,
              border: "1px solid black",
              borderRadius: 1.5,
            }}
          >
            <Typography variant="body1">
              {projects[0]?.parent?.displayName || "Undefine"}
            </Typography>
          </Box>
        </FormBox>
      )}
      <FormBox label={"Block"} fullWidth>
        <Select
          labelId="block-select-label"
          value={selectedBlock}
          defaultValue={[blocks[0]?._id]}
          multiple
          onChange={(e) => handleChange("block", e.target.value)}
          size="small"
          label="Blocks"
          fullWidth
        >
          {blocks.map((item) => (
            <MenuItem value={item?._id}>{item?.name || "undefined"}</MenuItem>
          ))}
        </Select>
      </FormBox>
    </Box>
  );
};

const ScheduleCard = ({ item, profileId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const [lastCompleteSlab, setLastCompleteSlab] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [schedule, setSchedule] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePrepare = async (selectedBookings) => {
    setLoading(true);
    try {
      const data = await Api.post("/listing/booking/payment/demanddeed/prepare", {
        slabId: lastCompleteSlab?._id,
        selectedBookings,
        userId: user?._id,
        profile: user?.profile,
        firstPartyProfile: profileId
      });
      if (data) {
        console.log(data);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Demand deeds prepared successfully",
          },
        });
        setOpen(false);
      } else {
        throw new Error("Error while preparing demand deed");
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (item?._id) {
      getSchedule(item?._id).then((data) => {
        setSchedule(data);
        setLastCompleteSlab(data?.lastCompleteSlab);
        setBookings(data?.bookings);
      });
    }
  }, [open, item?._id]);

  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: 2,
        transition: "0.3s",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              cursor: "pointer",
              "&:hover": { color: "primary.main", textDecoration: "underline" },
            }}
            onClick={() =>
              history.push(
                `/booking/settings/${profileId}?view=schedule&scheduleId=${item?._id}`
              )
            }
          >
            {item?.title || "Untitled"}
          </Typography>
          <IconButton
            size="small"
            onClick={() =>
              history.push(
                `/booking/settings/${profileId}?view=scheduleedit&scheduleId=${item?._id}`
              )
            }
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <HomeIcon color="action" fontSize="small" />
            <Typography variant="body2">
              {bookings.length || "0"} Bookings
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <BusinessIcon color="action" fontSize="small" />
            <Typography variant="body2">
              {item?.project?.displayName || "N/A"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <BlockIcon color="action" fontSize="small" />
            <Typography variant="body2">
              {item?.projectBlock?.name || "N/A"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SlabIcon color="action" fontSize="small" />
            <Typography variant="body2">
              {item?.slabs?.length || "0"} Slabs
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ScheduleIcon color="action" fontSize="small" />
            <Typography variant="body2">
              Created: {new Date(item?.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Last Completed Slab:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            <DemandDeedIcon color="action" fontSize="small" />
            <Typography variant="body2">
              {lastCompleteSlab?.title || "N/A"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ProgressIcon color="action" fontSize="small" />
            <Typography variant="body2" sx={{ mr: 1 }}>
              Progress:
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <LinearProgress
                variant="determinate"
                value={lastCompleteSlab?.cumulativePercentage || 0}
                sx={{ height: 8, borderRadius: 5 }}
              />
            </Box>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {lastCompleteSlab?.cumulativePercentage || 0}%
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          startIcon={<DemandDeedIcon />}
          loadingPosition="start"
          sx={{
            borderRadius: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          Prepare Demand Deeds
        </LoadingButton>
      </CardActions>
      <DemandDeedPrepareDrawer
        bookings={bookings}
        handlePrepare={handlePrepare}
        open={open}
        setOpen={setOpen}
        schedule={schedule}
        lastCompleteSlab={lastCompleteSlab}
        loading={loading}
      />
    </Card>
  );
};

const BookingSchedule = ({ libraryId, projectIds, projects }) => {
  const { profileId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [schedules, setSchedules] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [block, setBlock] = useState([]);
  const [project, setProject] = useState(null);
  const [name, setName] = useState("Vedant");

  console.log("name", name);

  useEffect(() => {
    if (project) {
      // projectId, projectBlockIds
      const params = {
        projectId: project,
      };
      if (block.length > 0) {
        params.projectBlockIds = block;
      }

      if (name?.length > 3) {
        params.name = name;
      }

      console.log("params", params);
      getSchedules(params).then((data) => {
        if (data) {
          setSchedules(data);
          setFilteredSchedules(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Failed to fetch booking schedules",
            },
          });
        }
      });
    }
  }, [project, block, name]);

  const handleFilterChange = ({
    searchTerm,
    selectedBlock,
    selectedProject,
  }) => {
    console.log(searchTerm);
    setName(searchTerm);
    setBlock(selectedBlock);
    setProject(selectedProject);
  };

  const totalPages = Math.ceil(filteredSchedules.length / ITEMS_PER_PAGE);
  const paginatedData = filteredSchedules.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  if (loading) {
    return (
      <Box
        sx={{
          height: "1000px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography color={"GrayText"}>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, pt: 0 }}>
      <FilterSection
        onFilterChange={handleFilterChange}
        projectIds={projectIds}
        projects={projects}
      />
      <Grid container spacing={2}>
        {paginatedData.map((item) => {
          return (
            <Grid item xs={12} sm={6} md={3} key={item._id}>
              <ScheduleCard item={item} profileId={profileId} />;
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default BookingSchedule;
