import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Grid,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import EmojiPicker from "emoji-picker-react";

import Api from "../../helpers/Api";
import ProfileAppbar from "../profile/profile.appbar";
import FilesViewer from "../file/Viewer/FilesViewer";
import StatusPicker from "./status.picker";
import IssueDialog from "./issue.table.dialog";
import FormView from "./FormView";
import Response from "../responses/response";
import SmartCommentList from "../comment/smart.comment.list";
import { Apartment, BusinessCenter } from "@material-ui/icons";
import { useLocalStorage } from "react-use";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "13vh",
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const NoDataBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const EmojiPickerContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

function ProfileIssueView({ issue, setIssue, isFromBooking = false }) {
  const location = useLocation();
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [template, setTemplate] = useState("");
  const [updateTemplateStatusLoading, setUpdateTemplateStatusLoading] =
    useState(false);
  const [updated, setUpdated] = useState(false);
  const [open, setOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  console.log("template", template);

  useEffect(() => {
    const statusObj = issue?.template?.pipeline.find(
      (obj) => obj?._id === issue?.status
    );
    setTemplate(issue?.template);
    setStatus(statusObj);
  }, [issue]);

  const updateTemplateStatus = async () => {
    setUpdateTemplateStatusLoading(true);
    const issueObject = {
      _id: issue?._id,
      template: template?._id,
      status: status?._id,
      closed: template?.finalStates.includes(status?._id),
    };
    const res = await Api.post("issue/updateTemplateStatus", issueObject);
    if (res?.issue) {
      setIssue(res?.issue);
      setUpdated(true);
    } else {
      console.error(res?.error);
    }
    setUpdateTemplateStatusLoading(false);
  };

  const createNotification = async () => {
    const data = await Api.post();
  };

  useEffect(() => {
    if (issue && template && status && status?._id !== issue?.status) {
      const timer = setTimeout(() => {
        updateTemplateStatus();
      }, 1700);
      return () => clearTimeout(timer);
    }
  }, [template, status]);

  return (
    <Root>
      <IssueDialog
        open={open}
        IdArr={[issue?._id]}
        issueDictionary={{ [issue?._id]: issue }}
        setOpen={setOpen}
        status={Boolean(issue?.closed)}
      />
      <Content>
        <Section>
          <Header>
            <StyledAvatar src={issue?.user?.displayPicture?.url}>
              {issue?.user?.displayName?.charAt(0)}
            </StyledAvatar>
            <Box>
              <Typography variant="h6">{issue?.user?.displayName}</Typography>
              <Typography variant="caption" color="text.secondary">
                Created on {moment(issue?.createdAt).format("MMM D, YYYY")}
              </Typography>
            </Box>
          </Header>
          <ProfileAppbar
            name={issue?.title}
            btns={
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                {template?.pipeline?.length > 0 && (
                  <StatusPicker
                    viewMode
                    pipeline={template?.pipeline}
                    startState={template?.startState}
                    status={status}
                    setStatus={setStatus}
                  />
                )}
                <Button
                  sx={{
                    height: "50px",
                  }}
                  startIcon={<EditIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (isFromBooking) {
                      history.push(`/booking/issue/edit/${issue?._id}`);
                    } else {
                      history.push(`/issue/edit/${issue?._id}`);
                    }
                  }}
                >
                  Edit Ticket
                </Button>
              </Box>
            }
          />
          <Box display="flex" alignItems="center">
            <Title variant="h6">
              Title:- {issue?.title || "Untitled Ticket"}
            </Title>
          </Box>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Description:-{" "}
            {issue?.description
              ? ReactHtmlParser(issue?.description)
              : "No description"}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Project</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Apartment />
                <Typography>
                  {issue?.project
                    ? issue.project.displayName
                    : "No project associated"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Organization</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <BusinessCenter />
                <Typography>
                  {issue?.organization
                    ? issue.organization.displayName
                    : "No organization associated"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {updateTemplateStatusLoading ? (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <CircularProgress size={14} color="primary" />
              <Typography
                variant="caption"
                sx={{ ml: 1 }}
                color="text.secondary"
              >
                Updating...
              </Typography>
            </Box>
          ) : updated ? (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <DoneIcon color="success" sx={{ width: 14, height: 14 }} />
              <Typography
                variant="caption"
                sx={{ ml: 1 }}
                color="text.secondary"
              >
                Updated
              </Typography>
            </Box>
          ) : null}
        </Section>

        <Divider />

        <Section>
          <SectionTitle variant="h6">Files</SectionTitle>
          {issue?.files?.length > 0 ? (
            <FilesViewer fileIds={issue.files.map((file) => file?._id)} />
          ) : (
            <NoDataBox>
              <InsertDriveFileOutlinedIcon
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography variant="body1" color="text.secondary">
                No files attached
              </Typography>
            </NoDataBox>
          )}
        </Section>
        <Divider />

        <Section>
          <SectionTitle variant="h6">Comments</SectionTitle>
          {issue?._id ? (
            <SmartCommentList parent={issue._id} parentModelName="Issue" />
          ) : (
            <NoDataBox>
              <CommentOutlinedIcon
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography variant="body1" color="text.secondary">
                No comments yet
              </Typography>
            </NoDataBox>
          )}
        </Section>
      </Content>
    </Root>
  );
}

export default ProfileIssueView;
