import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Checkbox,
  Divider,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Typography,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  RadioGroup,
  FormLabel,
  Radio,
  Autocomplete,
  Tooltip,
  InputAdornment,
  Grid,
  styled,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ProjectPicker from "../../../styled/generic/ProjectPicker";
import ProjectBlockAndUnitPickerDropdown from "../../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import UserSearcher from "../../../styled/generic/UserSearcher";
import BankAccountPicker from "../../../styled/generic/BankAccountPickerDropdown";
import PaymentSection from "../PaymentSection";
import {
  Edit,
  Delete,
  Add,
  Percent,
  CurrencyRupee,
  People,
  Assignment,
  LocationOn,
  Home,
  Person,
  Description,
  Phone,
  Apartment,
  Business,
  House,
} from "@mui/icons-material";
import CreatePalDrawer from "../../../AddMember/CreatePalDrawer";
import SearchEntityDialog from "../../../styled/CommonComponents/SearchEntityDrawer";
import { useSelector } from "react-redux";
import FormBox from "../../../styled/generic/FormBox";
import { BiTrash } from "react-icons/bi";
import Api from "../../../../helpers/Api";
import { EditOutlined } from "@material-ui/icons";

export const OwnerProfile = ({
  bookingData,
  formData,
  handleChange,
  handleRemoveEntity,
  givenProfileIdArr,
  mainProfileWitParent,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [openOwnerPal, setOpenOwnerPal] = useState(false);
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [ownerSelector, setOwnerSelector] = useState("Organization");
  const [organizationProfileId, setOrganizationProfileId] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [owner, setOwner] = useState(formData.owner || null);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (bookingData?.ownerProfile?._id)
      setOwner(bookingData?.ownerProfile?.parent);
  }, [bookingData]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await Api.post("/organization/get", {
        user: user._id,
      });
      if (response) {
        setOrganizationList(response);
      } else {
        console.error("Error fetching organizations:");
      }
    };
    fetchOrganizations();
  }, []);

  useEffect(() => {
    const searchUsers = async () => {
      if (userSearch.length > 2) {
        try {
          const response = await Api.post(`/users/get/pagination`, {
            name: userSearch,
          });
          setUserList(response.data);
        } catch (error) {
          console.error("Error searching users:", error);
        }
      }
    };

    const userSearchTimeout = setTimeout(searchUsers, 800);
    return () => clearTimeout(userSearchTimeout);
  }, [userSearch]);

  useEffect(() => {
    handleChange("owner", owner);
  }, [owner]);

  const handleAddOwner = (entity) => {
    setOwner(entity);
    setShowLeadsDrawer(false);
  };

  const handleEditOwner = (newOne) => {
    setOwner(newOne);
    setOpenOwnerPal(false);
  };

  // useEffect(() => {
  //   if (formData?.owner)
  //     setOwnerSelector(formData?.owner?.model || "Organization");
  // }, [formData.owner]);
  console.log("ownerSelector", ownerSelector);
  console.log("ownerProfile", owner);

  return (
    <FormBox label="Add Owner">
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
        <Box
          sx={{
            display: formData?.owner ? "none" : "block",
            mb: 1,
          }}
        >
          <FormLabel id="owner-type-label">Type</FormLabel>
          <RadioGroup
            row
            value={ownerSelector}
            onChange={(e) => {
              setOwnerSelector(e.target.value);
              setOrganizationProfileId("");
              setSelectedUser("");
              setOwner(null);
            }}
          >
            <FormControlLabel
              value="Organization"
              control={<Radio />}
              label="Organization"
            />
            <FormControlLabel value="User" control={<Radio />} label="User" />
            <FormControlLabel
              value="Pal"
              control={<Radio />}
              label="Create new"
            />
          </RadioGroup>
        </Box>

        {!owner && ownerSelector === "Organization" && (
          <Select
            value={organizationProfileId}
            onChange={(e) => setOwner(e.target.value)}
            fullWidth
            sx={{ mb: 1 }}
          >
            <MenuItem value="" disabled>
              Select an organization
            </MenuItem>
            {organizationList?.map((org) => (
              <MenuItem key={org?.profile} value={org}>
                {org?.displayName ?? "Undefined"}
              </MenuItem>
            ))}
          </Select>
        )}

        {!owner && ownerSelector === "User" && (
          <Autocomplete
            options={userList}
            inputValue={userSearch}
            onInputChange={(event, value) => setUserSearch(value)}
            value={selectedUser}
            onChange={(event, newValue) => setSelectedUser(newValue)}
            getOptionLabel={(option) => option?.displayName ?? ""}
            renderInput={(params) => (
              <TextField {...params} label="Search Users" fullWidth />
            )}
            renderOption={(props, option) => (
              <MenuItem
                {...props}
                onClick={() => setOwner(option)}
                sx={{ display: "flex", alignItems: "center", gap: 2 }}
              >
                <Avatar
                  src={option?.displayPicture?.url}
                  alt={option?.displayName}
                />
                <Typography variant="body1">{option?.displayName}</Typography>
              </MenuItem>
            )}
            fullWidth
            sx={{ mb: 1 }}
          />
        )}

        {!owner && ownerSelector === "Pal" && (
          <Button
            variant="outlined"
            startIcon={<Add />}
            fullWidth
            onClick={() => {
              setRole("Owner");
              setShowLeadsDrawer(true);
            }}
            sx={{ mb: 1 }}
          >
            Add Owner
          </Button>
        )}

        {owner && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              p: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar
                sx={{ width: 56, height: 56 }}
                src={
                  owner?.parent?.displayPicture?.url ||
                  owner?.displayPicture?.url
                }
                alt={owner?.parent?.displayName ?? owner?.displayName}
              />
              <Box>
                <Typography variant="subtitle1">
                  {owner?.parent?.displayName ?? owner?.displayName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {owner?.parent?.phone ?? owner?.phone}
                </Typography>
              </Box>
            </Box>
            <Box>
              {ownerSelector === "pal" && (
                <IconButton onClick={() => setOpenOwnerPal(true)}>
                  <EditOutlined />
                </IconButton>
              )}
              <IconButton onClick={() => setOwner(null)}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
        )}
      </Paper>

      <SearchEntityDialog
        orgtype="User"
        givenFilterOption={[
          {
            option: "Contact",
            types: ["Owner", "Other"],
          },
        ]}
        entity="User"
        multiple={false}
        curEntityId={user?._id}
        givenProfileIdArr={[]}
        onSave={handleAddOwner}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Owner"
        financialRelationType="Owner"
      />

      <CreatePalDrawer
        openPal={openOwnerPal}
        setOpenPal={setOpenOwnerPal}
        fRelationId={mainProfileWitParent?.profile?.finrel}
        selectEntityFunction={handleEditOwner}
        pal={mainProfileWitParent}
        title="Edit Owner"
        orgtype="User"
        financialRelationType="Owner"
        mainProfileWitParent={mainProfileWitParent}
        profileIdArr={[]}
        notAllowedRoles={[]}
        actionType="Update"
      />
    </FormBox>
  );
};
