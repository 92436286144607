import React from "react";
import {
  Box,
  Typography,
  Avatar,
  AvatarGroup,
  Chip,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  CalendarToday,
  Home,
  Engineering,
  CurrencyRupee,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { calculateBookingFinancials } from "./Bookings";
import HomeIcon from "../../../Assets/house.png";
import NumberFormat from "react-number-format";

const BookingCard = ({ booking }) => {
  const history = useHistory();
  const { agreementValue, totalDue, totalPaid } =
    calculateBookingFinancials(booking);

  return (
    <Box
      sx={{
        borderRadius: "8px",
        boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        cursor: "pointer",

        "&:hover": {
          // transform: "translateY(-5px)",
          boxShadow: "0 1px 5px 0 rgba(0,0,0,0.2)",
        },
      }}
      onClick={() => history.push(`/booking/view/${booking?._id}`)}
    >
      <Box sx={{ position: "relative", height: "160px", overflow: "hidden" }}>
        <img
          src={HomeIcon}
          alt="Property"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Chip
            label="Sale"
            color="warning"
            size="small"
            sx={{ borderRadius: "16px" }}
          />
          {booking?.cancelled === true ? (
            <Chip
              label={"Cancelled"}
              color={"error"}
              size="small"
              sx={{ borderRadius: "16px" }}
            />
          ) : (
            <Chip
              label={booking?.status || "Draft"}
              color={
                booking?.status === "Draft" || !booking?.status
                  ? "warning"
                  : "success"
              }
              size="small"
              sx={{ borderRadius: "16px" }}
            />
          )}
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography
          variant="h6"
          gutterButtom
          sx={{
            fontWeight: "bold",
          }}
        >
          {booking?.customerProfile?.parent?.displayName || "N/A"}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Engineering sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2" color="text.secondary">
            {booking?.project?.displayName}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Home sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2" color="text.secondary">
            Unit: {booking?.unit?.name || "undefined"}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <CurrencyRupee sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2" color="text.secondary">
            Agreement Value:{" "}
            <NumberFormat
              value={agreementValue}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₹"}
            />
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <CalendarToday sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2" color="text.secondary">
            Booked on: {new Date(booking?.bookingDate).toLocaleDateString()}
          </Typography>
        </Box>

        <Divider
          sx={{
            my: 1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography variant="caption" color="text.secondary">
              Account Managers
            </Typography>
            {booking?.accountManagers?.length > 0 ? (
              <AvatarGroup
                max={3}
                sx={{
                  mt: 0.5,
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                {booking?.accountManagers?.map((manager) => (
                  <Tooltip title={manager?.parent?.displayName}>
                    <Avatar
                      key={manager?._id || Math.random()}
                      src={manager?.parent?.displayPicture?.url}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Tooltip>
                ))}
              </AvatarGroup>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 0.5,
                }}
              >
                Not selected
              </Typography>
            )}
          </Box>
          <Chip
            label={`Invite: ${booking?.inviteCode}`}
            color="info"
            size="small"
            sx={{ borderRadius: "16px", mt: 1 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BookingCard;
