import React, { useState, useEffect } from "react";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute.js";

import BookingHome from "../components/booking/booking/BookingHome.jsx";
import BookingEdit from "../components/booking/booking/BookingEdit.jsx";
import BookingSettings from "../components/booking/booking/BookingSettings.jsx";
import BookingView from "../components/booking/booking/BookingView.jsx";
import DemandDeedDetails from "../components/booking/demanddeed/DemandDeedDetails.jsx";
import TransactionPage from "../components/booking/transaction/TransactionPage.jsx";
import BookingMessage from "../components/booking/messages/BookingMessages.jsx";
import BookingIssue from "../components/booking/booking/BookingIssue.jsx";
import TransactionEditPage from "../components/booking/transaction/TransactionEdit.jsx";
import BookingIssueView from "../components/booking/booking/BookingIssueView.jsx";
import ProfileIssueViewManager from "../components/issue/profile.issue.view.manager";

const Routes = (isMobile) => {
  return [
    <BasicPrivateRoute
      exact
      path="/booking/:profileId"
      noAppbar={true}
      component={BookingHome}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/messages/:profileId"
      noAppbar={true}
      component={BookingMessage}
      useBothSide={true}
      hideDrawer={isMobile ? true : false}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/issues/:profileId"
      noAppbar={true}
      component={BookingIssue}
      useBothSide={true}
      hideDrawer={isMobile ? true : false}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/edit/:bookingId"
      noAppbar={true}
      component={BookingEdit}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/view/:bookingId"
      noAppbar={true}
      component={BookingView}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path={"/booking/transaction/view/:transactionId"}
      noAppbar={true}
      component={TransactionPage}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path={"/booking/transaction/edit/:transactionId"}
      noAppbar={true}
      component={TransactionEditPage}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/settings/:profileId"
      noAppbar={true}
      component={BookingSettings}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/demanddeed/:demandDeedId"
      noAppbar={true}
      component={DemandDeedDetails}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/issue/view/:issueId"
      component={BookingIssueView}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/booking/issue/edit/:issueId"
      editModeValue={true}
      noAppbar={true}
      component={ProfileIssueViewManager}
      useBothSide
    />,
  ];
};

export default Routes;
