import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
  TableFooter,
  Pagination,
  TableSortLabel,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import {
  TrendingUp,
  TrendingDown,
  CurrencyRupee,
  Percent,
  LocalMall,
} from "@mui/icons-material";
import { PiBookOpenLight, PiSelectionBackground } from "react-icons/pi";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  Area,
  YAxis,
  Tooltip,
  AreaChart,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Cell,
  BarChart,
  Bar,
} from "recharts";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const getIcon = (change) => {
  const sx = {
    fontSize: 20,
    color: "primary.main",
  };
  switch (change) {
    case 0:
      return <LocalMall sx={sx} />;
    case 1:
      return <CurrencyRupee sx={sx} />;
    case 2:
      return <Percent sx={sx} />;
    case 3:
      return <CurrencyRupee sx={sx} />;
    default:
      return null;
  }
};

const tableHeader = [
  "Customer Name",
  "Status",
  "Booking Date",
  "Project",
  "Total Payable Amount",
  "Curent Due",
  "Received Amount",
];

const MetricCard = ({ title, value, change, unit, index }) => (
  <Paper elevation={2} sx={{ p: 2, height: "100%" }}>
    <Box
      display={"flex"}
      alignItems={"start"}
      justifyContent={"space-between"}
      mb={"0.5rem"}
    >
      <Box>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="h4"
          component="div"
          fontWeight="bold"
          sx={{ mb: 1 }}
        >
          {unit}
          {value}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: 1,
          borderRadius: "50%",
          border: "1px solid",
          borderColor: "#bfdbfe",
          boxShadow: "0px 0px 5px 0px #bfdbfe",
        }}
      >
        {getIcon(index)}
      </Box>
    </Box>
    <Box display="flex" alignItems="center">
      {parseFloat(change) > 0 ? (
        <TrendingUp
          size={16}
          sx={{
            color: "green",
          }}
        />
      ) : (
        <TrendingDown
          size={16}
          sx={{
            color: "red",
          }}
        />
      )}
      <Typography
        variant="body2"
        color={parseFloat(change) > 0 ? "success.main" : "error.main"}
        sx={{ ml: 0.5 }}
      >
        {change}% from last month
      </Typography>
    </Box>
  </Paper>
);

function getLastThreeYears() {
  const currentYear = new Date().getFullYear();
  return [currentYear - 1, currentYear - 2, currentYear - 3];
}

function getDateRange(value) {
  const today = new Date();
  let startDate, endDate;

  switch (value) {
    case "today":
      startDate = endDate = today.toLocaleDateString();
      break;
    case "yesterday":
      startDate = endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      ).toLocaleDateString();
      break;
    case "last7days":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      ).toLocaleDateString();
      endDate = today.toLocaleDateString();
      break;
    case "last30days":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
      ).toLocaleDateString();
      endDate = today.toLocaleDateString();
      break;
    case "last3months":
      startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      ).toLocaleDateString();
      endDate = today.toLocaleDateString();
      break;
    case "lastyear":
      startDate = new Date(today.getFullYear() - 1, 0, 1).toLocaleDateString();
      endDate = today.toLocaleDateString();
      break;

    default:
      const yaear1 = new Date(value, 0, 1, 0, 0, 2).getFullYear();
      startDate = new Date(yaear1, 0, 1).toLocaleDateString();
      endDate = new Date(yaear1, 11, 31).toLocaleDateString();
      break;
  }

  return { startDate, endDate };
}

function convertBrokerRevenueToPieFormat(brokerRevenue) {
  const brokerPiValue = [];
  if (brokerRevenue?.paid) {
    brokerPiValue.push({
      count: brokerRevenue?.paid,
      status: "Paid",
      revenue: brokerRevenue?.totalRevenue,
    });
  }

  if (brokerRevenue?.unpaid) {
    brokerPiValue.push({
      count: brokerRevenue?.unpaid,
      status: "Unpaid",
      revenue: brokerRevenue?.totalDue,
    });
  }
  return brokerPiValue;
}

const BookingAnalytics = ({ libraryId }) => {
  const { profileId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectIds, setProjectIds] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const setUpProjects = () => {
    const ids = [];
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model == "Project") {
        ids.push(team?.parent);
      }
    }
    return ids;
  };

  const fetchProjectId = async () => {
    const response = await Api.post("/profile/get", {
      profileIds: [profileId],
    });
    switch (response[0]?.parentModelName) {
      case "User":
        break;
      case "Project":
        setProjectIds([response[0].parent?._id]);
        break;
      case "Organization":
        const projects = setUpProjects();
        setProjectIds(projects.map((item) => item._id));
        break;
    }
  };

  useEffect(() => {
    if (profileId) {
      fetchProjectId();
    }
  }, [profileId]);

  const fetchBookingAnalytics = async () => {
    const response = await Api.post("listing/booking/analytics", {
      projectIds: projectIds,
      startDate: startDate,
      endDate: endDate,
    });
    if (response) {
      response.pieChartData.brokerPieData = convertBrokerRevenueToPieFormat(
        response.pieChartData.brokerPaymentStatuses
      );
      setData(response);
    } else {
      console.log("Error fetching booking analytics");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectIds.length > 0) {
      fetchBookingAnalytics();
    }
  }, [startDate, endDate, projectIds]);

  const lastThreeeYears = getLastThreeYears();

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress />
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h5" fontWeight={500} gutterBottom>
          Booking Analytics
        </Typography>
        <Select
          onChange={(event) => {
            const { startDate, endDate } = getDateRange(event.target.value);
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          variant="outlined"
          defaultValue={"last30days"}
        >
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="yesterday">Yesterday</MenuItem>
          <MenuItem value="last7days">Last 7 days</MenuItem>
          <MenuItem value="last30days">Last 30 days</MenuItem>
          <MenuItem value="last3months">Last 3 months</MenuItem>
          <MenuItem value="lastyear">Last Year</MenuItem>
          {lastThreeeYears.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Grid container spacing={1}>
        {data?.metrics?.splice(0, 4).map((metric, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <MetricCard
              index={index}
              title={metric.title}
              value={metric.value + (metric.suffix || "")}
              change={metric.change}
              unit={metric.unit}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: "1rem" }} />
      <Grid container spacing={2} gridColumn={12}>
        <Grid item xs={12} sm={6} md={6} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              Bookings
            </Typography>
            <BookingCharts data={data?.lineChartData["bookings"] ?? []} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              Transactions
            </Typography>
            <TransactionChart
              data={data?.lineChartData["transactions"] ?? []}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              Bookings stastus
            </Typography>
            <DemandDeedCharts
              data={data?.pieChartData["bookingStatuses"] ?? []}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              DemandDeed
            </Typography>
            <DemandDeedCharts
              data={data?.pieChartData["demandDeedStatuses"] ?? []}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              Transactions
            </Typography>
            <DemandDeedCharts
              data={data?.pieChartData["transactionStatuses"] ?? []}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              Broker
            </Typography>
            <DemandDeedCharts
              data={data?.pieChartData["brokerPieData"] ?? []}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        gridColumn={12}
        spacing={1}
        sx={{
          mt: "2rem",
        }}
      >
        <Grid item xs={12} sm={6} md={8}>
          <BookingTable
            tableHeader={tableHeader}
            tableData={data?.tableData["bookings"] || []}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TopBrokerTable tableData={data?.tableData["broker"] || []} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingAnalytics;

const TopBrokerTable = ({ tableData: initialData }) => {
  const tableHeader = ["Broker Name", "Total Revenue", "Current Due"];
  const [tableData, setTableData] = useState(convertBrokerData(initialData));
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortedData = [...tableData].sort((a, b) => {
      if (a[property] < b[property]) return isAsc ? -1 : 1;
      if (a[property] > b[property]) return isAsc ? 1 : -1;
      return 0;
    });

    setTableData(sortedData);
  };

  return (
    <Paper
      elevation={2}
      sx={{ width: "100%", padding: "1rem" }}
      variant="elevation"
    >
      <Typography
        variant="h6"
        sx={{
          mb: 1,
        }}
      >
        Brokers
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: "100%" }} aria-label="sortable table">
          <TableHead>
            <TableRow>
              {tableHeader.map((header, index) => (
                <TableCell
                  key={index}
                  align={index === 0 ? "left" : "right"}
                  sortDirection={orderBy === header ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={() => handleSort(header)}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.reverse().map((row) => (
              <TableRow key={row["Broker Name"]}>
                <TableCell component="th" scope="row">
                  {row["Broker Name"]}
                </TableCell>
                <TableCell align="right">{row["Total Revenue"]}</TableCell>
                <TableCell align="right">{row["Curent Due"]}</TableCell>
              </TableRow>
            ))}
            {!tableData.length > 0 && (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ height: 300 }}>
                  <Typography variant="body1">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const BookingTable = ({ tableHeader, tableData: initialData }) => {
  const [tableData, setTableData] = useState(convertBookingData(initialData));
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const data = convertBookingData(initialData);
    setTableData(data);
  }, [initialData]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortedData = [...tableData].sort((a, b) => {
      if (a[property] < b[property]) return isAsc ? -1 : 1;
      if (a[property] > b[property]) return isAsc ? 1 : -1;
      return 0;
    });

    setTableData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = tableData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Paper
      sx={{ width: "100%", padding: "1rem" }}
      variant="elevation"
      elevation={2}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        Bookings
      </Typography>

      {/* Table */}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="sortable table">
          <TableHead>
            <TableRow>
              {tableHeader.map((header, index) => (
                <TableCell
                  key={index}
                  align={index === 0 ? "left" : "right"}
                  sortDirection={orderBy === header ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={() => handleSort(header)}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row["Customer Name"]}
                </TableCell>
                <TableCell align="right">{row.Status}</TableCell>
                <TableCell align="right">{row["Booking Date"]}</TableCell>
                <TableCell align="right">{row.Project}</TableCell>
                <TableCell align="right">
                  {row["Total Payable Amount"]}
                </TableCell>
                <TableCell align="right">{row["Curent Due"]}</TableCell>
                <TableCell align="right">{row["Received Amount"]}</TableCell>
              </TableRow>
            ))}
            {!visibleRows.length && (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ height: 300 }}>
                  <Typography variant="body1">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ padding: "20px", display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(tableData.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  const [hovered, setHovered] = React.useState(false);

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={hovered ? "#2563eb" : fill} // Slightly darker shade on hover
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );
};

const BookingCharts = ({ data: initialData }) => {
  const [type, setType] = useState("aggrement");
  const data = initialData.map((booking) => ({
    name: booking?.date,
    uv: booking?.bookings,
    pv: type === "aggrement" ? booking?.aggrementValues : booking?.totalValues,
    amt: type === "aggrement" ? booking?.aggrementValues : booking?.totalValues,
  }));
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            padding: "14px",
            backgroundColor: "white",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          }}
        >
          <p className="label">{`Date: ${label}`}</p>
          <p className="intro">{`Bookings : ${payload[0]?.payload?.uv}`}</p>
          <p className="intro">{`Revenue : ₹${payload[0]?.payload?.amt}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setType(newValue);
  };

  return (
    <div style={{ width: "100%", height: "280px" }}>
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "10px",
          zIndex: 100,
        }}
      >
        <Tabs
          value={type}
          variant="standard"
          onChange={handleTabChange}
          sx={{
            border: "1px solid #e5e7eb",
            backgroundColor: "white",
          }}
        >
          <Tab
            value={"aggrement"}
            label="Aggrement value"
            sx={{
              fontSize: "12px",
              padding: "0px",
            }}
          />
          <Tab
            value={"revenue"}
            label="Total Value"
            sx={{
              fontSize: "12px",
              padding: "0px",
            }}
          />
        </Tabs>
      </div>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart data={data}>
          <Bar
            dataKey={"amt"}
            fill="#3b82f6"
            shape={<CustomBar />}
            isAnimationActive={false}
            states={{ hover: false }}
          />
          {/* <Bar
            dataKey={"uv"}
            fill="#22c55e"
            shape={<CustomBar />}
            isAnimationActive={false}
            states={{ hover: false }}
          /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const TransactionChart = ({ data: initialData }) => {
  const data = convertBookingsToChartData(initialData);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            padding: "14px",
            backgroundColor: "white",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          }}
        >
          <p className="label">{`Date: ${label}`}</p>
          <p className="intro">{`Revenue : ₹${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ width: "100%", height: "280px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="bump"
            dataKey="pv"
            stroke="#ef4444"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042"];

const ChartTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value, revenue } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p>{`${name} : ${value}`}</p>
        {revenue && <p>Amount: ₹{revenue}</p>}
      </div>
    );
  }
  return null;
};

const DemandDeedCharts = ({ data: initialData }) => {
  const pieData = convertPieData(initialData || []);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div style={{ width: "100%", height: "280px" }}>
      {" "}
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            dataKey="value"
            startAngle={360}
            endAngle={0}
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={120}
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<ChartTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

function convertBookingsToChartData(bookings) {
  const data = bookings.map((booking, index) => ({
    name: booking?.date,
    uv: booking?.bookings,
    pv: booking?.revenue,
    amt: booking?.revenue,
  }));
  return data;
}

function convertPieData(data) {
  return data.map((item) => ({
    name: item?.status,
    value: item?.count,
    revenue: item?.revenue || null,
  }));
}

function convertBookingData(data) {
  return data.map((item) => ({
    "Customer Name": item?.customerProfile?.parent?.displayName || "N/A",
    Status: item.status || "N/A",
    "Booking Date": new Date(item.createdAt).toISOString().split("T")[0],
    Project: item.project.displayName || "N/A",
    "Total Payable Amount": `₹${item?.amountReceivable}` || "₹8000",
    "Curent Due": `₹${item?.currentDue}` || "₹3000",
    "Received Amount": `₹${item?.amountReceived}` || "₹5000",
  }));
}

function convertBrokerData(data) {
  return data.map((item) => ({
    "Broker Name": item?.name || "N/A",
    "Total Revenue": `₹${item?.revenue}` || "₹8000",
    "Curent Due": `₹${item?.due}` || "₹3000",
  }));
}
