import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Api from "../../../helpers/Api";
import HorizBox from "../../styled/generic/HorizBox";

const ProjectPicker = ({
  size = "medium",
  project,
  fullWidth = false,
  setProject = () => {},
  setOrganization = () => {},
  selectedOrg,
}) => {
  console.log("project", project);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const MODES = ["User", "Project", "Organization"];
  const [mode, setMode] = useState("User");
  const [orgs, setOrgs] = useState([]);
  // const [selectedOrg, setSelectedOrg] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(project);
  const [listings, setListings] = useState([]);
  const [orgProjects, setOrgProjects] = useState([]);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        if (projects.length > 0) {
          return;
        } else {
          setProjects((prev) => [...prev, team?.parent]);
        }
      }
    }
  };

  const getOrgProjects = async () => {
    if (!selectedOrg) return;
    const data = await Api.post("/projects/by-org", {
      organizationId: selectedOrg,
    });

    if (data) {
      setOrgProjects(data.data.projects);
    } else {
      console.log("No data found for org projects", selectedOrg);
    }
  };

  useEffect(() => {
    getOrgProjects();
  }, [selectedOrg]);

  useEffect(() => {
    setUpOrgs();
    setUpProjects();
  }, []);

  console.log("orgProjects", orgProjects);

  return (
    <HorizBox>
      {selectedOrg ? (
        <FormControl fullWidth={fullWidth}>
          <InputLabel id="Project">Organization Project</InputLabel>
          <Select
            label="Project"
            value={selectedProject}
            defaultValue={project?._id}
            onChange={(e) => {
              setProject(projects.find((item) => item._id === e.target.value));

              setSelectedProject(e.target.value);
            }}
            sx={{ minWidth: "200px" }}
            size={size}
            fullWidth={fullWidth}
          >
            {orgProjects.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth={fullWidth}>
          <InputLabel id="Project">Project</InputLabel>
          <Select
            label="Project"
            value={selectedProject}
            defaultValue={project?._id}
            onChange={(e) => {
              setProject(projects.find((item) => item._id === e.target.value));

              setSelectedProject(e.target.value);
            }}
            sx={{ minWidth: "200px" }}
            size={size}
            fullWidth={fullWidth}
          >
            {projects.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {/* <Select
          value={mode}
          onChange={(e) => {
            setMode(e.target.value);
            setSelectedProject(null);
            setSelectedOrg(null);
          }}
          label="Mode"
          sx={{ minWidth: "200px" }}
          size={size}
        >
          <MenuItem key={1} value={"select"}>
            Select
          </MenuItem>
          {MODES.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {mode === "Project" && (
        
      )}

      {mode === "Organization" && (
        <FormControl>
          <InputLabel id="Organization">Organization</InputLabel>
          <Select
            label="Organization"
            value={selectedOrg}
            onChange={(e) => {
              setSelectedOrg(e.target.value);
              setOrganization(orgs.find((item) => item._id === e.target.value));
              setProject(null);
              setSelectedProject(null);
            }}
          >
            {orgs.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {mode === "Organization" && (
        <FormControl>
          <InputLabel id="Project">Project</InputLabel>
          <Select
            label="Project"
            value={selectedProject}
            onChange={(e) => {
              setSelectedProject(e.target.value);
              setProject(e.target.value);
            }}
            sx={{ minWidth: "200px" }}
            size={size}
          >
            {orgProjects.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
            )} */}
    </HorizBox>
  );
};

export default ProjectPicker;
