import React, { useState, useMemo, useEffect } from "react";
import {
  Paper,
  Select,
  MenuItem,
  Box,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputWrapper from "../../styled/CommonComponents/InputWarpper";
import Api from "../../../helpers/Api";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { getTermsAndConditions } from "../../OfferingsAndProducts/Api.call";

const TermsAndConditionsPicker = ({
  libraryId,
  selectedTermsAndConditions,
  setSelectedTermsAndConditions,
  editorContent,
  setEditorContent,
}) => {
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (libraryId) fetchTermsAndConditions();
  }, [libraryId]);

  const fetchTermsAndConditions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getTermsAndConditions({
        libraryId: libraryId,
      });
      if (response?.data) {
        setTermsAndConditions(response.data);
      } else {
        setError("No terms and conditions found");
      }
    } catch (err) {
      setError("Error fetching terms and conditions");
      console.error("Error fetching terms and conditions:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTermsChange = (event) => {
    const newTermsIds = event.target.value;
    const newTerms = termsAndConditions.filter((item) =>
      newTermsIds.includes(item._id)
    );
    setSelectedTerms(newTerms);

    const addedTerm = newTerms.find(
      (term) => !selectedTerms.find((t) => t._id === term._id)
    );

    console.log(addedTerm);

    if (addedTerm) {
      const newContent = addedTerm.content;
      const newTitle = addedTerm.title;
      setEditorContent(
        (prev) => prev + `<h3><b>${newTitle}</b></h3>` + newContent + "<br><br>"
      );
    }
    setSelectedTermsAndConditions(newTerms);
  };

  const memoizedSelect = useMemo(
    () => (
      <Select
        fullWidth
        value={selectedTerms.map((item) => item._id)}
        onChange={handleTermsChange}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selected.map((value) => {
              const selectedItem = termsAndConditions.find(
                (item) => item._id === value
              );
              return (
                <Typography variant="outlined" key={value}>
                  {selectedItem?.title || "Unknown"}
                </Typography>
              );
            })}
          </Box>
        )}
      >
        {termsAndConditions.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    ),
    [termsAndConditions, selectedTerms]
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box style={{ marginTop: "20px" }}>
      {memoizedSelect}
      <div
        style={{
          marginTop: "20px",
          maxHeight: "400px",
        }}
      >
        <ReactQuill
          theme="snow"
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
          value={editorContent}
          onChange={setEditorContent}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              ["clean"],
            ],
          }}
        />
      </div>
    </Box>
  );
};

export default TermsAndConditionsPicker;
