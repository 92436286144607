import Api from '../../helpers/Api';

export const getBillingAcountAgainstProfile = async (obj) => {
    try {
        const res = await Api.post('wallet/billing/billing-account/sharedIds/get', obj);
        const result = res?.billingAccounts || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const createMultipleTxs = async (obj) => {
    try {
        const res = await Api.post('listing/booking/multiple/transactions/create', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const transferStatusToAccountingTx = async (obj) => {
    try {
        const res = await Api.post('listing/booking/transaction/status/transfer', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingPaginatedTransactions = async (obj) => {
    try {
        const res = await Api.post('/listing/booking/transaction/get', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};