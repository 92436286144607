import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import HorizBox from "./HorizBox";
import Api from "../../../helpers/Api";
import { Select } from "@mui/material";

const BankAccountPicker = ({
  size = "medium",
  selectedBankAccount,
  setSelectedBankAccount,
  defaultBankAccountId,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const MODES = ["User", "Project", "Organization"];
  const [mode, setMode] = useState("User");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        setProjects((prev) => [...prev, team?.parent]);
      }
    }
  };

  useEffect(() => {
    setUpOrgs();
    setUpProjects();
  }, []);

  const getBankAccountsByMode = async () => {
    try {
      let _id =
        mode === "Organization"
          ? selectedOrg?._id
          : mode === "Project"
            ? selectedProject?._id
            : user._id;

      if (!_id) return console.log("No _id found");

      const { data } = await Api.post("/wallet/bank/getBanksByMode", {
        modeId: _id,
        mode: mode,
      });
      setBankAccounts(data);
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getBankAccountsByMode();
  }, [mode, selectedOrg?._id, selectedProject?._id]);

  useEffect(() => {
    setSelectedBankAccount();
  }, [mode]);

  useEffect(() => {
    if (defaultBankAccountId) {
      console.log("Setting default BankAccount", defaultBankAccountId);
      setSelectedBankAccount(defaultBankAccountId);
    }
  }, [defaultBankAccountId]);

  return (
    <HorizBox>
      <Autocomplete
        options={MODES}
        value={mode}
        onChange={(event, value) => {
          setMode(value)
        }}
        disableClearable
        size="small"
        style={{ width: "170px", marginRight: "10px" }}
        getOptionLabel={(option) => option || ""}
        renderInput={(params) => (
          <TextField
            size="small"
            variant="outlined"
            {...params}
            placeholder={"Select Mode"}
          />
        )}
      />
      {mode === "Project" && (
        <Autocomplete
          options={projects}
          value={selectedProject}
          onChange={(event, value) => {
            setSelectedProject(value)
          }}
          disableClearable
          size="small"
          style={{ width: "300px", marginRight: "10px" }}
          getOptionLabel={(option) => option?.displayName || ""}
          renderInput={(params) => (
            <TextField
              size="small"
              variant="outlined"
              {...params}
              placeholder={"Select Project"}
            />
          )}
        />
      )}
      {mode === "Organization" && (
        <Autocomplete
          options={orgs}
          value={selectedOrg}
          onChange={(event, value) => {
            setSelectedOrg(value)
          }}
          disableClearable
          size="small"
          style={{ width: "300px", marginRight: "10px" }}
          getOptionLabel={(option) => option?.displayName || ""}
          renderInput={(params) => (
            <TextField
              size="small"
              variant="outlined"
              {...params}
              placeholder={"Select Organization"}
            />
          )}
        />
      )}
      <FormControl variant="outlined" style={{ width: "250px" }} >
        <InputLabel id="BankAccount">Bank Account</InputLabel>
        <Select
          label="BankAccount"
          value={selectedBankAccount}
          onChange={(e) => setSelectedBankAccount(e.target.value)}
          sx={{ minWidth: "120px" }}
        >
          <MenuItem value="">Select</MenuItem>
          {bankAccounts?.map((item) => (
            <MenuItem key={item?._id} value={item?._id}>
              {item?.bankName || "Untitled"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </HorizBox>
  );
};

export default BankAccountPicker;
