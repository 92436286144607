function formatToRupees(amount) {
  let amountStr = amount.toString();
  let lastThreeDigits = amountStr.substring(amountStr.length - 3);
  let otherDigits = amountStr.substring(0, amountStr.length - 3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  }

  return otherDigits + lastThreeDigits;
}

export default formatToRupees;
