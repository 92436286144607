import {
  Box,
  IconButton,
  Typography,
  Drawer,
  TextField,
  Card,
  CardContent,
  Divider,
  Grid,
} from "@mui/material";
import {
  Schedule as ScheduleIcon,
  Business as BusinessIcon,
  AccountTree as AccountTreeIcon,
  Create as CreateIcon,
  Update as UpdateIcon,
  Layers as LayersIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../styled/generic/Button";
import HorizBox from "../../styled/generic/HorizBox";
import FormBox from "../../styled/generic/FormBox";
import TableContainer from "../../styled/generic/TableContainer";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import { Close, Edit } from "@material-ui/icons";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { DataGrid } from "@mui/x-data-grid";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";

const BOOKING_THRESHOLD = 50;

const Schedule = ({ libraryId }) => {
  const columns = [
    {
      field: "id",
      headerName: "Sr No.",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return <>{params?.row?.srno}</>;
      },
    },
    {
      field: "displayName",
      headerName: "Display Name",
      sortable: false,
      width: 250,
    },
    { field: "unit", headerName: "Unit name", width: 250, sortable: false },
    {
      field: "staus",
      headerName: "Status",
      sortable: false,
      valueGetter: (value, row) => `${row?.status?.toLowerCase()}`,
    },
  ];
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const history = useHistory();
  const [slabs, setSlabs] = useState([]);
  const [openImportDrawer, setOpenImportDrawer] = useState(false);

  const [scheduleId, setScheduleId] = useState("");
  const [schedule, setSchedule] = useState([]);

  const [highestCompletionOrder, setHighestCompletionOrder] = useState(0);
  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] =
    useState(false);
  const [markAsCompletedIndex, setMarkAsCompletedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [lastCompleteSlab, setLastCompleteSlab] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [selectedBookings1, setSelectedBookings1] = useState([]);
  const [selectedBookings2, setSelectedBookings2] = useState([]);

  useEffect(() => {
    let newRows1 = [];
    let newRows2 = [];

    if (bookings.length > BOOKING_THRESHOLD) {
      newRows1 = bookings
        .slice(0, bookings.length / 2)
        .map((booking, index) => ({
          srno: index + 1,
          id: booking._id,
          displayName: booking?.customerProfile?.parent?.displayName,
          unit: booking.unit?.name,
          status: booking.status,
        }));

      newRows2 = bookings
        .slice(bookings.length / 2, bookings.length)
        .map((booking, index) => ({
          srno: index + 1,
          id: booking._id,
          displayName: booking?.customerProfile?.parent?.displayName,
          unit: booking.unit?.name,
          status: booking.status,
        }));
    } else {
      newRows1 = bookings.map((booking, index) => ({
        srno: index + 1,
        id: booking._id,
        displayName: booking?.customerProfile?.parent?.displayName,
        unit: booking.unit?.name,
        status: booking.status,
      }));
      newRows2 = [];
    }
    setRows1(newRows1);
    setRows2(newRows2);
  }, [bookings]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const _scheduleId = searchParams.get("scheduleId");
    setScheduleId(_scheduleId);
  }, [location.search]);

  const getSchedule = async () => {
    setTableLoading(true);
    try {
      if (!scheduleId) {
        return;
      }
      const { data } = await Api.post("/listing/booking/schedule/get-by-id", {
        scheduleId: scheduleId,
      });
      if (data) {
        setBookings(data?.bookings);
        setSchedule(data);
        setSlabs(data?.slabs);
        const completedSchedule = data.slabs.filter((slab) => slab.isCompleted);
        setLastCompleteSlab(completedSchedule[completedSchedule.length - 1]);

        let highest = 1;
        // Find slab which is completed and has highest order
        data.slabs.forEach((slab) => {
          if (slab.isCompleted && slab.order > highest) {
            highest = slab.order;
          }
        });
        setHighestCompletionOrder(highest);
        console.log("order: ", highest);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    getSchedule();
  }, [scheduleId]);

  const handlePrepare = async (selectedBookings) => {
    setLoading(true);
    const data = await Api.post("/listing/booking/payment/demanddeed/prepare", {
      slabId: lastCompleteSlab?._id,
      selectedBookings: selectedBookings1.concat(selectedBookings2),
      userId: user?._id,
      profile: user?.profile,
      firstPartyProfile: profileId
    });
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Demand deed prepared successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setOpen(false);
    setLoading(false);
  };

  const markAsCompleted = async (index) => {
    const previousSlabs = slabs
      .slice(0, index + 1)
      .filter((x) => x.isCompleted === false);

    try {
      const { data } = await Api.post(
        "/listing/booking/schedule/slab/mark-as-completed",
        {
          slabIds: previousSlabs.map((slab) => slab._id),
        }
      );
      if (data) {
        setSlabs((prev) =>
          prev.map((item) => {
            const updatedSlab = data.find((slab) => slab._id === item._id);
            return updatedSlab ? { ...item, isCompleted: true } : item;
          })
        );
        setHighestCompletionOrder(highestCompletionOrder + 1);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  console.log(selectedBookings1.concat(selectedBookings2));

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",

              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              {schedule?.title || "Schedule"}
            </Typography>
            <IconButton
              onClick={() => {
                history.push(
                  `/booking/settings/${profileId}?view=scheduleedit&scheduleId=${scheduleId}`
                );
              }}
            >
              <Edit />
            </IconButton>
          </Box>
          <Button
            startIcon={<ArrowCircleDownIcon />}
            variant="outlined"
            sx={{
              width: "fit-content",
            }}
            onClick={() => {
              setOpenImportDrawer(true);
            }}
          >
            Import
          </Button>
        </Box>

        <Divider sx={{ my: 2 }} />
        <HorizBox>
          <FormBox label="Project">
            <Typography variant="body1">
              {schedule?.project?.displayName || "N/A"}
            </Typography>
          </FormBox>
          <FormBox label="Block">
            <Typography variant="body1">
              {schedule?.projectBlock?.name || "N/A"}
            </Typography>
          </FormBox>
          <FormBox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant="h6">
                  Booking {schedule?.bookings?.length}
                </Typography>
                <Button
                  startIcon={<Edit />}
                  variant="outlined"
                  sx={{
                    width: "fit-content",
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                  size={"large"}
                >
                  Prepare Demand Deeds
                </Button>
              </Box>
            </Box>
          </FormBox>
        </HorizBox>

        <Divider sx={{ my: 2 }} />

        <Box>
          <FormBox label="Slabs">
            <TableContainer
              columns={[
                "TYPE",
                "TITLE",
                "DESCRIPTION",
                "DUE DATE",
                "PERCENTAGE OF PAYMENT",
                "CUMULATIVE PERCENTAGE",
                "ACTIONS",
              ]}
              data={slabs}
              containerHeight="auto"
              headerAlignment={"center"}
              loading={tableLoading}
            >
              {slabs.map((slab, index) => {
                let date =
                  slab.type === "milestone" ? new Date(slab?.dueDate) : null;
                return (
                  <tr key={index} style={{ borderBottom: "1px solid #e0e0e0" }}>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      {slab?.type?.toUpperCase() || "Untitled"}
                    </td>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      {slab?.title || "Untitled"}
                    </td>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      {slab?.description || "N/A"}
                    </td>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      {date
                        ? `${date?.getDate()}/${date?.getMonth()}/${date?.getFullYear()}`
                        : "N?N"}
                    </td>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "#e6f4ea",
                          color: "#1e8e3e",
                          padding: "4px 8px",
                          borderRadius: "12px",
                          fontSize: "14px",
                        }}
                      >
                        {slab?.paymentPercentage || "0"}%
                      </span>
                    </td>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "#f1f3f4",
                          color: "#5f6368",
                          padding: "4px 8px",
                          borderRadius: "12px",
                          fontSize: "14px",
                        }}
                      >
                        {slab?.cumulativePercentage || "0"}%
                      </span>
                    </td>
                    <td style={{ padding: "16px", textAlign: "center" }}>
                      {slab.type === "milestone" ? (
                        slab?.isCompleted ? (
                          <span
                            style={{
                              backgroundColor: "#e6f4ea",
                              color: "#1e8e3e",
                              padding: "4px 8px",
                              borderRadius: "12px",
                              fontSize: "14px",
                            }}
                          >
                            ✓
                          </span>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setMarkAsCompletedIndex(index);
                              setIsComfirmationDialogOpen(true);
                            }}
                            disabled={
                              parseInt(slab?.order) <
                              parseInt(highestCompletionOrder)
                            }
                          >
                            {slab.isCompleted
                              ? "Completed"
                              : "Mark as Completed"}
                          </Button>
                        )
                      ) : (
                        <Button variant="outlined" disabled={true}>
                          Aggrement Based
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </TableContainer>
          </FormBox>
        </Box>
      </Box>
      <DemandDeedPrepareDrawer
        open={open}
        BOOKING_THRESHOLD={BOOKING_THRESHOLD}
        bookings={bookings}
        handlePrepare={handlePrepare}
        lastCompleteSlab={lastCompleteSlab}
        loading={loading}
        schedule={schedule}
        setOpen={setOpen}
      />

      <ConfirmationDialog
        title={"Are you absolutely sure?"}
        cancelButtonText={"cancel"}
        cancelListener={() => setIsComfirmationDialogOpen(false)}
        message={"Are you sure you want to mark this Slab as Complete?"}
        open={isComfirmationDialogOpen}
        setOpen={setIsComfirmationDialogOpen}
        successButtonText={"Confirm"}
        successListener={() => markAsCompleted(markAsCompletedIndex)}
      />
      <ImportScheduleDrawer
        isOpen={openImportDrawer}
        setIsOpen={setOpenImportDrawer}
        onImport={getSchedule}
        currentScheduleId={scheduleId}
        libraryId={libraryId}
      />
    </>
  );
};

export default Schedule;

export const DemandDeedPrepareDrawer = ({
  open,
  setOpen,
  loading,
  bookings,
  schedule,
  lastCompleteSlab,
  handlePrepare,
}) => {
  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [selectedBookings1, setSelectedBookings1] = useState([]);
  const [selectedBookings2, setSelectedBookings2] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Sr No.",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return <>{params?.row?.srno}</>;
      },
    },
    {
      field: "displayName",
      headerName: "Display Name",
      sortable: false,
      width: 250,
    },
    { field: "unit", headerName: "Unit name", width: 250, sortable: false },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      valueGetter: (value, row) => `${row?.status?.toLowerCase()}`,
    },
  ];

  useEffect(() => {
    let newRows1 = [];
    let newRows2 = [];

    if (bookings.length > BOOKING_THRESHOLD) {
      newRows1 = bookings
        .slice(0, bookings.length / 2)
        .map((booking, index) => ({
          srno: index + 1,
          id: booking._id,
          displayName: booking?.customerProfile?.parent?.displayName,
          unit: booking.unit?.name,
          status: booking.status,
        }));

      newRows2 = bookings
        .slice(bookings.length / 2, bookings.length)
        .map((booking, index) => ({
          srno: index + 1,
          id: booking._id,
          displayName: booking?.customerProfile?.parent?.displayName,
          unit: booking.unit?.name,
          status: booking.status,
        }));
    } else {
      newRows1 = bookings.map((booking, index) => ({
        srno: index + 1,
        id: booking._id,
        displayName: booking?.customerProfile?.parent?.displayName,
        unit: booking.unit?.name,
        status: booking.status,
      }));
      newRows2 = [];
    }
    setRows1(newRows1);
    setRows2(newRows2);
  }, [bookings, BOOKING_THRESHOLD]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        if (!loading) {
          setOpen(false);
        }
      }}
      anchor="right"
      sx={{
        cursor: loading && "not-allowed",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          borderRadius: "10px",
          width: bookings.length > BOOKING_THRESHOLD ? "100vw" : "50vw",
          pt: 2,
          px: 4,
          pb: 3,
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.8rem",
          }}
        >
          <Box
            sx={{
              marginBottom: "0.8rem",
            }}
          >
            <Typography variant="h5" component="h2" color={"primary"}>
              Prepare demand deeds
            </Typography>
            <Typography variant="body1">
              Applicable bookings {schedule?.bookings?.length}
            </Typography>
          </Box>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: "50px",
            width: "100%",
            border: "1px solid #25763B",
            borderRadius: "5px",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "48px",
              width: `${lastCompleteSlab?.cumulativePercentage}%`,
              borderRight: "1px solid #25763B",
              backgroundColor: "#B5E8C3",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#25763B",
              }}
            >
              {lastCompleteSlab?.cumulativePercentage}%
            </Typography>
          </Box>
        </Box>
        <FormBox label={"Select exclude bookings"}>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <div style={{ minHeight: 200, width: "100%" }}>
              <DataGrid
                rows={rows1}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                selectionModel={rows1.map((item) => item?.id)}
                pageSizeOptions={[15, 20]}
                checkboxSelection
                onRowSelectionModelChange={(e) => {
                  setSelectedBookings1(e);
                }}
                sx={{
                  fontSize: "18px",
                }}
              />
            </div>
            {rows2.length > 0 && (
              <div style={{ minHeight: 200, width: "100%" }}>
                <DataGrid
                  rows={rows2}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  selectionModel={bookings.map((item) => item?._id)}
                  pageSizeOptions={[15, 20]}
                  checkboxSelection
                  onRowSelectionModelChange={(e) => {
                    setSelectedBookings2(e);
                  }}
                  sx={{
                    fontSize: "18px",
                  }}
                />
              </div>
            )}
          </div>
        </FormBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={
              bookings.length > BOOKING_THRESHOLD
                ? selectedBookings1.concat(selectedBookings2).length ===
                bookings?.length
                : selectedBookings1.length === bookings?.length
            }
            variant={"contained"}
            onClick={() => {
              handlePrepare(selectedBookings1.concat(selectedBookings2));
            }}
            loading={loading}
          >
            Prepare
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

const ImportScheduleDrawer = ({
  isOpen,
  setIsOpen,
  onImport,
  libraryId,
  currentScheduleId,
}) => {
  const [loading, setLoading] = useState(false);
  const [slabView, setSlabView] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const dispatch = useDispatch();

  const getSchedules = useCallback(async () => {
    const { data } = await Api.post("/listing/booking/schedule/get", {
      libraryId: libraryId,
    });
    if (data) {
      const structuredData = data.map((item) => ({
        _id: item._id,
        title: item.title,
        projectName: item.project?.displayName,
        projectBlock: item?.projectBlock?.name,
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
        slabsLength: item?.slabs.length,
        slabs: item?.slabs,
      }));
      setSchedules(structuredData);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (libraryId) {
      getSchedules();
    }
  }, [isOpen]);

  const handleImport = async () => {
    setLoading(true);
    const payload = {
      libraryId: libraryId,
      scheduleId: selectedSchedule?._id,
      slabs: selectedSchedule?.slabs || [],
      currentScheduleId: currentScheduleId,
    };

    const data = await Api.post("/listing/booking/schedule/import", payload);
    if (data) {
      console.log(data);
      onImport();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: data?.message,
        },
      });
      setSelectedSchedule(null);
      setSlabView(false);
      setIsOpen(false);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error While importing Schdule",
        },
      });
    }
    setLoading(false);
  };

  return (
    <DrawerContainer
      open={isOpen}
      title={"Import Schedule"}
      setOpen={setIsOpen}
      onClose={setIsOpen}
    >
      <Box
        sx={{
          minWidth: "400px",
          width: "400px",
          height: "88vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: "91%",
            width: "100%",
          }}
        >
          {/* add this  */}
          {slabView ? (
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
              }}
            >
              <TextField
                placeholder=""
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  marginBottom: "10px",
                }}
              />
              {schedules.map((data) => (
                <ProjectDetailsCard
                  key={data.title}
                  data={data}
                  selectedSchedule={selectedSchedule}
                  onSelect={(data) => {
                    setSelectedSchedule(data);
                    setSlabView(true);
                  }}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
              }}
            >
              <TextField
                placeholder=""
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  marginBottom: "10px",
                }}
              />
              {schedules.map((data) => (
                <ProjectDetailsCard
                  key={data.title}
                  data={data}
                  selectedSchedule={selectedSchedule}
                  onSelect={(data) => {
                    setSelectedSchedule(data);
                    setSlabView(true);
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <DuoButtonGroup
            primaryButtonText={slabView ? "Save" : "Import"}
            primaryButtonListener={() => {
              handleImport();
            }}
            hideSecondary={true}
            loadingPrimary={loading}
          />
        </Box>
      </Box>
    </DrawerContainer>
  );
};

const ProjectDetailsCard = ({ data, onSelect, selectedSchedule }) => {
  return (
    <Card
      variant="outlined"
      elevation={true}
      onClick={() => onSelect(data)}
      sx={{
        mb: 1,
        cursor: "pointer",
        border: selectedSchedule?._id === data?._id && "1px solid blue",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
        "&:active": {
          backgroundColor: "Background",
          border: "1px solid blue",
        },
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom color="primary">
          <Box display="flex" alignItems="center">
            <ScheduleIcon sx={{ mr: 1 }} />
            {data.title}
          </Box>
        </Typography>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
        >
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            sx={{
              gridColumn: "span 1/ span 1",
            }}
          >
            <Box display="flex" alignItems="start">
              <BusinessIcon sx={{ mr: 1, color: "primary.main" }} />
              Project: {data.projectName}
            </Box>
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            sx={{
              gridColumn: "span 1/ span 1",
            }}
          >
            <Box display="flex" alignItems="start">
              <AccountTreeIcon sx={{ mr: 1, color: "primary.main" }} />
              Block: {data.projectBlock}
            </Box>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{
              gridColumn: "span 1/ span 1",
            }}
          >
            <Box display="flex" alignItems="center">
              <CreateIcon sx={{ mr: 1, color: "primary.main" }} />
              Created: {new Date(data.createdAt).toLocaleDateString()}
            </Box>
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{
              gridColumn: "span 1/ span 1",
            }}
          >
            <Box display="flex" alignItems="start">
              <UpdateIcon sx={{ mr: 1, color: "primary.main" }} />
              Updated: {new Date(data.updatedAt).toLocaleDateString()}
            </Box>
          </Typography>
        </Box>

        <Typography variant="body2" color="text.secondary">
          <Box display="flex" alignItems="center">
            <LayersIcon sx={{ mr: 1, color: "primary.main" }} />
            Slabs: {data.slabsLength}
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
};
