import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import HorizBox from "../styled/generic/HorizBox";
import Button from "../styled/generic/Button";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  CircularProgress,
  Chip,
  InputAdornment,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import Choose from "../select/choose";
import NumberFormat from "react-number-format";
import {
  Add,
  AlarmOnOutlined,
  KeyboardArrowDownOutlined,
  MessageOutlined,
  NoteOutlined,
  PhoneOutlined,
  TaskAlt,
} from "@material-ui/icons";
import {

  People,

} from "@mui/icons-material";
import ProjectPicker from "../styled/generic/ProjectPicker.jsx";
import OrgPicker from "../styled/generic/OrgPicker.jsx";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import SpaceBetween from "../styled/generic/SpaceBetween";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import { useDispatch, useSelector } from "react-redux";
import DrawerContainer from "../styled/generic/DrawerContainer";
import SearchEntityDialog from "../styled/CommonComponents/SearchEntityDrawer";
import Select from "../styled/generic/Select";
import Api from "../../helpers/Api";
import ImgTextChip from "../styled/generic/ImgTextChip";
import dayjs from "dayjs";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import { Delete, EditOutlined, FilePresentOutlined } from "@mui/icons-material";
import DatePicker from "../styled/generic/DatePicker";
import TimePicker from "../styled/generic/TimePicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllFiles } from "../styled/CommonComponents/api.call";
import TaskPicker from "../styled/generic/TaskPicker";
import { add } from "lodash";
import TableContainer from "../styled/generic/TableContainer";
import PopupMenuList from "../styled/generic/PopupMenuList";
import MoreButton from "../styled/generic/MoreButton";
import FilesViewer from "../file/Viewer/FilesViewer.js";
import useShareAndAssign from "../ResourceAccessControl/shareAndAssign";
import config from "../../config/index";
import { setAuthCookieData } from "../../helpers/cookie.helper";
import CreatePalDrawer from "../AddMember/CreatePalDrawer";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell";
import ProjectBlockAndUnitPickerDropdown from "../styled/generic/ProjectBlockAndUnitPickerDropdown";
import { GiArrowScope } from "react-icons/gi";
import InputBase from "@mui/material/InputBase";
import Autocomplete from "@mui/material/Autocomplete";
import { useDebounce } from "react-use";
import axios from "axios";
import keyConfig from "../../config/keys.config";
import { updateDeleteFlagForSingleFiles } from "../propertyManagement/apiCall";
import ActionView from "./issueactivity/action.view.js"
import Pagination from "@mui/material/Pagination";




const DURATION_OPTIONS = [
  { label: '15 mins', minutes: 15 },
  { label: '30 mins', minutes: 30 },
  { label: '1 hour', minutes: 60 },
  { label: '2 hours', minutes: 120 },
];

const ProfileIssueCRM = ({ getIssueCall, issue, setIssue }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fileDictionary, createdFileIds } = useSelector((state) => state.file);
  const [actionTab, setActionTab] = useState("Notes");
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [activitySearchStr, setActivitySearchStr] = useState("");
  const [note, setNote] = useState("");
  const [activities, setActivities] = useState([]);
  const [lead, setLead] = useState(false);
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  const [itemToPreview, setItemToPreview] = useState(null);
  const [showRequirementDrawer, setShowRequirementDrawer] = useState(false);
  const [actionTitle, setActionTitle] = useState("");
  const [actionDescription, setActionDescription] = useState("");
  const [actionDate, setActionDate] = useState(dayjs());
  const [showTaskPicker, setShowTaskPicker] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [showUpdatePalDrawer, setShowUpdatePalDrawer] = useState(false);
  const [openChooseProject, setOpenChooseProject] = useState(false);
  const [openChooseOrganization, setOpenChooseOrganization] = useState(false);
  const [chosenProject, setChosenProject] = useState(null);
  const [chosenOrganization, setChosenOrganization] = useState("");
  const [selectedProjectBlock, setSelectedProjectBlock] = useState(issue?.projectBlock || null);
  const [selectedProjectUnit, setSelectedProjectUnit] = useState(issue?.unit || null);
  const [placeText, setPlaceText] = useState("");
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(30);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalPages, setTotalPages] = useState(1); // Total pages returned from backend

  console.log("lead data :: ", lead);

  const updateIssue = async (statusId) => {
    try {
      const res = await Api.post("issue/updateFromKanban", {
        issueId: issue?._id,
        status: statusId,
      });
      if (res?.success) {
        // dispatch({
        //   type: "AddApiAlert",
        //   payload: {
        //     success: true,
        //     message: "Status updated successfully",
        //   },
        // });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: res?.message || "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  const TIME_MODES = [
    "1 day",
    "1 week",
    "15 days",
    "1 months",
    "3 months",
    "6 months",
    "9 months",
    "1 year",
    "Custom",
  ];
  const REQUIREMENT_TYPE_OPTIONS = [
    "Rent",
    "Sell",
    "Roommate",
    "Job",
    "Investment",
    "Tender",
  ];
  const [actionDateMode, setActionDateMode] = useState("1 day");
  const [requirementTitle, setRequirementTitle] = useState("");
  const [requirementType, setRequirementType] = useState("Rent");

  const { user, userProfile } = useSelector((state) => state.auth);

  let actionTabOptions = [
    {
      title: "Notes",
      icon: NoteOutlined,
    },
    {
      title: "Actions",
      icon: AlarmOnOutlined,
    },
    {
      title: "Documents",
      icon: FilePresentOutlined,
    },
    {
      title: "Tasks",
      icon: GiArrowScope,
    },
    {
      title: "SMS",
      icon: MessageOutlined,
    },
    {
      title: "Call",
      icon: PhoneOutlined,
    },
  ];

  const saveAction = async () => {
    try {
      let locationObj = null;
      if (chosenProject) {
        locationObj = {
          name: chosenProject.name,
        };
      } else if (selectedPlace) {
        const {
          address_line1,
          address_line2,
          name,
          city,
          country,
          country_code,
          lat,
          lon,
          state,
          state_code,
          postcode,
        } = selectedPlace;

        locationObj = {
          address_line1,
          address_line2,
          name,
          city,
          country,
          country_code,
          loc: {
            type: "Point",
            coordinates: [lat, lon],
          },
          state,
          state_code,
          postcode,
        };
      }

      const payload = {
        issueId: issue?._id,
        type: actionTab === "Notes" ? "Note" :
          actionTab === "Documents" ? "Doc" : "Action",
        userProfileId: user?.profile,
        templateId: issue?.template?._id,
        tags: issue.tags
      };

      switch (actionTab) {
        case "Notes":
          payload.note = note;
          break;
        case "Documents":
          payload.fileIds = createdFileIds;
          break;
        default:
          // Action type
          payload.actionType = actionType;
          payload.actionTitle = actionType;
          payload.actionDescription = chosenProject ?
            `${actionType} at ${chosenProject.name}` :
            `${actionType} at ${selectedPlace?.name}`;
          payload.actionDate = actionDate.toDate();
          payload.actionDateMode = actionDateMode;
          payload.location = locationObj;
          payload.endDate = actionType === "Meeting" ?
            dayjs(actionDate).add(selectedDuration, 'minutes').toDate() :
            undefined;
          payload.durationMins = actionType === "Meeting" ? selectedDuration : undefined;
          payload.managers = selectedManagers.map(manager => manager._id);
          payload.leadProfile = lead?.profile?._id || lead?.profile;
          break;
      }

      const { data } = await Api.post("/issue/activity/create", payload);

      if (data) {
        // Reset form based on type
        switch (actionTab) {
          case "Notes":
            setNote("");
            break;
          case "Documents":
            dispatch({ type: "FileUploadReset" });
            break;
          default:
            setSelectedPlace(null);
            setPlaceText("");
            setActionDate(dayjs());
            break;
        }

        setActivities([...activities, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: `${actionTab === "Notes" ? "Note" :
              actionTab === "Documents" ? "Document" : "Action"} added successfully`,
          },
        });
        setActionTab("Notes")
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteDocByFile = async (fileId) => {
    try {
      const { data } = await Api.post("/issue/activity/update", {
        issueActivityId: itemToPreview?._id,
        fileId: fileId,
        type: "Doc",
      });
      if (data) {
        setActivities(
          activities.map((item) => (item?._id === data?._id ? data : item)) ||
          []
        );
        setItemToPreview();
        setShowPreviewDrawer(false);

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Docs updated successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };


  const getActivities = async (filterType, page, limit) => {
    try {
      setLoadingActivities(true);
      const response = await Api.post("/issue/activity/get", {
        issueId: issue?._id,
        type: filterType,
        page: page,
        limit: limit,
      });
      if (response.data && response.data) {
        setActivities(response.data);
        setTotalPages(response.totalPages);
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoadingActivities(false);
    }
  };

  // useEffect(() => {
  //   if (issue?._id) {
  //     getActivities(activityFilter, currentPage, pageSize);
  //   }
  // }, [activityFilter, currentPage, pageSize, issue?._id]);



  const connectLeadWithIssue = async (lead) => {
    try {
      const { data } = await Api.post("/issue/activity/connect-lead", {
        issueId: issue?._id,
        finrelId: lead?.finrel?._id || lead?.finrel,
      });
      if (data) {
        setLead(data?.finrel);
        setIssue({
          ...issue,
          source: data.source,
          sourceNote: data.sourceNote
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getActivities();
  }, []);

  useEffect(() => {
    console.log(issue)

    setLead(issue?.finrel);
    setRequirementTitle(issue?.finrel?.requirement?.title);
    setRequirementType(issue?.finrel?.requirement?.type || "Rent");
    setTasks(issue?.tasks || []);
  }, [issue]);

  useEffect(() => {
    switch (actionDateMode) {
      case "1 day":
        setActionDate(dayjs().add(1, "day").hour(9).minute(0));
        break;
      case "1 week":
        setActionDate(dayjs().add(1, "week").hour(9).minute(0));
        break;
      case "15 days":
        setActionDate(dayjs().add(15, "day").hour(9).minute(0));
        break;
      case "1 months":
        setActionDate(dayjs().add(1, "month").hour(9).minute(0));
        break;
      case "3 months":
        setActionDate(dayjs().add(3, "month").hour(9).minute(0));
        break;
      case "6 months":
        setActionDate(dayjs().add(6, "month").hour(9).minute(0));
        break;
      case "9 months":
        setActionDate(dayjs().add(9, "month").hour(9).minute(0));
        break;
      case "1 year":
        setActionDate(dayjs().add(1, "year").hour(9).minute(0));
        break;

      default:
        break;
    }
  }, [actionDateMode]);

  const editRequirement = async () => {
    try {
      const { data } = await Api.post("/listing/requirements/update", {
        requirementId: lead?.requirement?._id,
        updateData: {
          title: requirementTitle,
          requirementType: requirementType,
        },
      });
      if (data) {
        setIssue({
          ...issue,
          finrel: {
            ...issue?.finrel,
            requirement: {
              ...issue?.finrel?.requirement,
              title: requirementTitle,
              requirementType: requirementType,
            },
          },
        });
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Requirement updated successfully",
          },
        });
        setShowRequirementDrawer(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const handlePreview = (item) => {
    setItemToPreview(item);
    setShowPreviewDrawer(true);
    switch (item?.type) {
      case "Note":
        setNote(item?.note);
        break;

      case "Action":
        setActionTitle(item?.calendarEvent?.title);
        setActionDescription(item?.calendarEvent?.description);
        setActionDate(dayjs(item?.calendarEvent?.startDate));
        setActionDateMode(item?.actionDateMode || "1 day");
        break;

      case "Doc":
        let docs = item?.docs;
        let _files = [];
        for (let i = 0; i < docs.length; i++) {
          let doc = docs[i];
          _files = [..._files, ...doc.files];
        }
        setFiles(_files);
        break;

      default:
        break;
    }
  };

  const addTasksToIssue = async (taskIds) => {
    try {
      const { data } = await Api.post("/issue/add-tasks", {
        issueId: issue?._id,
        taskIds: taskIds,
      });
      if (data) {
        setIssue({
          ...issue,
          tasks: data.tasks,
        });

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Tasks added successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const removeTaskFromIssue = async (taskId) => {
    try {
      const { data } = await Api.post("/issue/remove-task", {
        issueId: issue?._id,
        taskId: taskId,
      });
      if (data) {
        setIssue({
          ...issue,
          tasks: data.tasks,
        });

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task removed successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const [shared, setShared] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );
  const projectProfileId =
    issue?.project?.profile?._id || issue?.project?.profile;
  const orgProfileId =
    issue?.organization?.profile?._id || issue?.organization?.profile;
  const userProfileId = user?.profile;
  //Initalize
  useEffect(() => {
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: issue?._id,
    resourceName: "Issue",
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusOption, setStatusOption] = useState("");

  useEffect(() => {
    let template = issue?.template;
    if (template) {
      setStatusOptions(template.pipeline);
    }
  }, [issue]);

  useEffect(() => {
    setStatusOption(issue?.status);
  }, [statusOptions]);

  const [activityFilter, setActivityFilter] = useState("All");

  useEffect(() => {
    if (!issue || !statusOption) return;
    if (issue?.status !== statusOption) {
      updateIssue(statusOption);
    }
  }, [statusOption]);

  const [mainProfileWithParent, setMainProfileWithParent] = useState(null);
  const [profileIdArr, setProfileIdArr] = useState([]);

  useEffect(() => {
    if (issue) {
      setMainProfileWithParent(issue?.profile);
      setProfileIdArr([issue?.profile?._id]);
    }
  }, [issue]);

  useEffect(() => {
    setChosenOrganization(issue?.organization);
    setChosenProject(issue?.project);

    setChosenProject(issue?.project);
    setChosenOrganization(issue?.organization);
  }, [issue]);

  const updateIssueApi = async () => {
    const issueObject = {
      _id: issue?._id,
      project: chosenProject,
      organization: chosenOrganization,
      projectBlock: selectedProjectBlock,
      unit: selectedProjectUnit,
      dealValue: issue.dealValue
    };

    const res = await Api.post("issue/update", issueObject);
    if (!res) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
      return;
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ticket updated successfully",
        },
      });
    }

    dispatch({ type: "FileUploadReset" });
    history.goBack();
  };

  const ACTION_TYPES = [
    "Site Visit",
    "Meeting",
    "Phone Call",
    "Follow-up",
    "Other"
  ];

  const [actionType, setActionType] = useState("Site Visit");
  const [actionLocation, setActionLocation] = useState("");

  const searchPlaces = async (text) => {
    const options = {
      method: "GET",
      url: "https://address-completion.p.rapidapi.com/v1/geocode/autocomplete",
      params: {
        text: placeText,
        limit: "15",
        lang: "en",
        countrycodes: "in",
      },
      headers: {
        "X-RapidAPI-Key": keyConfig?.rapidApi,
        "X-RapidAPI-Host": "address-completion.p.rapidapi.com",
      },
    };

    let response = await axios.request(options);

    let places = response?.data?.features;
    let formatted_places = [];

    for (let i = 0; i < places?.length; i++) {
      let place = places[i];
      if (place?.properties?.name) {
        formatted_places.push(place?.properties);
      } else {
        formatted_places.push({
          ...place?.properties,
          name: place?.properties?.address_line1,
        });
      }
    }

    setPlaces(formatted_places);
  };

  useDebounce(
    () => {
      if (placeText && placeText.length > 2) {
        searchPlaces();
      } else {
        setPlaces([]);
      }
    },
    1200,
    [placeText]
  );

  const QUICK_SCHEDULES = [
    { label: 'Tomorrow', days: 1 },
    { label: '1 Week', days: 7 },
    { label: '2 Weeks', days: 14 },
    { label: '1 Month', days: 30 },
    { label: '3 Months', days: 90 },
    { label: '6 Months', days: 180 },
    { label: '1 Year', days: 365 },
  ];

  const [userSearch, setUserSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);

  useEffect(() => {
    const searchUsers = async () => {
      const response = await Api.post(`/users/get/pagination`, {
        name: userSearch,
      });
      if (response.data.length > 0) {
        setUserList(response.data);
      } else {
        setUserList([]);
      }
    };

    const userSearchTimeout = setTimeout(() => {
      if (userSearch) {
        searchUsers();
      }
    }, 800);

    return () => {
      clearTimeout(userSearchTimeout);
    };
  }, [userSearch]);

  const handleAddManager = (newManager) => {
    if (!selectedManagers.some(manager => manager._id === newManager._id)) {
      setSelectedManagers([...selectedManagers, newManager]);
    }
  };

  const handleRemoveManager = (managerId) => {
    setSelectedManagers(selectedManagers.filter(
      (manager) => manager._id !== managerId
    ));
  };

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });

    await updateDeleteFlagForSingleFiles({ fileId: selectedId })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActivityFilterChange = (event) => {
    setActivityFilter(event.target.value);
    setCurrentPage(1);
  };


  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={`${issue?.template?.title}`}
      appBarActions={
        <HorizBox spacing={2}>
          <Box sx={{ minWidth: 200 }}>
            <Select
              value={statusOption}
              onChange={(e) => setStatusOption(e.target.value)}
              size="small"
              fullWidth={true}
              sx={{
                '& .MuiSelect-select': {
                  padding: '8px 14px',
                  borderRadius: '8px',
                }
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem value={option?._id}>{option?.text}</MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 500
            }}
            onClick={() => updateIssueApi()}
          >
            Update
          </Button>
        </HorizBox>
      }
    >
      <Grid container spacing={3}>
        {/* Left Sidebar */}
        <Grid item xs={3}>
          <StandardAppContainerRounded sx={{ mb: 2 }}>
            {lead?._id ? (
              <Box>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                  pb: 2,
                  borderBottom: '1px solid #eee'
                }}>
                  <SingleUserWithCaptionTableCell
                    label={lead?.profile?.parent?.displayName}
                    caption={`${lead?.profile?.parent?.email?.trim() || "Email"} ${lead?.profile?.parent?.phone?.trim() || "Phone"}`}
                    url={lead?.profile?.parent?.displayPicture?.url}
                    labelStyleProps={{
                      fontSize: '17px',
                      fontWeight: 600,
                      color: 'black',
                    }}
                    captionStyleProps={{
                      fontSize: '0.75rem',
                      color: '#666',
                    }}
                    avatarSx={{
                      width: '40px',
                      height: '40px',
                    }}
                  />
                  <IconButton
                    onClick={() => connectLeadWithIssue({})}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#ffebee'
                      }
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </Box>

                {/* Info Sections */}
                <Box sx={{ '& > div': { mb: 2.5 } }}>
                  {/* Deal Value Section */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: '15px',
                        mb: 1,
                        color: '#2C3E50'
                      }}
                    >
                      Deal Value (₹)
                    </Typography>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator={true}
                      prefix="₹"
                      value={issue?.dealValue || ''}
                      onValueChange={(values) => {
                        const { value } = values;
                        setIssue({
                          ...issue,
                          dealValue: value
                        });
                      }}
                      fullWidth
                      size="small"
                      sx={{
                        backgroundColor: '#F8F9FA',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '6px',
                          height: '36px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 12px',
                          fontSize: '14px',
                        }
                      }}
                    />
                  </Box>

                  {/* Source Section */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: '15px',
                        mb: 1,
                        color: '#2C3E50'
                      }}
                    >
                      Source
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#34495E',
                        backgroundColor: '#F8F9FA',
                        padding: '8px 12px',
                        borderRadius: '6px',
                        fontSize: '14px'
                      }}
                    >
                      {issue?.source || "No source specified"}
                    </Typography>
                  </Box>

                  {/* Source Notes Section */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: '15px',
                        mb: 1,
                        color: '#2C3E50'
                      }}
                    >
                      Source Notes
                    </Typography>
                    <Box sx={{
                      backgroundColor: '#F8F9FA',
                      padding: '12px',
                      borderRadius: '6px',
                      maxHeight: '150px',
                      overflowY: 'auto'
                    }}>
                      {issue?.sourceNote ? (
                        issue.sourceNote.split('\n').map((note, index) => (
                          <Typography
                            key={index}
                            variant="body2"
                            sx={{
                              color: '#34495E',
                              fontSize: '14px',
                              mb: note.length > 0 ? 1 : 0,
                              lineHeight: 1.5
                            }}
                          >
                            {note}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: '#95A5A6',
                            fontSize: '14px',
                            fontStyle: 'italic'
                          }}
                        >
                          No source notes available
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* Listing Section */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: '15px',
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      Listing
                      <IconButton
                        color="primary"
                        onClick={() => setShowUpdatePalDrawer(true)}
                        sx={{ padding: '4px' }}
                      >
                        <EditOutlined fontSize="small" />
                      </IconButton>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {lead?.listing?.title || "Untitled Listing"}
                    </Typography>
                  </Box>

                  {/* Similar styling for other sections... */}
                </Box>
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  This ticket is not linked to any lead
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowLeadsDrawer(true)}
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none'
                  }}
                >
                  Link a Lead
                </Button>
              </Box>
            )}
          </StandardAppContainerRounded>

          {/* Project & Organization Pickers */}
          <StandardAppContainerRounded sx={{ mb: 2 }}>
            <FormBox label="Project">
              <ProjectPicker
                selectedProject={chosenProject}
                setSelectedProject={setChosenProject}
                fullWidth={true}
              />
            </FormBox>

            {chosenProject && (
              <FormBox label="Block & Unit" sx={{ mt: 2 }}>
                <ProjectBlockAndUnitPickerDropdown
                  projectId={chosenProject?._id}
                  selectedProjectBlock={selectedProjectBlock}
                  setSelectedProjectBlock={setSelectedProjectBlock}
                  selectedProjectUnit={selectedProjectUnit}
                  setSelectedProjectUnit={setSelectedProjectUnit}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    }
                  }}
                />
              </FormBox>
            )}

            {/* <FormBox label="Organization" sx={{ mt: 2 }}>
              <OrgPicker
                selectedOrg={chosenOrganization}
                setSelectedOrg={setChosenOrganization}
                fullWidth={true}
              />
            </FormBox> */}
          </StandardAppContainerRounded>

          {/* Assignment Section */}
          {lead?._id && (
            <StandardAppContainerRounded>
              <FormBox label="Assign">
                {AssignButton}
                {AssigneDrawer}
                {AssignView}
              </FormBox>
            </StandardAppContainerRounded>
          )}
        </Grid>

        {/* Main Content Area */}
        <Grid item xs={9}>
          {/* Action Tabs */}
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={1}>
              {actionTabOptions.map((tab) => {
                const Icon = tab.icon;
                return (
                  <Grid item key={tab.title}>
                    <Button
                      variant={actionTab === tab.title ? "contained" : "outlined"}
                      onClick={() => setActionTab(tab.title)}
                      startIcon={<Icon />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: actionTab === tab.title ? undefined : '#fff',
                      }}
                    >
                      {tab.title}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          {/* New Activity Creation Section */}
          <Box sx={{ mb: 3 }}>
            {actionTab === "Notes" && (
              <Box sx={{
                backgroundColor: '#fff',
                borderRadius: '12px',
                border: '1px solid #E0E0E0',
                p: 2
              }}>
                <InputBase
                  placeholder="Add a note..."
                  fullWidth
                  multiline
                  rows={3}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  sx={{
                    backgroundColor: '#F8F9FA',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                      lineHeight: '1.5',
                    }
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    variant="contained"
                    disabled={!note.trim()}
                    onClick={saveAction}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px'
                    }}
                  >
                    Add Note
                  </Button>
                </Box>
              </Box>
            )}

            {actionTab === "Actions" && (
              <Box sx={{
                backgroundColor: '#fff',
                borderRadius: '12px',
                border: '1px solid #E0E0E0',
                p: 2
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormBox label="Action Type">
                      <Select
                        value={actionType}
                        onChange={(e) => setActionType(e.target.value)}
                        fullWidth
                        size="small"
                        sx={{
                          '& .MuiSelect-select': {
                            padding: '8px 14px',
                          }
                        }}
                      >
                        {ACTION_TYPES.map((type) => (
                          <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                      </Select>
                    </FormBox>
                  </Grid>

                  <Grid item xs={12}>
                    <FormBox label="Quick Schedule">
                      <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        '& .MuiChip-root': {
                          borderRadius: '16px',
                        }
                      }}>
                        {QUICK_SCHEDULES.map((schedule) => (
                          <Chip
                            key={schedule.label}
                            label={schedule.label}
                            onClick={() => {
                              setActionDate(dayjs().add(schedule.days, 'day').hour(9).minute(0));
                            }}
                            sx={{
                              backgroundColor: dayjs().add(schedule.days, 'day').hour(9).minute(0).isSame(actionDate, 'minute')
                                ? '#1976D2'
                                : '#F5F5F5',
                              color: dayjs().add(schedule.days, 'day').hour(9).minute(0).isSame(actionDate, 'minute')
                                ? '#fff'
                                : '#666',
                              '&:hover': {
                                backgroundColor: dayjs().add(schedule.days, 'day').hour(9).minute(0).isSame(actionDate, 'minute')
                                  ? '#1565C0'
                                  : '#E0E0E0',
                              }
                            }}
                          />
                        ))}
                      </Box>
                    </FormBox>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormBox label="Date">
                      <DatePicker
                        value={actionDate}
                        onChange={(val) => setActionDate(val)}
                        fullWidth
                        size="small"
                      />
                    </FormBox>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormBox label="Time">
                      <TimePicker
                        value={actionDate}
                        onChange={(val) => setActionDate(val)}
                        fullWidth
                        size="small"
                      />
                    </FormBox>
                  </Grid>

                  {(actionType === "Meeting" || actionType === "Site Visit") && (
                    <>
                      <Grid item xs={12}>
                        <FormBox label="Account Managers">
                          <Autocomplete
                            disablePortal
                            options={userList}
                            disableClearable={true}
                            inputValue={userSearch}
                            onInputChange={(event, value) => {
                              setUserSearch(value);
                            }}
                            getOptionLabel={(option) => option?.displayName ?? ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Account Managers"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <People />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              if (selectedManagers.some(
                                (manager) => manager._id === option._id
                              )) {
                                return null;
                              }
                              return (
                                <MenuItem
                                  {...props}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  onClick={() => handleAddManager(option)}
                                >
                                  <Avatar
                                    sx={{ width: "45px", height: "45px" }}
                                    src={option?.displayPicture?.url}
                                    alt={option?.displayName}
                                  />
                                  <Typography variant="body1">
                                    {option?.displayName}
                                  </Typography>
                                </MenuItem>
                              );
                            }}
                          />
                        </FormBox>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {selectedManagers.map((manager, index) => (
                            <Chip
                              key={index}
                              avatar={
                                <Avatar
                                  src={manager?.displayPicture?.url}
                                  alt={manager?.displayName}
                                />
                              }
                              label={manager?.displayName}
                              onDelete={() => handleRemoveManager(manager._id)}
                              sx={{ m: 0.5 }}
                            />
                          ))}
                        </Box>
                      </Grid>
                    </>
                  )}

                  {actionType === "Meeting" && (
                    <Grid item xs={12}>
                      <FormBox label="Duration">
                        <Box sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 1,
                          '& .MuiChip-root': {
                            borderRadius: '16px',
                          }
                        }}>
                          {DURATION_OPTIONS.map((duration) => (
                            <Chip
                              key={duration.label}
                              label={duration.label}
                              onClick={() => setSelectedDuration(duration.minutes)}
                              sx={{
                                backgroundColor: selectedDuration === duration.minutes
                                  ? '#1976D2'
                                  : '#F5F5F5',
                                color: selectedDuration === duration.minutes
                                  ? '#fff'
                                  : '#666',
                                '&:hover': {
                                  backgroundColor: selectedDuration === duration.minutes
                                    ? '#1565C0'
                                    : '#E0E0E0',
                                }
                              }}
                            />
                          ))}
                        </Box>
                      </FormBox>
                    </Grid>
                  )}

                  {chosenProject ? (
                    <Grid item xs={12}>
                      <FormBox label="Location">
                        <Typography variant="body2" sx={{ color: '#666' }}>
                          {chosenProject.name}
                        </Typography>
                      </FormBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <FormBox label="Location">
                        <Autocomplete
                          options={places}
                          value={selectedPlace}
                          onChange={(evt, newPlace) => setSelectedPlace(newPlace)}
                          fullWidth
                          getOptionLabel={(option) => option?.name || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search location..."
                              value={placeText}
                              onChange={(e) => setPlaceText(e.target.value)}
                              size="small"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: '#F8F9FA',
                                }
                              }}
                            />
                          )}
                        />
                      </FormBox>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                      <Button
                        variant="contained"
                        onClick={saveAction}
                        sx={{
                          textTransform: 'none',
                          borderRadius: '8px'
                        }}
                      >
                        Add Action
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

            {actionTab === "Documents" && (
              <Box sx={{
                backgroundColor: '#fff',
                borderRadius: '12px',
                border: '1px solid #E0E0E0',
                p: 2
              }}>
                <Box sx={{ mb: 2 }}>
                  <CustomFileUploadButton
                    showComponent={
                      <Button
                        variant="outlined"
                        startIcon={<Add />}
                        sx={{
                          textTransform: 'none',
                          borderRadius: '8px'
                        }}
                      >
                        Upload Files
                      </Button>
                    }
                    parentType={"Issue"}
                    parentId={issue?._id}
                    fileNum={25}
                    givenMaxSize={26214400}
                    closeFunCall={() => console.log("close dialog")}
                  />
                </Box>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                    <Button
                      variant="contained"
                      onClick={saveAction}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px'
                      }}
                    >
                      Add Action
                    </Button>
                  </Box>
                </Grid>

                {createdFileIds && createdFileIds.length > 0 && (
                  <FilesViewer
                    fileIds={createdFileIds}
                    deletable={true}
                    styleBody={{
                      width: "100%",
                      height: "auto",
                    }}
                    handler={removeSingleImgFromReducerAndDelete}
                  />
                )}
              </Box>
            )}

          </Box>

          {/* Activity Board */}
          <StandardAppContainerRounded>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                Activity Timeline
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                <Select
                  value={activityFilter}
                  onChange={handleActivityFilterChange}
                  size="small"
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem key="All" value="All">All </MenuItem>
                  <MenuItem key="Note" value="Note">Notes</MenuItem>
                  <MenuItem key="Action" value="Action">Actions</MenuItem>
                  <MenuItem key="Doc" value="Doc">Documents</MenuItem>
                  <MenuItem key="SMS" value="SMS">SMS</MenuItem>
                  <MenuItem key="Call" value="Call">Calls</MenuItem>
                </Select>
              </Box>

              {loadingActivities ? (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {activities
                    .map((item) => (
                      <Box
                        key={item?._id}
                        onClick={() => handlePreview(item)}
                        sx={{
                          backgroundColor: '#fff',
                          borderRadius: '12px',
                          border: '1px solid #E0E0E0',
                          p: 2,
                          cursor: 'pointer',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                            borderColor: '#BDBDBD'
                          }
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Avatar
                              src={item?.userProfile?.parent?.displayPicture?.url}
                              sx={{ width: 32, height: 32 }}
                            />
                            <Box>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                {item?.userProfile?.parent?.displayName || "Untitled"}
                              </Typography>
                              <Typography variant="caption" color="text.secondary">
                                {dayjs(item?.createdAt).format("MMM D, YYYY • h:mm A")}
                              </Typography>
                            </Box>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Chip
                              icon={
                                item?.type === "Doc" ? <FilePresentOutlined /> :
                                  item?.type === "Note" ? <NoteOutlined /> :
                                    item?.type === "Action" ? <AlarmOnOutlined /> :
                                      item?.type === "SMS" ? <MessageOutlined /> :
                                        <PhoneOutlined />
                              }
                              label={item?.type}
                              size="small"
                              sx={{
                                backgroundColor: '#E3F2FD',
                                color: '#1976D2',
                                '& .MuiChip-icon': { color: '#1976D2' }
                              }}
                            />
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                // Add your menu handling here
                              }}
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                        {/* Conditional rendering based on item.type */}
                        <Box>
                          {item?.type === 'Note' && (
                            <Typography variant="body2" sx={{ color: '#424242' }}>
                              {item?.note || 'No content available'}
                            </Typography>
                          )}

                          {item?.type === 'Doc' && (
                            <Box>
                              {item.docs.map((doc) =>
                                doc.files.map((file) => (
                                  file?._id ? (
                                    <Box key={file._id} sx={{ mb: 1 }}>
                                      {file?.type?.startsWith('image/') ? (
                                        <img
                                          src={file.url}
                                          alt={file.name}
                                          style={{ maxWidth: '100%', borderRadius: '8px' }}
                                        />
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          startIcon={<FilePresentOutlined />}
                                          href={file.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {file.name}
                                        </Button>
                                      )}
                                    </Box>
                                  ) : null
                                ))
                              )}
                            </Box>
                          )}

                          {item?.type === 'Action' && (
                            <Box>
                              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                                {item.actionType}
                              </Typography>
                              <Typography variant="body2" sx={{ color: '#424242' }}>
                                {item.calendarEvent?.description || 'No description available'}
                              </Typography>
                            </Box>
                          )}

                          {item?.type === 'SMS' && (
                            <Typography variant="body2" sx={{ color: '#424242' }}>
                              {/* Display SMS content */}
                              {item?.message || 'No SMS content available'}
                            </Typography>
                          )}

                          {item?.type === 'Call' && (
                            <Typography variant="body2" sx={{ color: '#424242' }}>
                              {/* Display Call details */}
                              {item?.callSummary || 'No call summary available'}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))}
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </StandardAppContainerRounded>
        </Grid>
      </Grid>

      <DrawerContainer
        open={showPreviewDrawer}
        setOpen={setShowPreviewDrawer}
        title={itemToPreview?.type}
        onClose={() => {
          setItemToPreview(null);
          setNote("");
          setActionTitle("");
          setActionDescription("");
          setActionDate(dayjs());
        }}
      >
        <Box>
          {itemToPreview?.type === "Note" && (
            <Box>
              <TextField
                placeholder="Enter"
                fullWidth={true}
                multiline={true}
                rows={4}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <DuoButtonGroup
                primaryButtonText="Update"
                primaryButtonListener={() => {
                  //  updateNote()
                }}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => {
                  setShowPreviewDrawer(false);
                }}
              />
            </Box>
          )}
          {itemToPreview?.type === "Action" && (
            <Box>
              <FormBox label="Action Name">
                <TextField
                  placeholder="Enter"
                  fullWidth={true}
                  value={actionTitle}
                  onChange={(e) => setActionTitle(e.target.value)}
                />
              </FormBox>
              <FormBox label="Action Description">
                <TextField
                  placeholder="Enter"
                  fullWidth={true}
                  multiline={true}
                  rows={4}
                  value={actionDescription}
                  onChange={(e) => setActionDescription(e.target.value)}
                />
              </FormBox>
              <FormBox label="Action Date">
                <Select
                  value={actionDateMode}
                  onChange={(e) => setActionDateMode(e.target.value)}
                  fullWidth={true}
                >
                  {TIME_MODES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
              {actionDateMode === "Custom" && (
                <Box>
                  <FormBox label="Action Date">
                    <DatePicker
                      value={actionDate}
                      onChange={(val) => setActionDate(val)}
                    />
                  </FormBox>
                  <FormBox label="Action Time">
                    <TimePicker
                      value={actionDate}
                      onChange={(val) => {
                        setActionDate(val);
                      }}
                      fullWidth={true}
                    />
                  </FormBox>
                </Box>
              )}

              <DuoButtonGroup
                primaryButtonText="Update"
                primaryButtonListener={() => {
                  //  updateAction()
                }}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => {
                  setShowPreviewDrawer(false);
                }}
              />
            </Box>
          )}
          {itemToPreview?.type === "Doc" && (
            <Box>
              <FilesViewer
                files={files}
                isDeletable={true}
                deleteFunction={async (fileId) => {
                  deleteDocByFile(fileId);
                }}
                disablePreview={false}
                disableShowMetaData={true}
                disableEditMetaData={true}
              />
            </Box>
          )}
        </Box>
      </DrawerContainer>

      <SearchEntityDialog
        entity={issue?.profile?.parentModelName}
        multiple={false}
        orgtype={"User"}
        curEntityId={issue?.profile?.parent?._id}
        givenFilterOption={[
          {
            option: "Contact",
            types: ["Prospect", "Other"],
          },
        ]}
        onSave={(entity) => {
          connectLeadWithIssue(entity);
          setShowLeadsDrawer(false);
        }}
        givenProfileIdArr={[issue.profile._id, user.profile]}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Lead"
        financialRelationType="Prospect"
      />

      <CreatePalDrawer
        text=""
        title={'Update Lead'}
        openPal={showUpdatePalDrawer}
        setOpenPal={setShowUpdatePalDrawer}
        financialRelationType={"Prospect"}
        contactType={"Prospect"}
        pal={lead}
        actionType={"Update"}
        mainProfileWitParent={mainProfileWithParent}
        profileIdArr={profileIdArr} // TODO: Correct this
        selectEntityFunction={(newLead) => {
          getIssueCall();
        }}
      />
      <Choose
        open={openChooseProject}
        setOpen={setOpenChooseProject}
        parentModelName="Project"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Find Projects`}
        onSelected={(arr) => {
          if (arr && arr.length > 0) {
            setChosenProject(arr[0]);
            let updatedIssue = issue;
            updatedIssue.project = arr[0];
            setIssue(updatedIssue);
          }
        }}
      />

      <Choose
        open={openChooseOrganization}
        setOpen={setOpenChooseOrganization}
        parentModelName="Organization"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Find Organization`}
        onSelected={(arr) => {
          if (arr && arr.length > 0) {
            setChosenOrganization(arr[0]);
            let updatedIssue = issue;
            updatedIssue.organization = arr[0];
            setIssue(updatedIssue);
          }
        }}
      />
    </StandardContainer>
  );
};

export default ProfileIssueCRM;
