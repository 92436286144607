import Api from "../../../helpers/Api";

export const getBookingById = async (bookingId) => {
  const { data } = await Api.post("/listing/booking/get-by-id", { bookingId });
  return data;
};

export const updateBooking = async (bookingId, payload, brokerData) => {
  return await Api.post("/listing/booking/update", {
    bookingId,
    bookingData: payload,
    brokerData: brokerData,
  });
};

export const getPaymentTables = async (libraryId) => {
  const { data } = await Api.post("/listing/booking/payment/table/get", {
    libraryId,
  });
  return data;
};

export const getPaymentSchedules = async (libraryId, tableId) => {
  const { data } = await Api.post("/listing/booking/schedule/get", {
    libraryId,
    tableId,
  });
  return data;
};

export const getPaymentTableComponents = async (tableId) => {
  const { data } = await Api.post("listing/booking/payment/component/get", {
    tableId,
  });
  return data.data;
};

export const getPaymentScheduleComponents = async (scheduleId) => {
  const { data } = await Api.post("listing/booking/schedule/get-by-id", {
    scheduleId,
  });
  return data.data.slabs;
};

export const getTeams = async (profileId) => {
  const { data } = await Api.post(`/issue/template/getAll`, { profileId });
  return data.filter((item) => item?.isCRM === false);
};

export const getBillingAccounts = async (sharedIds) => {
  try {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      { sharedIds }
    );
    return { billingAccounts: response.billingAccounts || [] };
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};
