import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Checkbox,
  Divider,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Typography,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  RadioGroup,
  FormLabel,
  Radio,
  Autocomplete,
  Tooltip,
  InputAdornment,
  Grid,
  styled,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ProjectPicker from "../../../styled/generic/ProjectPicker";
import ProjectBlockAndUnitPickerDropdown from "../../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import UserSearcher from "../../../styled/generic/UserSearcher";
import BankAccountPicker from "../../../styled/generic/BankAccountPickerDropdown";
import PaymentSection from "../PaymentSection";
import {
  Edit,
  Delete,
  Add,
  Percent,
  CurrencyRupee,
  People,
  Assignment,
  LocationOn,
  Home,
  Person,
  Description,
  Phone,
  Apartment,
  Business,
  House,
} from "@mui/icons-material";
import CreatePalDrawer from "../../../AddMember/CreatePalDrawer";
import SearchEntityDialog from "../../../styled/CommonComponents/SearchEntityDrawer";
import { useSelector } from "react-redux";
import FormBox from "../../../styled/generic/FormBox";
import { BiTrash } from "react-icons/bi";
import Api from "../../../../helpers/Api";
import { EditOutlined } from "@material-ui/icons";

export const AccountManagerProfile = ({
  formData,
  handleChange,
  givenProfileIdArr,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [userSearch, setUserSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const searchUsers = async () => {
      const response = await Api.post(`/users/get/pagination`, {
        name: userSearch,
      });
      if (response.data.length > 0) {
        setUserList(response.data);
      } else {
        setUserList([]);
      }
    };

    const userSearchTimeout = setTimeout(() => {
      searchUsers();
    }, 800);

    return () => {
      clearTimeout(userSearchTimeout);
    };
  }, [userSearch]);

  const handleAddAccountManager = (newManager) => {
    handleChange("accountManagers", [...formData.accountManagers, newManager]);
    setSelectedUser(null);
  };

  const handleRemoveAccountManager = (managerId) => {
    handleChange(
      "accountManagers",
      formData.accountManagers.filter((manager) => manager._id !== managerId)
    );
  };

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={userList}
        disableClearable={true}
        inputValue={userSearch}
        onInputChange={(event, value) => {
          setUserSearch(value);
        }}
        value={selectedUser}
        onChange={(event, newValue) => {
          setSelectedUser(newValue);
        }}
        getOptionLabel={(option) => option?.displayName ?? ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Account Managers"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <People />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          if (
            formData.accountManagers.some(
              (manager) => manager._id === option._id
            )
          ) {
            return null;
          }
          return (
            <MenuItem
              {...props}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => handleAddAccountManager(option)}
            >
              <Avatar
                sx={{ width: "45px", height: "45px" }}
                src={option?.displayPicture?.url}
                alt={option?.displayName}
              />
              <Typography variant="body1">{option?.displayName}</Typography>
            </MenuItem>
          );
        }}
      />
      <Box sx={{ mt: 2 }}>
        {formData.accountManagers.map((manager, index) => (
          <Chip
            key={index}
            avatar={
              <Avatar
                src={manager?.displayPicture?.url}
                alt={manager?.displayName}
              />
            }
            label={manager?.displayName}
            onDelete={() => handleRemoveAccountManager(manager._id)}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
    </Box>
  );
};
