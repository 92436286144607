import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Chip,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Add as AddIcon, Edit, Percent } from "@mui/icons-material";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import { useDispatch } from "react-redux";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import TextArea from "../../styled/generic/TextArea";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getProfileData } from "../../team/procurements/api.call";

const fetchBookings = async ({ libraryId }) => {
  const data = await Api.post("/listing/booking/get", { libraryId });
  if (data) {
    return data?.bookings;
  } else {
    console.error("Error fetching bookings:");
    return null;
  }
};

const CreateOfferModel = ({ open, setOpen, libraryId }) => {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [formData, setFormData] = useState({
    project: profileId,
    offerType: "",
    description: "",
    value: "",
    expiryDate: "",
    isActive: true,
    terms: "",
  });
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    if (profileId) {
      getProfileData({ profileId })
        .then((data) => {
          const walletData = data?.parent?.wallet || null;
          setWalletData(walletData);
          setFormData((prev) => ({
            ...prev,
            library: walletData.defaultLibrary,
          }));
          setProfileData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  // useEffect(() => {
  //   const getBookings = async () => {
  //     const response = await fetchBookings({
  //       libraryId,
  //     });
  //     if (response) {
  //       const filteredBookings = response.filter(
  //         (item) => item?.customerProfile?.parent?.displayName
  //       );
  //       setBookings(filteredBookings);
  //     } else {
  //       dispatch({
  //         type: "AddApiAlert",
  //         payload: {
  //           success: false,
  //           message: "Failed to fetch bookings",
  //         },
  //       });
  //     }
  //   };
  //   if (open && libraryId) {
  //     getBookings();
  //   }
  // }, [open, libraryId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = await Api.post("/booking/offer-create", formData);
    if (data) {
      setOpen(false);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Offer created successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to create offer",
        },
      });
    }
    setLoading(false);
  };

  return (
    <DrawerContainer title={"Create Offer"} open={open} setOpen={setOpen}>
      <Box
        sx={{
          minWidth: "60vw",
        }}
      >
        {/* <FormBox label={"Booking"}>
          <Autocomplete
            componentName="booking"
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) =>
              option?.customerProfile?.parent?.displayName
            }
            options={bookings}
            loading={false}
            onChange={(event, newValue) => {
              if (newValue) {
                setFormData((prev) => ({ ...prev, booking: newValue._id }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search bookings"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {false ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormBox> */}
        <FormBox label="Offer Type">
          <Select
            name="offerType"
            value={formData.offerType}
            onChange={handleChange}
            placeholder="Offer Type"
            fullWidth
          >
            {[
              "Discount",
              "Freebie",
              "Upgrade",
              "Referral Discounts",
              "Money-Back Guarantee",
              "Other",
            ].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormBox>
        <FormBox label="Description">
          <TextArea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            fullWidth
            minRows={5}
            maxRows={8}
            sx={{
              width: "100%",
              resize: "none",
            }}
          />
        </FormBox>
        <FormBox label="Terms">
          <TextArea
            name="terms"
            value={formData.terms}
            onChange={handleChange}
            placeholder="Terms"
            fullWidth
            minRows={5}
            maxRows={8}
            sx={{
              width: "100%",
              resize: "none",
            }}
          />
        </FormBox>
        <SpaceBetween
          right={
            <FormBox label="Expiry Date">
              <TextField
                name="expiryDate"
                type="date"
                value={formData.expiryDate}
                onChange={handleChange}
                placeholder="Expiry Date"
                fullWidth
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
              />
            </FormBox>
          }
          left={
            <FormBox label="Value">
              <TextField
                name="value"
                type="number"
                value={formData.value}
                onChange={handleChange}
                placeholder="Value"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent />
                    </InputAdornment>
                  ),
                }}
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label={"Status"}>
              <Select
                fullWidth
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    status: e.target.value,
                  }))
                }
                defaultValue={"Draft"}
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Archived">Archived</MenuItem>
              </Select>
            </FormBox>
          }
        />
        <FormBox label={"Actions"}>
          <ActionInput
            onChange={(e) =>
              setFormData((prevData) => ({ ...prevData, actions: e }))
            }
            initialActions={["Action 1", "Action 2", "Action 3"]}
          />
        </FormBox>
        <FormBox>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                name="isActive"
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    isActive: e.target.checked,
                  }));
                }}
              />
            }
            label="Set as Active"
          />
        </FormBox>{" "}
        <DuoButtonGroup
          primaryButtonListener={handleSubmit}
          loadingPrimary={loading}
          disableSecondaryButton={loading}
          primaryButtonText={"Create Offer"}
          secondaryButtonText={"Cancel"}
          secondaryButtonListener={() => setOpen(false)}
        />
      </Box>
    </DrawerContainer>
  );
};

export default CreateOfferModel;

const ActionInput = ({ initialActions = [], onChange }) => {
  const [actions, setActions] = useState(initialActions);
  const [newAction, setNewAction] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);

  const handleAddAction = () => {
    if (newAction.trim()) {
      const updatedActions = [...actions, newAction.trim()];
      setActions(updatedActions);
      setNewAction("");
      onChange(updatedActions);
    }
  };

  const handleDeleteAction = (indexToDelete) => {
    const updatedActions = actions.filter(
      (_, index) => index !== indexToDelete
    );
    setActions(updatedActions);
    onChange(updatedActions);
  };

  const handleEditAction = (index) => {
    setEditIndex(index);
    setNewAction(actions[index]);
    setOpenDialog(true);
  };

  const handleUpdateAction = () => {
    if (newAction.trim()) {
      const updatedActions = [...actions];
      updatedActions[editIndex] = newAction.trim();
      setActions(updatedActions);
      setNewAction("");
      setEditIndex(-1);
      setOpenDialog(false);
      onChange(updatedActions);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <Box display="flex" alignItems="" mb={2}>
        <TextField
          placeholder="Add an action"
          variant="outlined"
          value={newAction}
          onChange={(e) => setNewAction(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleAddAction()}
          fullWidth
        />
        <IconButton color="primary" onClick={handleAddAction} sx={{ ml: 1 }}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1} maxWidth={"100%"}>
        {actions.map((action, index) => (
          <Chip
            key={index}
            label={action}
            size="medium"
            onDelete={() => handleDeleteAction(index)}
            onClick={() => handleEditAction(index)}
            color="info"
            variant="outlined"
          />
        ))}
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Action</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Action"
            fullWidth
            variant="outlined"
            value={newAction}
            onChange={(e) => setNewAction(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleUpdateAction} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
