import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";

const ScheduleTable = ({
  paymentSchedule,
  onUpdatePaymentSchedule,
  components,
}) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [editedRow, setEditedRow] = useState({});

  const handleEdit = (index, row) => {
    setEditIndex(index);
    setEditedRow(row);
  };

  const handleSave = (index) => {
    const updatedSchedule = [...paymentSchedule];
    updatedSchedule[index] = editedRow;
    onUpdatePaymentSchedule(updatedSchedule);
    setEditIndex(-1);
  };

  const handleCancel = () => {
    setEditIndex(-1);
    setEditedRow({});
  };

  const handleChange = (e, field) => {
    setEditedRow({ ...editedRow, [field]: e.target.value });
  };

  useEffect(() => {
    if (components?.length > 0 && paymentSchedule?.length === 0) {
      const initialSchedule = components?.map((component) => ({
        title: component?.title,
        percentage: 0,
        dueDate: null,
        componentRef: component?._id,
      }));
      onUpdatePaymentSchedule(initialSchedule);
    }
  }, [components, paymentSchedule, onUpdatePaymentSchedule]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Percentage</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentSchedule?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row?.title}</TableCell>
              <TableCell>
                {editIndex === index ? (
                  <TextField
                    value={editedRow?.percentage}
                    onChange={(e) => handleChange(e, "percentage")}
                    type="number"
                  />
                ) : (
                  row.percentage
                )}
              </TableCell>
              <TableCell>
                {editIndex === index ? (
                  <TextField
                    value={editedRow?.dueDate}
                    onChange={(e) => handleChange(e, "dueDate")}
                    type="date"
                  />
                ) : (
                  row.dueDate
                )}
              </TableCell>
              <TableCell>
                {editIndex === index ? (
                  <>
                    <IconButton onClick={() => handleSave(index)}>
                      <Save />
                    </IconButton>
                    <IconButton onClick={handleCancel}>
                      <Cancel />
                    </IconButton>
                  </>
                ) : (
                  <IconButton onClick={() => handleEdit(index, row)}>
                    <Edit />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleTable;
