import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Chip,
  Card,
  Select,
  Divider,
  MenuItem,
  Grid,
  Avatar,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Edit, ArrowForward } from "@mui/icons-material";
import { Pagination } from "@mui/material";
import formatToRupees from "../../../helpers/formatToRupees";
import { getBookingPaginatedTransactions } from "../api.call";
import { useDebounce } from "react-use";

const Transaction = ({ libraryId, setOldTransaction, reload }) => {

  const [changeState, setChangeState] = useState(false)
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [status, setStatus] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const getTransactions = async () => {
    setLoading(true);
    await getBookingPaginatedTransactions({
      libraryId,
      page,
      limit: 10,
      searchTerm,
      sortBy,
      sortOrder,
      status,
    })
      .then((data) => {
        setTransactions(data.data);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error while fetching transactions",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      })
  };

  useDebounce(() => {
    if (libraryId) {
      getTransactions()
    }
  }, 1000, [page, sortBy, sortOrder, libraryId, changeState])

  useEffect(() => {
    setPage(1)
    setChangeState(!changeState)
  }, [status, reload, searchTerm])

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleSortChange = (event) => {
    const [newSortBy, newSortOrder] = event.target.value.split(":");
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setPage(1);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Grid
        container
        sx={{
          alignItems: "center",
          gap: "10px",
        }}
        gridTemplateColumns={4}
      >
        <Grid item gridColumn={3}>
          <TextField
            placeholder="Search"
            variant="outlined"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item gridColumn={1}>
          <Select
            value={`${sortBy}:${sortOrder}`}
            onChange={handleSortChange}
            fullWidth
            size="small"
          >
            <MenuItem value="createdAt:desc">Newest First</MenuItem>
            <MenuItem value="createdAt:asc">Oldest First</MenuItem>
            <MenuItem value="amountPayable:desc">Amount (High to Low)</MenuItem>
            <MenuItem value="amountPayable:asc">Amount (Low to High)</MenuItem>
          </Select>
        </Grid>
        <Grid item gridColumn={1}>
          <Select
            value={status}
            onChange={handleStatusChange}
            fullWidth
            size="small"
            displayEmpty
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="checking">Checking</MenuItem>
            <MenuItem value="sent">Sent</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {transactions?.length > 0 ? (
        transactions?.map((transactionData, index) => (
          <TransactionRow
            key={transactionData._id || index}
            transaction={transactionData}
            history={history}
            setOldTransaction={setOldTransaction}
          />
        ))
      ) : (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ textAlign: "center", py: 4 }}
        >
          No transactions found.
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Transaction;

const TransactionRow = ({ transaction, history, setOldTransaction }) => {
  const theme = useTheme();
  const receivedAmount = transaction?.billItems?.reduce(
    (accumulator, transaction) =>
      accumulator + parseInt(transaction?.amountReceived),
    0
  );
  const currentDue = transaction?.billItems.reduce(
    (accumulator, transaction) =>
      accumulator + parseInt(transaction?.currentDue),
    0
  );

  return (
    <Card
      variant="outlined"
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", md: "2fr 3fr" },
        gap: 2,
        p: 2,
        mb: 2,
        borderRadius: 2,
      }}
    >
      <Box
        onClick={() =>
          history.push(`/booking/transaction/view/${transaction._id}`)
        }
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: 200,
            height: 40,
            mr: 2,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" fontWeight={500} noWrap>
            <span
              dangerouslySetInnerHTML={{
                __html: transaction.subject,
              }}
            />
          </Typography>
        </Box>
        <Avatar
          src={
            transaction?.booking?.customerProfile?.parent?.displayPicture?.url
          }
          sx={{ width: 40, height: 40, mr: 2 }}
        />
        <Box>
          <Typography variant="subtitle1" fontWeight="medium">
            {transaction?.booking?.customerProfile?.parent.displayName ||
              "Unknown"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {transaction?.booking?.customerProfile?.parent?.username}{" "}
            <ArrowForward sx={{ fontSize: 12, mx: 0.5 }} />
            {transaction?.booking?.projectBlock?.name}{" "}
            <ArrowForward sx={{ fontSize: 12, mx: 0.5 }} />
            {transaction?.booking?.unit?.name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
          gap: 1,
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <AmountChip label="Payable" amount={transaction?.amountPayable} />
        <AmountChip label="Received" amount={receivedAmount} />
        <AmountChip label="Due" amount={currentDue} />
        <Chip
          sx={{
            width: "fit-content",
          }}
          label={new Date(transaction?.createdAt).toLocaleDateString()}
          variant="outlined"
        />
        <StatusChip status={transaction?.status} />
        <Chip
          sx={{
            width: "fit-content",
          }}
          label={transaction?.type}
          variant="outlined"
        />
      </Box>
    </Card>
  );
};

const AmountChip = ({ label, amount }) => (
  <Chip
    label={`${label}: ₹${formatToRupees(amount)}`}
    variant="outlined"
    sx={{
      width: "fit-content",
      borderColor: "divider",
      bgcolor: "background.paper",
      "& .MuiChip-label": {
        fontWeight: "normal",
      },
    }}
  />
);

const StatusChip = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "draft":
        return "info";
      case "checking":
        return "warning";
      case "sent":
        return "error";
      case "paid":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Chip
        label={status}
        size="medium"
        color={getStatusColor(status)}
        sx={{
          width: "fit-content",
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        }}
      />
    </Box>
  );
};
