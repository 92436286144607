import React, { useEffect, useState, useCallback } from "react";
import {
  TextField,
  Select,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  InputAdornment,
  IconButton,
  Divider,
  Autocomplete,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Avatar,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import {
  Email as EmailIcon,
  Lock as LockIcon,
  Add as AddIcon,
  Mail as MailIcon,
  Person,
  Save,
  VisibilityOff,
  Visibility,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Api from "../../../helpers/Api";

const BookingBusinessEmail = ({ libraryId }) => {
  const dispatch = useDispatch();
  const [businessEmails, setBusinessEmails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const getBusinessEmails = useCallback(async () => {
    setLoading(true);
    const response = await Api.post(`/listing/booking/business-emails`, {
      libraryId,
    });
    if (response) {
      setBusinessEmails(response);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch business emails",
        },
      });
    }
    setLoading(false);
  }, [libraryId, dispatch]);

  useEffect(() => {
    if (libraryId) {
      getBusinessEmails();
    }
  }, [libraryId, getBusinessEmails]);

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5">Business Emails</Typography>
        <Button
          onClick={() => setIsOpen(true)}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
        >
          Add Email
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {businessEmails.map((item, index) => (
            <BusinessEmailItem
              item={item}
              getBusinessEmails={getBusinessEmails}
              libraryId={libraryId}
              dispatch={dispatch}
            />
          ))}
        </List>
      )}
      <AddBusinessEmail
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={getBusinessEmails}
        libraryId={libraryId}
        dispatch={dispatch}
      />
    </Box>
  );
};

export default BookingBusinessEmail;

const BusinessEmailItem = ({
  item,
  libraryId,
  dispatch,
  getBusinessEmails,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDefaultEmail = async (emailId) => {
    setLoading(true);
    const response = await Api.post(
      "listing/booking/business-email/make-default",
      {
        libraryId,
        businessEmailId: emailId,
      }
    );

    if (response) {
      getBusinessEmails();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Default email updated successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to update default email",
        },
      });
    }
    setLoading(false);
  };

  return (
    <ListItem
      key={item._id}
      sx={{
        bgcolor: "background.paper",
        borderRadius: 1,
        mb: 1,
        boxShadow: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar src={item?.profileId?.parent?.displayPicture?.url} />
            <Typography>{item?.profileId?.parent?.displayName}</Typography>
          </Box>

          {loading ? (
            <IconButton size="small">
              <CircularProgress size={20} />
            </IconButton>
          ) : (
            <Tooltip title="Make default ">
              <IconButton
                edge="end"
                aria-label="make default"
                onClick={() => handleDefaultEmail(item._id)}
                sx={{ mr: 1 }}
              >
                <MailIcon color={item.isDefault ? "primary" : "action"} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            marginTop: "10px",
          }}
        />
        <Typography variant="body1">
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Email
          </span>
          {item.email}
        </Typography>
        <Typography variant="body2">
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Provider:-{" "}
          </span>
          {item.provider}
        </Typography>
      </Box>
    </ListItem>
  );
};

const fetchUsers = async (query) => {
  const response = await Api.post("/search/users", {
    name: query,
  });
  if (response) {
    return response.data.map((item) => item.profile);
  } else {
    console.error("Error fetching users:");
  }
  return [];
};

const AddBusinessEmail = ({ isOpen, setIsOpen, onSubmit, libraryId }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [provider, setProvider] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [query, setQuery] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleUserChange = (event, newValue) => {
    setSelectedUser(newValue);
    if (newValue) {
      setEmail(newValue.email);
    } else {
      setEmail("");
    }
  };

  const handleUserInputChange = async (event, newInputValue) => {
    setQuery(newInputValue);
  };

  useEffect(() => {
    if (query) {
      const timeout = setTimeout(async () => {
        setLoading(true);
        const fetchedUsers = await fetchUsers(query);
        setUsers(fetchedUsers);
        setLoading(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [query]);

  useEffect(() => {
    if (email?.split("@")[1]?.split(".")[0]) {
      setProvider(email?.split("@")[1]?.split(".")[0]);
    }
  }, [email]);

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setProvider("");
    setSelectedUser(null);
    setQuery("");
    setShowPassword(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = await Api.post("listing/booking/email/create", {
      email,
      password,
      provider,
      profileId: selectedUser?._id,
      libraryId,
    });

    if (data) {
      onSubmit();
      handleClose();
      resetForm();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Business email added successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to add business email",
        },
      });
    }
    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Business Email</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "8px",
          }}
        >
          <Autocomplete
            options={users}
            getOptionLabel={(option) => `${option?.parent?.displayName}`}
            value={selectedUser}
            onChange={handleUserChange}
            onInputChange={handleUserInputChange}
            renderOption={(props, option) => (
              <li {...props}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    p: 1,
                  }}
                >
                  <Avatar src={option?.parent?.displayPicture?.url} />
                  <Box>
                    <Typography variant="body1">
                      {option?.parent?.displayName}
                    </Typography>
                  </Box>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search User"
                required
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <TextField
            fullWidth
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {provider === "gmail" && (
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                }}
              >
                Generate your app password form here{" "}
                <a
                  href="https://myaccount.google.com/apppasswords"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Link
                </a>
              </Typography>
            )}
          </Box>

          <Select
            value={provider}
            defaultValue={email?.split("@")[1]?.split(".")[0]}
            onChange={(e) => setProvider(e.target.value)}
            placeholder="Select Email Provider"
            required
            fullWidth
          >
            <MenuItem value="gmail">Gmail</MenuItem>
            <MenuItem value="yahoo">Yahoo</MenuItem>
            <MenuItem value="outlook">Outlook</MenuItem>
            <MenuItem value="hotmail">Hotmail</MenuItem>
            <MenuItem value="aol">AOL</MenuItem>
            <MenuItem value="icloud">iCloud</MenuItem>
            <MenuItem value="zoho">Zoho</MenuItem>
            <MenuItem value="yandex">Yandex</MenuItem>
            <MenuItem value="protonmail">ProtonMail</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="outlined" onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Save />}
            >
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
