const evaluateMathExpression = (expression, mongoComponents) => {
    // Convert expression to string
    const expressionString = String(expression);
    // Replace MongoDB IDs with their corresponding values
    const replacedExpression = expressionString.replace(/[_a-f0-9]{24}/gi, match => {
        const component = mongoComponents.find(c => c._id === match);
        return component ? component.value : 0;
    });

    // Check if the expression contains any functions
    if (/\b(IF|ROUND|CEIL|FLOOR|MAX)\b/i.test(replacedExpression)) {
        // Replace function calls with their evaluated values
        const evaluatedExpression = replacedExpression.replace(
            /\b(IF|ROUND|CEIL|FLOOR|MAX)\b\(([^()]*(\([^()]*\))?)*\)/gi,
            (match, functionName, functionArgs) => {
                const evaluatedArgs = splitFunctionArgs(functionArgs, mongoComponents).map(arg =>
                    evaluateMathExpression(arg, mongoComponents)
                );

                switch (functionName.toUpperCase()) {
                    case "IF":
                        return evaluatedArgs[0] ? evaluatedArgs[1] : evaluatedArgs[2];
                    case "ROUND":
                        return (
                            Math.round(evaluatedArgs[0] * Math.pow(10, evaluatedArgs[1])) /
                            Math.pow(10, evaluatedArgs[1])
                        );
                    case "CEIL":
                        return Math.ceil(evaluatedArgs[0]);
                    case "FLOOR":
                        return Math.floor(evaluatedArgs[0]);
                    case "MAX":
                        return Math.max(...evaluatedArgs);
                    default:
                        throw new Error(`Unknown function: ${functionName}`);
                }
            }
        );

        // Evaluate the expression without functions
        return evaluateSimpleExpression(evaluatedExpression);
    }

    // Evaluate the expression without functions
    return evaluateSimpleExpression(replacedExpression);
};

const evaluateSimpleExpression = expression => {
    const tokens = expression.split(/([+\-*/^%><=!()])/);
    const operators = ["+", "-", "*", "/", "^", "%", ">", "<", "=", "!"];
    const precedence = {
        "^": 5,
        "*": 4,
        "/": 4,
        "%": 4,
        "+": 3,
        "-": 3,
        ">": 2,
        "<": 2,
        "=": 2,
        "!": 2,
    };

    const outputQueue = [];
    const operatorStack = [];

    tokens.forEach(token => {
        if (token.trim() === "") {
            return;
        }

        if (!isNaN(parseFloat(token))) {
            outputQueue.push(parseFloat(token));
        } else if (operators.includes(token)) {
            while (
                operatorStack.length > 0 &&
                operators.includes(operatorStack[operatorStack.length - 1]) &&
                precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]
            ) {
                outputQueue.push(operatorStack.pop());
            }
            operatorStack.push(token);
        } else if (token === "(") {
            operatorStack.push(token);
        } else if (token === ")") {
            while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== "(") {
                outputQueue.push(operatorStack.pop());
            }
            operatorStack.pop();
        }
    });

    while (operatorStack.length > 0) {
        outputQueue.push(operatorStack.pop());
    }

    const evaluationStack = [];

    outputQueue.forEach(token => {
        if (!isNaN(parseFloat(token))) {
            evaluationStack.push(token);
        } else {
            const right = evaluationStack.pop();
            const left = evaluationStack.pop();
            switch (token) {
                case "+":
                    evaluationStack.push(left + right);
                    break;
                case "-":
                    evaluationStack.push(left - right);
                    break;
                case "*":
                    evaluationStack.push(left * right);
                    break;
                case "/":
                    evaluationStack.push(left / right);
                    break;
                case "^":
                    evaluationStack.push(Math.pow(left, right));
                    break;
                case "%":
                    evaluationStack.push(left % right);
                    break;
                case ">":
                    evaluationStack.push(left > right);
                    break;
                case "<":
                    evaluationStack.push(left < right);
                    break;
                case "=":
                    evaluationStack.push(left === right);
                    break;
                case "!":
                    evaluationStack.push(left !== right);
                    break;
            }
        }
    });

    return evaluationStack.pop();
};

// ... splitFunctionArgs and other helper functions ...

const splitFunctionArgs = (functionArgs, mongoComponents) => {
    const args = [];
    let currentArg = "";
    let openParenCount = 0;
    let insideQuotes = false;

    for (let i = 0; i < functionArgs.length; i++) {
        const char = functionArgs[i];

        if (char === "(") {
            openParenCount++;
        } else if (char === ")") {
            openParenCount--;
        } else if (char === '"') {
            insideQuotes = !insideQuotes;
        }

        if (char === "," && openParenCount === 0 && !insideQuotes) {
            args.push(evaluateArgument(currentArg.trim(), mongoComponents));
            currentArg = "";
        } else {
            currentArg += char;
        }
    }

    if (currentArg !== "") {
        args.push(evaluateArgument(currentArg.trim(), mongoComponents));
    }

    return args;
};

const evaluateArgument = (arg, mongoComponents) => {
    const comparisonRegex = /(.+)(==|!=|>|<|>=|<=)(.+)/;
    const matches = arg.match(comparisonRegex);

    if (matches) {
        const left = evaluateMathExpression(matches[1].trim(), mongoComponents);
        const operator = matches[2].trim();
        const right = evaluateMathExpression(matches[3].trim(), mongoComponents);

        switch (operator) {
            case "==":
                return left === right;
            case "!=":
                return left !== right;
            case ">":
                return left > right;
            case "<":
                return left < right;
            case ">=":
                return left >= right;
            case "<=":
                return left <= right;
        }
    }

    return evaluateMathExpression(arg, mongoComponents);
};

export default evaluateMathExpression;
