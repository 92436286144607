import React from "react";
import { Box, Typography, Avatar, Badge, Paper, useTheme } from "@mui/material";
import { Circle } from "@mui/icons-material";

const ConversationItem = ({ conversation, messages, isSelected, onClick }) => {
  const theme = useTheme();
  const lastMessage = messages[messages.length - 1] || {};

  // Custom darker blue colors
  const darkBlue = {
    main: "#BBDEFB", // Slightly darker main blue
    light: "#E3F2FD", // Light blue for hover
    dark: "#64B5F6", // Darker blue for accents
    text: "#1565C0", // Darker blue text for better contrast
  };

  const getTimeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    const intervals = [
      { label: "y", seconds: 31536000 },
      { label: "m", seconds: 2592000 },
      { label: "d", seconds: 86400 },
      { label: "h", seconds: 3600 },
      { label: "m", seconds: 60 },
    ];
    for (let i = 0; i < intervals.length; i++) {
      const interval = intervals[i];
      const count = Math.floor(seconds / interval.seconds);
      if (count > 0) {
        return `${count}${interval.label}`;
      }
    }
    return "now";
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 1,
        borderRadius: 1,
        overflow: "hidden",
        backgroundColor: isSelected
          ? darkBlue.main
          : theme.palette.background.paper,
        cursor: "pointer",
        transition: "all 0.2s",
        border: `1px solid ${
          isSelected ? darkBlue.dark : theme.palette.divider
        }`,
        "&:hover": {
          borderColor: darkBlue.dark,
          backgroundColor: isSelected ? darkBlue.main : darkBlue.light,
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ display: "flex", p: 1.5, alignItems: "center" }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Circle
              sx={{
                fontSize: 12,
                color: conversation.isOnline
                  ? theme.palette.success.main
                  : theme.palette.text.disabled,
                backgroundColor: theme.palette.background.paper,
                borderRadius: "50%",
              }}
            />
          }
        >
          <Avatar
            sx={{
              width: 48,
              height: 48,
              bgcolor: darkBlue.dark,
              color: theme.palette.common.white,
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
            src={conversation?.groupDP}
          >
            {conversation?.groupName[0]}
          </Avatar>
        </Badge>
        <Box sx={{ ml: 1.5, overflow: "hidden", flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 0.5,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                color: darkBlue.text,
              }}
              noWrap
            >
              {conversation?.groupName}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
                minWidth: "40px",
                textAlign: "right",
              }}
            >
              {getTimeAgo(new Date(lastMessage.createdAt))}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: lastMessage.isUnread
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
              fontWeight: lastMessage.isUnread ? 500 : 400,
            }}
            noWrap
          >
            {lastMessage.text || "No messages yet"}
          </Typography>
        </Box>
        {lastMessage.isUnread && (
          <Badge
            badgeContent={lastMessage.unreadCount}
            sx={{
              ml: 1,
              "& .MuiBadge-badge": {
                backgroundColor: darkBlue.dark,
                color: theme.palette.common.white,
              },
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

export default ConversationItem;
