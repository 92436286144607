import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
  Select,
} from "@mui/material";

import Api from "../../helpers/Api";
import HorizBox from "../styled/generic/HorizBox";

const BillingAccountPicker = ({
  size = "medium",
  selectedBillingAccount,
  setSelectedBillingAccount,
  defaultBillingAccountId,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const MODES = ["User", "Project", "Organization"];
  const [mode, setMode] = useState("User");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const setUpOrgs = useCallback(() => {
    const organizationTeams = teamIds
      .map((id) => teamDictionary[id])
      .filter((team) => team?.parent?.model === "Organization");
    setOrgs(organizationTeams.map((team) => team.parent));
  }, [teamIds, teamDictionary]);

  const setUpProjects = useCallback(() => {
    const projectTeams = teamIds
      .map((id) => teamDictionary[id])
      .filter((team) => team?.parent?.model === "Project");
    setProjects(projectTeams.map((team) => team.parent));
  }, [teamIds, teamDictionary]);

  useEffect(() => {
    setUpOrgs();
    setUpProjects();
  }, [setUpOrgs, setUpProjects]);

  const getBillingAccountsByMode = useCallback(async () => {
    setLoading(true);
    let modeId =
      mode === "Organization"
        ? selectedOrg
        : mode === "Project"
        ? selectedProject
        : user._id;

    if (!modeId) {
      setLoading(false);
      return;
    }

    try {
      const response = await Api.post(
        "/wallet/billing/billing-account/getByMode",
        {
          modeId,
          mode,
        }
      );

      if (response && response.data) {
        setBillingAccounts(response.data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to fetch billing accounts",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching billing accounts:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while fetching billing accounts",
        },
      });
    } finally {
      setLoading(false);
    }
  }, [mode, selectedOrg, selectedProject, user._id, dispatch]);

  useEffect(() => {
    getBillingAccountsByMode();
  }, [getBillingAccountsByMode]);

  useEffect(() => {
    setSelectedBillingAccount(undefined);
  }, [mode, setSelectedBillingAccount]);

  useEffect(() => {
    if (defaultBillingAccountId) {
      console.log("Setting default Billing Account", defaultBillingAccountId);
      setSelectedBillingAccount(defaultBillingAccountId);
    }
  }, [defaultBillingAccountId, setSelectedBillingAccount]);

  return (
    <HorizBox>
      <FormControl>
        <InputLabel id="mode">Mode</InputLabel>
        <Select
          value={mode}
          onChange={(e) => setMode(e.target.value)}
          label="Mode"
          sx={{ minWidth: "120px" }}
          size={size}
        >
          {MODES.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {mode === "Project" && (
        <FormControl>
          <InputLabel id="Project">Project</InputLabel>
          <Select
            label="Project"
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            sx={{ minWidth: "120px" }}
            size={size}
          >
            {projects.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {mode === "Organization" && (
        <FormControl>
          <InputLabel id="Organization">Organization</InputLabel>
          <Select
            label="Organization"
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
            sx={{ minWidth: "120px" }}
            size={size}
          >
            {orgs.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl>
        <InputLabel id="BillingAccount">Billing Account</InputLabel>
        {loading ? (
          <CircularProgress size={24} sx={{ ml: 1, mt: 1 }} />
        ) : (
          <Select
            label="BillingAccount"
            value={selectedBillingAccount}
            onChange={(e) => setSelectedBillingAccount(e.target.value)}
            sx={{ minWidth: "120px" }}
            size={size}
          >
            <MenuItem value="">Select</MenuItem>
            {billingAccounts?.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.name || "Untitled"}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </HorizBox>
  );
};

export default BillingAccountPicker;
