import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBooking,
  getPaymentTables,
  getPaymentSchedules,
  getTeams,
  getBillingAccounts,
} from "./api";
import BookingForm from "./BookingForm";
import PaymentSection from "./PaymentSection";
import useBookingData from "./useBookingData";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import { Typography, CircularProgress } from "@mui/material";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import { getProfileByWallet } from "../../finance2o/commonComponent/transaction/api";

const BookingEdit = () => {
  const history = useHistory();
  const { bookingId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { bookingData, setBookingData, loading, error } =
    useBookingData(bookingId);

  const [paymentTables, setPaymentTables] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [teams, setTeams] = useState([]);
  const [billingAccounts, setBillingAccounts] = useState([]);

  useEffect(() => {
    if (bookingData?.library?._id) {
      getPaymentTables(bookingData.library._id).then(setPaymentTables);
    }
    if (user?.profile) {
      getTeams(user.profile).then(setTeams);
    }
  }, [bookingData, user]);

  useEffect(() => {
    if (bookingData?.library?._id && bookingData?.paymentTable?._id) {
      getPaymentSchedules(
        bookingData.library._id,
        bookingData.paymentTable._id
      ).then(setPaymentSchedules);
    }
  }, [bookingData]);

  console.log("bookingData", bookingData);

  const getBillingAccountsInfo = async () => {
    if (!bookingData?.project?.profile?.parent?.wallet) return;
    getProfileByWallet({
      walletId: bookingData?.project?.profile?.parent?.wallet,
    }).then((id) => {
      getBillingAccounts([id])
        .then((response) => {
          if (response && response.billingAccounts) {
            console.log("Billing Account ", response);
            setBillingAccounts(response.billingAccounts);
          } else {
            console.error("Unexpected response structure:", response);
            setBillingAccounts([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching billing accounts:", error);
          setBillingAccounts([]);
        });
    });
  };

  useEffect(() => {
    if (bookingData) {
      getBillingAccountsInfo();
    }
  }, [bookingData]);

  useEffect(() => {
    if (bookingData?.project?.profile?._id) {
      getBillingAccounts([bookingData?.project?.profile?._id])
        .then((response) => {
          if (response && response.billingAccounts) {
            setBillingAccounts(response.billingAccounts);
          } else {
            console.error("Unexpected response structure:", response);
            setBillingAccounts([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching billing accounts:", error);
          setBillingAccounts([]);
        });
    }
  }, [bookingData]);

  const handleUpdateBooking = async (updatedData) => {
    try {
      const payload = {
        unit: updatedData.unit?._id,
        bookingStatus: updatedData.bookingStatus,
        sanctionedUnsanctioned: updatedData.sanctionedUnsanctioned
          ? "Sanctioned"
          : "Unsanctioned",
        registrationDate: updatedData.registrationDate,
        unitType: updatedData.unitType,
        closingManager: updatedData.closingManager?.profile?._id,
        bookingDate: updatedData.bookingDate,
        team: updatedData.team,
        bankAccount: updatedData.bankAccountId,
        billingAccount: updatedData.billingAccount,
        table: updatedData.paymentTable?._id,
        schedule: updatedData.schedule?._id,
        area: updatedData.area,

        saleableArea: updatedData.saleableArea,
        ratePerSqFt: updatedData.rsf,
        customerProfile: updatedData.customerProfile,
        ownerProfile: updatedData.owner.profile,
        brokerProfile: updatedData.brokerProfile,
        accountManagers: updatedData.accountManagers.map(
          (item) => item?.profile
        ),
        isBrokerInvolved: updatedData.isBrokerInvolved,
      };

      const brokerData = updatedData.brokerProfile;
      console.log("payload:", payload);
      await updateBooking(bookingId, payload, brokerData);
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Booking updated successfully!",
        },
      });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while updating the booking.",
        },
      });
    }
  };

  if (loading) return <CircularProgress />;
  if (error)
    return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <StandardContainer showAppBar={true} appBarTitle="Edit Booking">
      <StandardAppContainerRounded>
        <BookingForm
          bookingData={bookingData}
          onUpdateBooking={handleUpdateBooking}
          paymentTables={paymentTables}
          paymentSchedules={paymentSchedules}
          teams={teams}
          setBookingData={setBookingData}
          billingAccounts={billingAccounts}
        />
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default BookingEdit;
