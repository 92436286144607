import { Box, IconButton } from "@mui/material";
import {
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CreateTranscationTable from "../../transaction/CreateTranscationTable";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Delete } from "@material-ui/icons";
import BillingAccountSelector from "../../transaction/BillingAccountSelector";

const TransactionSection = ({
  bookingId,
  index,
  setTransactions,
  initialData = {},
  paymentDetails,
  usedComponents = [],
  selectedBillingAccounts = null
}) => {

  const { user } = useSelector((state) => state.auth);
  const [billItems, setBillItems] = useState(initialData.billItems || []);
  const [selectedBankAccount, setSelectedBankAccount] = useState(initialData.transactionData?.bankAccountId || "");
  const [subject, setSubject] = useState(initialData.transactionData?.subject || "");

  const handleSave = () => {
    const newTransaction = {
      transactionData: {
        booking: bookingId,
        subject: subject,
        bankAccountId: selectedBankAccount,
        paymentStatus: "draft",
        status: "draft",
        profile: user?.profile,
        paymentDue: billItems.reduce(
          (acc, item) => acc + parseInt(item.currentDue || 0, 10),
          0
        ),
        amountPaid: billItems.reduce(
          (acc, item) =>
            acc + parseInt(item.receivedAgainstCurrentDue || 0, 10),
          0
        ),
        amountPayable: billItems.reduce(
          (acc, item) => acc + parseInt(item.totalAmountReceivable || 0, 10),
          0
        ),
      },
      billItems: billItems,
    };

    setTransactions((prev) =>
      prev.map((transaction, i) => (i === index ? newTransaction : transaction))
    );
  };

  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      handleSave();
    }
  }, [billItems, subject, selectedBankAccount]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "10px",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: "10px",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            flex: 3,
          }}
        >
          <Typography variant="h6">Transaction {index + 1}</Typography>
          <TextField
            placeholder="Enter subject"
            fullWidth="true"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Subject</InputAdornment>
              ),
            }}
          />
        </Box>
        <IconButton
          onClick={() =>
            setTransactions((prev) =>
              prev.filter((_, transactionIndex) => index !== transactionIndex)
            )
          }
        >
          <Delete />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          paddingRight: `10px`,
          flex: 1,
        }}
      >
        <Typography variant="h6">Select Billing Account</Typography>
        <BillingAccountSelector
          defaultSelectedBillingAccount={selectedBillingAccounts}
          selectedBillingAccounts={selectedBankAccount}
          onBillingAcountChange={(value) => {
            setSelectedBankAccount(value);
          }}
        />
      </Box>
      <CreateTranscationTable
        billItems={billItems}
        paymentDetails={paymentDetails}
        usedComponents={usedComponents}
        setBillItems={setBillItems}
        status="Draft"
      />
    </Box>
  );
};

export default TransactionSection;