import React, { useEffect, useState, useRef } from "react";
import { FormControl, InputLabel, MenuItem, Grid } from "@mui/material";
import Api from "../../../helpers/Api";
import Select from "./Select";

const ProjectBlockAndUnitPickerDropdown = ({
  projectId,
  listingId,
  hideLabel,
  size,
  selectedProjectBlock,
  setSelectedProjectBlock,
  selectedProjectUnit,
  setSelectedProjectUnit,
  fullWidth = false,
}) => {
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [units, setUnits] = useState([]);
  const [isVertical, setIsVertical] = useState(false);
  const containerRef = useRef(null);

  const getProjectBlocks = async () => {
    console.log("projectId", projectId);
    if (!projectId) {
      if (listingId) {
        try {
          const { data } = await Api.post("/public/project/block/get", {
            listingId: listingId,
          });
          if (data && data?.length > 0) {
            setProjectBlocks(data);
            setSelectedProjectBlock(data[0]);
          } else {
            setSelectedProjectBlock("");
            setProjectBlocks([]);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        return;
      }
    } else {
      try {
        const { data } = await Api.post("/public/project/block/get", {
          projectId: projectId,
        });

        if (data && data?.length > 0) {
          setProjectBlocks(data);
          setSelectedProjectBlock(data[0]);
        } else {
          setSelectedProjectBlock("");
          setProjectBlocks([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUnits = async (blockId) => {
    try {
      const { data } = await Api.post("/project/unit/getUnitsByBlock", {
        blockId: blockId,
      });

      if (data && data?.length > 0) {
        setSelectedProjectUnit(data[0]);

        console.log("data", data);
        const filteredData = data.filter(function (item) {
          return item?.vacant;
        });
        setUnits(filteredData);
      } else {
        setSelectedProjectUnit("");
        setUnits([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectBlocks();
  }, [projectId, listingId]);

  useEffect(() => {
    if (selectedProjectBlock) {
      getUnits(selectedProjectBlock._id);
    }
  }, [selectedProjectBlock]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setIsVertical(containerRef.current.offsetWidth < 700);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Grid
      ref={containerRef}
      container
      spacing={2}
      direction={isVertical ? "column" : "row"}
      sx={{
        '& .MuiFormControl-root': {
          minWidth: '250px',
          maxWidth: '350px',
          width: '100%',
        },
        '& .MuiInputBase-root': {
          height: '40px',
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px 14px',
        }
      }}
    >
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={fullWidth} size={size}>
          {!hideLabel && (
            <InputLabel
              id="demo-simple-select-helper-label"
              shrink={selectedProjectBlock !== null}
            >
              {selectedProjectBlock ? "Project Block" : "Select Project Block"}
            </InputLabel>
          )}

          <Select
            value={selectedProjectBlock}
            onChange={(evt) => setSelectedProjectBlock(evt.target.value)}
            label={
              selectedProjectBlock ? "Project Block" : "Select Project Block"
            }
            notched={hideLabel ? false : selectedProjectBlock !== null}
            displayEmpty
          >
            <MenuItem value="">
              {selectedProjectBlock?.displayName || "Select"}
            </MenuItem>
            {projectBlocks.length > 0 &&
              projectBlocks.map((obj) => (
                <MenuItem key={obj._id} value={obj}>
                  {obj?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      {selectedProjectBlock && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth={fullWidth} size={size}>
            {!hideLabel && (
              <InputLabel
                id="demo-simple-select-helper-label"
                shrink={selectedProjectUnit !== null}
              >
                {selectedProjectUnit ? "Project Unit" : "Select Project Unit"}
              </InputLabel>
            )}
            <Select
              value={selectedProjectUnit}
              onChange={(evt) => setSelectedProjectUnit(evt.target.value)}
              label={selectedProjectUnit ? "Project Unit" : "Select Project Unit"}
              notched={hideLabel ? false : selectedProjectUnit !== null}
              displayEmpty
            >
              <MenuItem value="">
                {selectedProjectUnit?.displayName || "Select"}
              </MenuItem>
              {units?.length > 0 &&
                units?.map((obj) => (
                  <MenuItem key={obj._id} value={obj}>
                    {obj?.name || "Untitled"}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectBlockAndUnitPickerDropdown;
