import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Popper,
  ButtonBase,
  InputBase,
  Autocomplete,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StyledRoot = styled(Box)(({ theme }) => ({
  width: 250,
  margin: theme.spacing(2, 0),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  padding: theme.spacing(1.5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
  fontWeight: 600,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1.5),
  width: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& input': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.divider}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledOption = styled('li')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ColorBox = styled(Box)(({ color }) => ({
  width: 16,
  height: 16,
  borderRadius: 4,
  marginRight: 8,
  backgroundColor: color,
}));

export default function StatusPicker({ status, setStatus, pipeline = [], startState = "", viewMode }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(status);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") return;
    setValue(status);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "status-picker" : undefined;

  useEffect(() => {
    if (!viewMode && !status && pipeline?.length > 0) {
      const startStateObj = pipeline.find((obj) => obj?._id === startState) || pipeline[0];
      setStatus(startStateObj);
      setValue(startStateObj);
    }
  }, [pipeline, viewMode, status, startState, setStatus]);

  return (
    <StyledRoot>
      <StyledButton aria-describedby={id} onClick={handleClick}>
        <Box display="flex" alignItems="center">
          {status && <ColorBox color={status.color} />}
          <Typography variant="subtitle2">
            {status ? status.text : "Select Status"}
          </Typography>
        </Box>
        <ArrowDropDownIcon />
      </StyledButton>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <Paper elevation={0}>
          <StyledHeader variant="subtitle2">Choose status of the ticket</StyledHeader>
          <Autocomplete
            open
            onClose={handleClose}
            options={pipeline || []}
            value={value}
            onChange={(event, newValue) => {
              setStatus(newValue);
              setValue(newValue);
              handleClose();
            }}
            disablePortal
            renderOption={(props, option) => (
              <StyledOption {...props}>
                <ColorBox color={option?.color} />
                <Box>
                  <Typography variant="body2">{option?.text}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {option?.description}
                  </Typography>
                </Box>
              </StyledOption>
            )}
            getOptionLabel={(option) => option?.text}
            renderInput={(params) => (
              <StyledInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder="Search status..."
              />
            )}
          />
        </Paper>
      </StyledPopper>
    </StyledRoot>
  );
}