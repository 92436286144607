import React, { useState, useRef, useEffect } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import {
  Box,
  InputAdornment,
  TextField,
  Button,
  Typography,
  AppBar,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Badge,
  Select,
  CircularProgress,
  IconButton,
  Toolbar,
  DialogActions,
  Tooltip,
  Card,
  Divider,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Paper,
  Grid,
  ListItemAvatar,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import {
  Add,
  ArrowDownward,
  ArrowDropDown,
  AttachFile,
  Send,
  Edit,
  Search,
  MoreVert,
} from "@mui/icons-material";
import LeftSideBar from "./LeftSidebar";
import ConversationItem from "./ConversationItem";
import { CiSearch } from "react-icons/ci";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import Picker from "emoji-picker-react";
import { Close, EmojiEmotions, FilterList } from "@mui/icons-material";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import FileUploadButton from "../../file/Uploader/FileUploadButton";
import {
  updateDeleteFlagForManyFiles,
  updateDeleteFlagForSingleFiles,
} from "../../profileChat/apiCall";
import FilesViewer from "../../file/Viewer/FilesViewer";
import { LoadingButton } from "@mui/lab";
import EmojiPicker from "emoji-picker-react";

const ChatMessage = ({ message, isOwn, user, date, files }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: isOwn ? "flex-end" : "flex-start",
      mb: 2,
    }}
  >
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "start",
        flexDirection: isOwn ? "row-reverse" : "row",
      }}
    >
      <Avatar
        sx={{ width: 40, height: 40 }}
        src={user?.parent?.displayPicture?.url}
      >
        {user?.parent?.displayname?.[0]}
      </Avatar>
      <Box
        sx={{
          maxWidth: "60%",
          backgroundColor: isOwn ? "#eff6ff" : "#F5F5F5",
          color: "text.primary",
          borderRadius: 2,
          padding: 1.5,
          boxShadow: 1,
        }}
      >
        {files?.length > 0 && (
          <Box sx={{ mb: 1 }}>
            <img
              src={files[0].url}
              alt="Attached file"
              style={{ maxWidth: "100%", borderRadius: 4 }}
            />
          </Box>
        )}
        <Typography variant="body1" gutterBottom>
          {message}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: isOwn ? "flex-start" : "flex-end",
          }}
        >
          <Typography variant="caption" color="text.secondary">
            {new Date(date).toLocaleString()}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

const BookingMessage = () => {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [pageLoading, setPageLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [filter, setFilter] = useState(false);
  const { createdFileIds } = useSelector((state) => state.file);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [showFilter, setShowFilter] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleEmojiClick = (emojiObject) => {
    setInputMessage((prevInput) => prevInput + emojiObject.emoji);
  };
  useEffect(scrollToBottom, [messages]);

  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [listings, setListings] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const [showGroupInfo, setShowGroupInfo] = useState(true);
  const [filterScreen, setFilterScreen] = useState({
    label: "Projects",
    size: projects.length,
  });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const BOOKING_STATUS = ["DRAFT", "SENT", "CHECKING", "PAID"];
  const FILTER_SCREENS = [
    { label: "Projects", size: projects.length },
    { label: "Listings", size: listings.length },
    {
      label: "Organization",
      size: organizations.length,
    },
  ];

  useEffect(() => {
    const getFilterData = async () => {
      const data = await Api.post(`/profile/get/admin/project/organization`, {
        givenProfiles: [user?.profile],
      });
      setOrganizations(() => data.data.organizations || []);
      console.log("organizations", data.data.organizations || []);
    };
    if (user) {
      getFilterData();
    }
  }, [user]);

  useEffect(() => {
    const getListingData = async () => {
      const listingData = await Promise.all(
        projects
          .filter((item) =>
            selectedProjects.length > 0
              ? selectedProjects.includes(item._id)
              : item
          )
          .map((project) =>
            Api.post(`/listing/getListingsByProject`, {
              projectId: project._id,
            })
          )
      );
      const AllListings = listingData.reduce((acc, item) => {
        return [...acc, ...item?.data];
      }, []);
      console.log(AllListings);
      setListings(AllListings);
    };
    if (projects?.length > 0) {
      getListingData();
    }
  }, [projects, selectedProjects]);

  const handleClearAll = () => {
    setSelectedProjects([]);
    setSelectedOrganizations([]);
    setSelectedListings([]);
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        if (projects.includes(team?.parent)) {
          continue;
        } else {
          setProjects((prev) => [...prev, team?.parent]);
        }
      }
    }
  };

  useEffect(() => {
    if (!projects.length > 0) {
      setUpProjects();
    }
  }, []);

  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      const data = await Api.post("chat/message", {
        conversation: selectedConversation.conversation._id,
        user: user.profile,
        text: inputMessage,
        files: createdFileIds,
      });
      if (data) {
        console.log(data);
        setMessages([
          ...messages,
          {
            _id: data?.data?.messages[0]?._id,
            text: data?.data?.messages[0]?.text,
            isOwn: true,
            user: data?.data?.messages[0]?.user,
            createdAt: new Date(
              data?.data?.messages[0]?.createdAt
            ).toLocaleDateString(),
            files: data?.data?.messages[0]?.files,
          },
        ]);
        setInputMessage("");
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred 2",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId: profileId })
        .then(async (data1) => {
          setWalletData(data1);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPageLoading(false);
        });
    }
  }, [profileId]);

  const getConversations = async () => {
    const payload = {
      libraryId: walletData?.defaultLibrary,
      projectIds: selectedProjects,
      organizationIds: selectedOrganizations,
      listingIds: selectedListings,
    };
    const data = await Api.post("/bookings/conversations/get", payload);

    console.log(data);
    return data;
  };

  console.log("selectedListings", selectedConversation);

  useEffect(() => {
    if (listings.length > 0) {
      const updatedSelectedListings = selectedListings.filter((id) =>
        listings.some((item) => item._id === id)
      );
      setSelectedListings(updatedSelectedListings);
    }
  }, [listings]);

  useEffect(() => {
    setPageLoading(true);
    if (walletData?.defaultLibrary) {
      getConversations().then((response) => {
        if (response) {
          const data = response.data.conversations;
          setConversations(data);
        } else {
          console.log("No conversation found");
        }
        setPageLoading(false);
      });
    }
  }, [walletData]);

  const handleConversationChange = (index) => {
    setSelectedConversation(conversations[index]);
    console.log(conversations[index].messages);
    const structuredMessages = conversations[index].messages.map((item) => ({
      _id: item?._id,
      text: item?.text,
      isOwn: item?.user?._id === user?.profile,
      user: item?.user,
      createdAt: new Date(item?.createdAt).toLocaleDateString(),
      files: item?.files,
    }));
    console.log("structuredMessages", structuredMessages);
    setMessages(structuredMessages);
  };

  useEffect(() => {
    if (conversations.length > 0) {
      handleConversationChange(0);
    }
  }, [conversations]);

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });

    await updateDeleteFlagForSingleFiles({ fileId: selectedId })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeImgFromReducerAndDelete = async () => {
    updateDeleteFlagForManyFiles({ fileIds: createdFileIds })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Messages"
      loading={pageLoading}
    >
      <Box
        sx={{
          display: "flex",
          height: "calc(100vh - 92px)",
          width: "100%",
          bgcolor: "background.default",
        }}
      >
        {/* Left sidebar */}
        <Box
          sx={{
            width: "400px",
            flexShrink: 0,
            borderRight: "1px solid",
            borderColor: "divider",
          }}
        >
          <LeftSideBar
            conversations={conversations}
            filter={filter}
            handleConversationChange={handleConversationChange}
            selectedConversation={selectedConversation}
            setFilter={setFilter}
            setShowFilter={setShowFilter}
          />
        </Box>

        {/* Right content area */}
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <RightContentArea
            createdFileIds={createdFileIds}
            removeImgFromReducerAndDelete={removeImgFromReducerAndDelete}
            removeSingleImgFromReducerAndDelete={
              removeSingleImgFromReducerAndDelete
            }
            setShowEmoji={setShowEmoji}
            showEmoji={showEmoji}
            selectedConversation={selectedConversation}
            messages={messages}
            inputMessage={inputMessage}
            handleSendMessage={handleSendMessage}
            messagesEndRef={messagesEndRef}
            setInputMessage={setInputMessage}
            onShowGroupInfo={() => setShowGroupInfo((prev) => !prev)}
            onUpdate={() => {
              getConversations().then((response) => {
                if (response) {
                  const data = response.data.conversations;
                  setConversations(data);
                } else {
                  console.log("No conversation found");
                }
              });
            }}
          />
        </Box>

        <Box>
          <GroupInfoSidebar
            isOpen={showGroupInfo}
            onClose={() => setShowGroupInfo(false)}
            groupInfo={{
              id: selectedConversation?.conversation?._id,
              name: selectedConversation?.conversation?.groupName,
              image: selectedConversation?.conversation?.groupDP?.url,
              mediaCount: 8,
            }}
            participates={
              selectedConversation?.conversation?.participantsRole || []
            }
            onUpdate={() => {
              getConversations().then((response) => {
                if (response) {
                  const data = response.data.conversations;
                  setConversations(data);
                } else {
                  console.log("No conversation found");
                }
              });
            }}
          />
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => {
          if (!gridLoading) {
            setShowFilter(false);
          }
        }}
        variant="temporary"
        sx={{
          width: 500,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: 500 },
        }}
      >
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <Box
            sx={{
              p: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
            }}
          >
            <Typography variant="h6">Filters</Typography>
            <Button
              disabled={gridLoading}
              variant="text"
              size="small"
              onClick={() => {
                setGridLoading(true);
                handleClearAll();
                getConversations().then((response) => {
                  if (response) {
                    const data = response.data.conversations;
                    setConversations(data);
                  } else {
                    console.log("No conversation found");
                  }
                  setGridLoading(false);
                });
              }}
            >
              Reset all
            </Button>
          </Box>
          <Box display={"flex"}>
            <List
              sx={{
                flex: 1.5,
              }}
            >
              {FILTER_SCREENS.map((filter, index) => (
                <ListItem
                  key={index}
                  button
                  sx={{
                    display: "flex",
                  }}
                  onClick={() => setFilterScreen(filter.label)}
                >
                  <ListItemText primary={filter.label} />
                  <Typography>{filter.size}</Typography>
                </ListItem>
              ))}
            </List>
            {filterScreen === "Projects" && (
              <List
                sx={{
                  flex: 2.5,
                }}
              >
                {projects.map((project, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (selectedProjects.includes(project._id)) {
                        setSelectedProjects((prev) =>
                          prev.filter((item) => item !== project._id)
                        );
                      } else {
                        setSelectedProjects((prev) => [...prev, project._id]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectedProjects.includes(project._id)}
                    />
                    <ListItemText primary={project.displayName} />
                  </ListItem>
                ))}
              </List>
            )}
            {filterScreen === "Listings" && (
              <List
                sx={{
                  flex: 2.5,
                }}
              >
                {listings.map((listing, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (selectedListings.includes(listing._id)) {
                        setSelectedListings((prev) =>
                          prev.filter((item) => item !== listing._id)
                        );
                      } else {
                        setSelectedListings((prev) => [...prev, listing._id]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectedListings.includes(listing._id)}
                    />
                    <ListItemText primary={listing?.title} />
                  </ListItem>
                ))}
              </List>
            )}
            {filterScreen === "Organization" && (
              <List
                sx={{
                  flex: 2.5,
                }}
              >
                {organizations.map((organization, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (selectedOrganizations.includes(organization._id)) {
                        setSelectedOrganizations((prev) =>
                          prev.filter((item) => item !== organization._id)
                        );
                      } else {
                        setSelectedOrganizations((prev) => [
                          ...prev,
                          organization._id,
                        ]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectedOrganizations.includes(organization._id)}
                    />
                    <ListItemText primary={organization?.parent?.displayName} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1.5rem",
              gap: "15px",
            }}
          >
            <Button
              disabled={gridLoading}
              variant="outlined"
              onClick={() => {
                setShowFilter(false);
              }}
            >
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={gridLoading}
              onClick={() => {
                setGridLoading(true);
                getConversations().then((response) => {
                  if (response) {
                    const data = response.data.conversations;
                    setConversations(data);
                  } else {
                    console.log("No conversation found");
                    setConversations([]);
                  }
                  setGridLoading(false);
                });
              }}
            >
              APPLY
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
    </StandardContainer>
  );
};

export default BookingMessage;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const MessageArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: "auto",
  height: "calc(100vh - 230px)",
  backgroundColor: theme.palette.background.default,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[300],
    borderRadius: "3px",
  },
}));

const InputArea = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.05)",
}));

const RightContentArea = ({
  messages,
  inputMessage,
  setInputMessage,
  handleSendMessage,
  messagesEndRef,
  createdFileIds,
  removeImgFromReducerAndDelete,
  removeSingleImgFromReducerAndDelete,
  setShowEmoji,
  showEmoji,
  selectedConversation,
  onShowGroupInfo,
  onUpdate,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileInputRef = useRef(null);

  const handleEmojiClick = (emojiObject) => {
    setInputMessage((prevInput) => prevInput + emojiObject.emoji);
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    console.log("Selected files:", files);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderLeft: "1px solid",
        borderColor: "divider",
      }}
    >
      <StyledAppBar position="static" elevation={0}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={selectedConversation?.conversation?.groupDP?.url}
              alt={selectedConversation?.conversation?.groupName}
              sx={{ width: 40, height: 40, marginRight: 2 }}
            />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {selectedConversation?.conversation?.groupName}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {selectedConversation?.conversation?.participants?.map(
                  (participant, index) =>
                    `${participant.parent.displayName}${
                      index <
                      selectedConversation.conversation.participants.length - 1
                        ? ", "
                        : ""
                    }`
                )}
              </Typography>
            </Box>
          </Box>
          <Tooltip title="More options">
            <IconButton onClick={onShowGroupInfo}>
              <MoreVert />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </StyledAppBar>

      <MessageArea>
        {createdFileIds.length > 0 && (
          <Paper
            elevation={3}
            sx={{
              width: "95%",
              margin: "0 auto 16px",
              position: "relative",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "background.paper",
                "&:hover": { bgcolor: "action.hover" },
              }}
              onClick={removeImgFromReducerAndDelete}
            >
              <Close />
            </IconButton>
            <Box sx={{ p: 2, pt: 4 }}>
              <FilesViewer
                fileIds={createdFileIds}
                deletable={true}
                styleBody={{
                  width: "100%",
                  height: "auto",
                }}
                handler={removeSingleImgFromReducerAndDelete}
              />
            </Box>
          </Paper>
        )}
        {messages.map((msg, index) => (
          <ChatMessage
            key={index}
            message={msg.text}
            isOwn={msg.isOwn}
            user={msg.user}
            date={msg.createdAt}
            files={msg.files}
          />
        ))}
        <div ref={messagesEndRef} />
      </MessageArea>

      <InputArea elevation={0}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            color="primary"
          >
            <EmojiEmotions />
          </IconButton>
          {showEmojiPicker && (
            <Box
              sx={{ position: "absolute", bottom: "100%", left: 0, zIndex: 1 }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </Box>
          )}
          <TextField
            placeholder="Type a message..."
            fullWidth
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <AttachFile />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 3,
                backgroundColor: (theme) => theme.palette.background.default,
              },
            }}
          />
          <input
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={
              <Send
                style={{
                  transform:
                    inputMessage.length > 0 ? "rotate(-35deg)" : "none",
                  transition: "all 100ms ease-in",
                }}
              />
            }
            onClick={handleSendMessage}
            sx={{
              minWidth: "100px",
              borderRadius: 3,
              height: 56,
            }}
          >
            Send
          </Button>
        </Box>
      </InputArea>
    </Box>
  );
};

const GroupInfoSidebar = ({
  isOpen,
  onClose,
  groupInfo,
  participates,
  onUpdate,
}) => {
  const currentUserId = useSelector((state) => state.auth.user._id);
  const [searchTerm, setSearchTerm] = useState("");
  const [members, setMembers] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editModelTitle, setEditModelTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [changeRoleOpen, setChangeRoleOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddingMember, setIsAddingMember] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event, member) => {
    setAnchorEl(event.currentTarget);
    setSelectedMember(member);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (participates.length > 0) {
      setMembers(convertProfileToMember(participates));
    }
  }, [participates]);

  const filteredMembers = members?.filter((member) =>
    member?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleChangeRole = async (memberId, newRole) => {
    handleClose();
  };

  const handleRoleSave = (newRole) => {
    console.log(`Changed ${selectedMember.name}'s role to ${newRole}`);
    setMembers(
      members.map((member) =>
        member.id === selectedMember.id ? { ...member, role: newRole } : member
      )
    );
  };

  const handleAddMember = async (newMember) => {
    setIsAddingMember(true);
    setError(null);
    const response = await Api.post("bookings/conversation/add-member", {
      conversationId: groupInfo.id,
      newMemberId: newMember._id,
      role: newMember.role,
      userId: currentUserId,
    });

    console.log("Add member response:", response);

    if (response) {
      // setMembers(
      //   convertProfileToMember(response.data.conversation.participants)
      // );
      onUpdate();

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Member added successfully",
        },
      });
    } else {
      console.error("Error adding member:", error);
      setError("Failed to add member. Please try again.");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to add member. Please try again.",
        },
      });
    }
    setIsAddingMember(false);
    setAddMemberOpen(false);
  };

  const handleEditSave = async (field, value) => {
    setIsLoading(true);
    setError(null);
    const response = await Api.post("bookings/conversation-update", {
      conversationId: groupInfo.id,
      field,
      value,
    });

    if (field === "groupName") {
      groupInfo.name = value;
    } else if (field === "description") {
      groupInfo.description = value;
    }

    if (response) {
      setIsLoading(false);
      setEditOpen(false);
      onUpdate();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Updated successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to update. Please try again.",
        },
      });
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: 400,
        height: "100%",
        backgroundColor: "background.paper",
        borderLeft: "1px solid",
        borderColor: "divider",
        display: isOpen ? "flex" : "none",
        flexDirection: "column",
        transition: "all 0.3s",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: 2,
          pt: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Group info</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      {/* Group Image and Name */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar src={groupInfo.image} sx={{ width: 90, height: 90, mb: 1 }} />
        <Typography variant="subtitle1" fontWeight="bold">
          {groupInfo.name}{" "}
          <IconButton
            size="small"
            onClick={() => {
              setEditOpen(true);
              setEditModelTitle("Edit group name");
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Group · {members.length} members
        </Typography>
      </Box>

      <Divider />

      {/* Add group description */}
      <Box
        component={ButtonBase}
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          paddingY: "15px",
          paddingX: "16px",
        }}
        onClick={() => {
          setEditOpen(true);
          setEditModelTitle("Edit group description");
        }}
      >
        <Typography
          variant="body2"
          color="primary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Add fontSize="small" sx={{ mr: 1 }} /> Add group description
        </Typography>
      </Box>

      <Divider />

      {/* Members list */}
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search members"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List>
          <ListItem
            component={ButtonBase}
            onClick={() => setAddMemberOpen(true)}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "primary.main" }}>
                <Add />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add member" />
          </ListItem>
          {filteredMembers.map((member, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar src={member.avatar}>{member.name[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={member.name}
                secondary={member.status || member.phone}
                secondaryTypographyProps={{ color: "text.secondary" }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  flexDirection: "column",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  {member.role}
                </Typography>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleChangeRole}>Change role</MenuItem>
                </Menu>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event, member)}
                  size="small"
                  sx={{
                    zIndex: 999,
                  }}
                >
                  <ArrowDropDown />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      <EditDialog
        onSave={(value) =>
          handleEditSave(
            editModelTitle === "Edit group name" ? "groupName" : "description",
            value
          )
        }
        open={editOpen}
        setOpen={setEditOpen}
        data={
          editModelTitle === "Edit group name"
            ? groupInfo.name
            : groupInfo.description
        }
        title={editModelTitle}
        isLoading={isLoading}
        error={error}
      />

      <ChangeRoleDialog
        open={changeRoleOpen}
        setOpen={setChangeRoleOpen}
        onSave={handleRoleSave}
        currentRole={selectedMember?.role}
      />
      <AddMemberDialog
        open={addMemberOpen}
        setOpen={setAddMemberOpen}
        onAddMember={handleAddMember}
        isAddingMember={isAddingMember}
        error={error}
      />
    </Box>
  );
};

const AddMemberDialog = ({
  open,
  setOpen,
  onAddMember,
  isAddingMember,
  error,
}) => {
  const [newMember, setNewMember] = useState({
    id: null,
    name: "",
    role: "User",
  });

  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open]);

  const fetchUsers = async () => {
    setLoading(true);
    const response = await Api.post("/search/users", {
      name: searchTerm,
    });
    if (response) {
      setAllUsers(response.data.map((item) => item.profile.parent));
    } else {
      console.error("Error fetching users:");
    }

    setLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) fetchUsers();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSubmit = () => {
    if (newMember._id) {
      onAddMember(newMember);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Member</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={allUsers}
          getOptionLabel={(option) => option.displayName || ""}
          value={newMember}
          onChange={(event, newValue) => {
            setNewMember(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderOption={(props, option) => {
            if (loading) {
              return (
                <li {...props}>
                  <Typography>Loading...</Typography>
                </li>
              );
            }
            return (
              <li {...props}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={
                      option.displayPicture?.url ||
                      "https://i.pravatar.cc/150?img=1"
                    }
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: 1,
                    }}
                  />
                  <Typography>{option.displayName}</Typography>
                </Box>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Users"
              margin="normal"
              fullWidth
              required
            />
          )}
        />
        <TextField
          select
          fullWidth
          label="Role"
          value={newMember?.role}
          onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
          margin="normal"
        >
          <MenuItem value="User">User</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
        </TextField>
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!newMember?._id || isAddingMember}
        >
          {isAddingMember ? <CircularProgress size={24} /> : "Add Member"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditDialog = ({
  open,
  setOpen,
  onSave,
  title,
  data,
  isLoading,
  error,
}) => {
  const [value, setValue] = useState(data || "");

  useEffect(() => {
    setValue(data || "");
  }, [data]);

  const handleSubmit = () => {
    onSave(value);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          padding: 3,
        }}
      >
        <TextField
          fullWidth
          placeholder="Enter value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          variant="outlined"
          disabled={isLoading}
        />
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChangeRoleDialog = ({ open, setOpen, onSave, currentRole }) => {
  const [role, setRole] = useState(currentRole || "");

  const handleSubmit = () => {
    onSave(role);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Change Role</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          padding: 3,
        }}
      >
        <FormControl variant="outlined" sx={{ width: "100%", mt: 1 }}>
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            labelId="role-select-label"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Role"
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            mt: 2,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

function convertProfileToMember(participantsRole) {
  return participantsRole.map((participate) => {
    const { user } = participate;
    return {
      id: user?.parent?._id,
      name: user?.parent?.displayName,
      avatar:
        user?.parent?.displayPicture?.url || "https://i.pravatar.cc/150?img=1",
      isCurrentUser: false,
      phone: user?.parent?.phone || user?.parent?.email || null,
      role: participate?.role,
    };
  });
}
