import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import CustomSelectAutocomplete from "../../styled/CommonComponents/CustomSelectAutocomplete";
import { getBillingAcountAgainstProfile } from "../api.call";
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    rootCont: {
        width: "100%",
        display: "flex"
    },
    bankCont: {
        display: "flex",
        alignItems: "center",
        padding: "0px 15px",
        borderRadius: "8px",
        border: "1px solid #c1bfbf"
    }
}));

function BillingAccountSelector({ 
    selectedBillingAccounts, onBillingAcountChange, 
    defaultSelectedBillingAccount, sourceProfile 
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { teamDictionary, teamIds } = useSelector((state) => state.team);

    const modes = ["User", "Organization", "Project"];
    
    const [profiles, setProfiles] = useState([])
    const [billingAcounts, setBillingAcounts] = useState([])
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedMode, setSelectedMode] = useState(null);

    useEffect(() => {
        if (sourceProfile) {
            let locData = null;
            for (let i = 0; i < teamIds.length; i++) {
                let id = teamIds[i];
                let team = teamDictionary[id];
                if (team?.parent?.profile?._id == sourceProfile) {
                    locData = team?.parent;
                }
            }
            if (!selectedProfile && !selectedMode) {
                setSelectedMode(locData?.model)
                onModeChange(locData?.model, true)
                setSelectedProfile(locData)
                onProfileChange(locData)
            }
        } else if (defaultSelectedBillingAccount?.parent || defaultSelectedBillingAccount?.parent?._id) {
            let locData = null;
            for (let i = 0; i < teamIds.length; i++) {
                let id = teamIds[i];
                let team = teamDictionary[id];
                if (team?.parent?._id == defaultSelectedBillingAccount?.parent?._id || team?.parent?._id == defaultSelectedBillingAccount?.parent) {
                    locData = team?.parent;
                }
            }
            if (!selectedProfile && !selectedMode) {
                setSelectedMode(locData?.model)
                onModeChange(locData?.model, true)
                setSelectedProfile(locData)
                onProfileChange(locData)
            }
        }
    }, [defaultSelectedBillingAccount, sourceProfile])

    //to select default value
    useEffect(() => {
        if (selectedBillingAccounts?._id) {
            setSelectedMode(selectedBillingAccounts?.parentModelName)
            if (selectedBillingAccounts?.parentModelName === "Organization" || selectedBillingAccounts?.parentModelName === "Project") {
                let locVal = null;
                for (let i = 0; i < teamIds.length; i++) {
                    let id = teamIds[i];
                    let team = teamDictionary[id];
                    if (team?.parent?.model === selectedBillingAccounts?.parentModelName && (team?.parent?._id == selectedBillingAccounts?.parent || team?.parent?._id == selectedBillingAccounts?.parent?._id)) {
                        locVal = team?.parent;
                    }
                }
                setSelectedProfile(locVal)
            }
        }
    }, [selectedBillingAccounts])

    //to get projects of organizations
    const onModeChange = (value, isOnlyUpdateOptions = false) => {
        setSelectedMode(value)
        if (value === "Project" || value === "Organization") {
            let arr = []
            for (let i = 0; i < teamIds.length; i++) {
                let id = teamIds[i];
                let team = teamDictionary[id];
                if (team?.parent?.model === value) {
                    arr.push(team?.parent);
                }
            }
            setProfiles(arr)
            if (!isOnlyUpdateOptions) {
                setSelectedProfile(null)
                onBillingAcountChange(null)
            }
        } else if (value !== "User") {
            setProfiles([])
            if (!isOnlyUpdateOptions) {
                setBillingAcounts([])
                setSelectedProfile(null)
                onBillingAcountChange(null)
            }
        }
    }

    //to get billing accounts
    const onProfileChange = (value) => {
        setSelectedProfile(value);
        if (value && value?._id) {
            getBillingAcountAgainstProfile({
                sharedIds: [value?.profile?._id]
            })
                .then((data) => {
                    console.log(data)
                    if (data && data.length > 0) {
                        setBillingAcounts(data)
                        onBillingAcountChange(data[0])
                    } else {
                        setBillingAcounts([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <div className={classes.rootCont} >
            <Autocomplete
                options={modes}
                value={selectedMode}
                onChange={(event, value) => {
                    onModeChange(value)
                }}
                disableClearable
                size="small"
                style={{ width: "200px", marginRight: "15px" }}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        placeholder={"Select Mode"}
                    />
                )}
            />
            {selectedMode !== "User" && (
                <div style={{ width: "350px", marginRight: "15px" }} >
                    <CustomSelectAutocomplete
                        value={selectedProfile}
                        onChange={(value) => {
                            onProfileChange(value)
                        }}
                        isMulti={false}
                        options={profiles}
                        disabled={selectedMode === null}
                        placeholder={`Select ${selectedMode === null ? "Scope First" : selectedMode}`}
                        getLabel={(s) => s?.displayName}
                        getIdentity={(s) => s?._id}
                        getImage={(s) => s?.displayPicture?.url}
                        getDisplayName={(s) => s?.displayName}
                        getUsername={(s) => s?.username}
                    />
                </div>
            )}
            <Autocomplete
                options={billingAcounts}
                value={selectedBillingAccounts}
                onChange={(event, value) => {
                    onBillingAcountChange(value)
                }}
                disableClearable
                size="small"
                style={{ width: "350px", marginRight: "15px" }}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        placeholder={`Select Billing Account`}
                    />
                )}
            />
            {selectedBillingAccounts?.bankAccount && (
                <div className={classes.bankCont} >
                    <p>{selectedBillingAccounts?.bankAccount?.bankName} ({selectedBillingAccounts?.bankAccount?.branchName})</p>
                </div>
            )}
        </div>
    );
}

export default BillingAccountSelector;