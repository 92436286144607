import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import Button from "../../styled/generic/Button";
import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const Schedules = ({ libraryId }) => {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const createSchedule = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/listing/booking/schedule/create", {
        library: libraryId,
      });
      if (data) {
        setSchedules([...schedules, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Schedule created successfully",
          },
        });
       
        history.push(
          `/booking/settings/${profileId}?view=scheduleedit&scheduleId=${data?._id}`
        );
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getSchedules = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/listing/booking/schedule/get", {
        libraryId: libraryId,
      });
      if (data) {
        setSchedules(data);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <Box>
      <BoxSpaceBetween>
        <Typography variant="h5">Schedules</Typography>
        <Button variant="outlined" color="primary" onClick={createSchedule}>
          Add Schedule
        </Button>
      </BoxSpaceBetween>
      <TableContainer
        columns={["Title", "Project", "Block"]}
        data={schedules}
        loading={loading}
      >
        {schedules?.map((schedule) => (
          <tr
            onClick={() =>
              history.push(
                `/booking/settings/${profileId}?view=schedule&scheduleId=${schedule?._id}`
              )
            }
          >
            <td>{schedule?.title || "Untitled"}</td>
            <td>{schedule?.project?.displayName || "N/A"}</td>
            <td>{schedule?.projectBlock?.name || "N/A"}</td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default Schedules;
