import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Button,
  Avatar,
  Divider,
  Grid,
} from "@mui/material";
import { CurrencyRupee, Percent, AddCircleOutline } from "@mui/icons-material";
import Api from "../../../helpers/Api";
import formatToRupees from "../../../helpers/formatToRupees";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

const BookingBroker = ({ brokers, bookingId, onSubmit }) => {
  const dispatch = useDispatch();
  // const [brokers, setBrokers] = useState(initialBroker || []);

  // const fetchBrokers = async ({ ids }) => {
  //   const data = await Api.post("listing/booking/brokers", {
  //     brokerIds: ids,
  //   });
  //   if (data) {
  //     setBrokers(data.brokers);
  //   } else {
  //     dispatch({
  //       type: "AddApiAlert",
  //       payload: {
  //         success: false,
  //         message: "Failed to fetch brokers",
  //       },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (initialBroker.length > 0) {
  //     fetchBrokers({
  //       ids: initialBroker.map((broker) => broker._id),
  //     });
  //   }
  // }, [initialBroker]);

  const handleCreateTransaction = async (broker) => {
    const payload = {
      broker: {
        ...broker,
        profile: broker.profile._id,
      },
      bookingId,
    };
    const data = await Api.post(
      "listing/booking/broker-transaction/create",
      payload
    );
    onSubmit();
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Transaction created successfully",
        },
      });
      // fetchBrokers({
      //   ids: initialBroker.map((broker) => broker._id),
      // });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Transaction creation failed",
        },
      });
    }
  };

  const handleTransactionPaid = async (broker) => {
    const data = await Api.post("listing/booking/broker-transaction/paid", {
      broker: broker,
      // walletId,
      bookingId,
    });
    onSubmit();
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Transaction paid successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Transaction paid failed",
        },
      });
    }
  };
  return (
    <Grid container spacing={1}>
      {brokers.length > 0 ? (
        brokers.map((broker) => (
          <Grid item key={broker._id} xs={12} md={4} lg={3}>
            <BrokerProfileCard
              brokerProfile={broker}
              onCreateTransaction={handleCreateTransaction}
              onTransactionPaid={handleTransactionPaid}
            />
          </Grid>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <Typography variant="body1">No brokers found</Typography>
        </Box>
      )}
    </Grid>
  );
};

export default BookingBroker;

const BrokerProfileCard = ({
  brokerProfile,
  onCreateTransaction,
  onTransactionPaid,
}) => {
  const { chargeAmount, chargePercentage, isPaid, isTransaction, profile } =
    brokerProfile;

  return (
    <Card sx={{ maxWidth: 400, m: 2 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar src={profile?.parent?.displayPicture?.url} />
          <Typography variant="h5">{profile?.parent?.displayName}</Typography>
        </Box>

        <Divider
          sx={{
            my: 2,
          }}
        />

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <CurrencyRupee sx={{ mr: 1 }} />
          <Typography variant="body1">
            Charge Amount:{" "}
            <NumberFormat
              value={chargeAmount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Percent sx={{ mr: 1 }} />
          <Typography variant="body1">
            Charge Percentage: {chargePercentage}%
          </Typography>
        </Box>

        <Box sx={{ mt: 2, mb: 2 }}>
          <Chip
            label={isPaid ? "Paid" : "Unpaid"}
            color={isPaid ? "success" : "error"}
            sx={{ mr: 1 }}
          />
        </Box>
        {!isPaid && isTransaction && (
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => onTransactionPaid(brokerProfile)}
            fullWidth
          >
            Pay
          </Button>
        )}
        {!isTransaction && (
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => onCreateTransaction(brokerProfile)}
            fullWidth
          >
            Create Transaction
          </Button>
        )}
        <Typography variant="caption" display="block" sx={{ mt: 2 }}>
          ID: {profile._id}
        </Typography>
      </CardContent>
    </Card>
  );
};
